import PropType from "prop-types";
import { withPage } from "@myloc/myloc-utils";
import pages from "../../utils/pages";
import { useTranslate } from "../../language/i18n";
import WizardPage, { getActiveStep } from "../shared/WizardPage/WizardPage";
import OrderOverview from "../shared/OrderOverview/OrderOverview";
import Aid from "./Aid/Aid";
import AdditionalInfo from "../shared/AdditionalInfo/AdditionalInfo";
import AddressAndContact from "../shared/AddressAndContact/AddressAndContact";
import OrderInfo from "./OrderInfo/OrderInfo";
import useOrderWizard from "../../hooks/useOrderWizard";
import { useParams } from "react-router";

const PickupOrderPage = ({ currentActiveStep }) => {
  const queryParams = useParams();
  const translate = useTranslate();
  const { data, setData, stepper, handleNext } = useOrderWizard(pages.PICKUP_ORDER.PATH, { contentType: "PICKUP" });

  const steps = [
    {
      title: translate("CHOOSE_AID"),
      titleShort: translate("AID"),
      children: <Aid data={data} setData={_data => setData({ ...data, ..._data })} onNext={handleNext} />,
    },
    {
      title: translate("ADD_ADDITIONAL_INFO"),
      titleShort: translate("ADDITIONAL_INFO_SHORT"),
      children: <AdditionalInfo onNext={handleNext} />,
    },
    {
      title: translate("ADD_PICKUP_ADDRESS_AND_CONTACT"),
      titleShort: translate("ADDRESS"),
      children: <AddressAndContact onNext={handleNext} disableFacility />,
    },
    {
      title: translate("ORDER_OVERVIEW"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: (
        <OrderOverview>
          <OrderInfo />
        </OrderOverview>
      ),
    },
  ];

  return (
    <WizardPage
      title={translate("PICKUP_ORDER_TITLE")}
      steps={steps}
      activeStep={getActiveStep(queryParams.content, currentActiveStep, data.currentStep)}
      setActiveStep={step => stepper.setActiveStep(step)}
    />
  );
};

PickupOrderPage.propTypes = {
  pickupList: PropType.array,
  text: PropType.string,
  accessories: PropType.array,
  files: PropType.array,
  subContentType: PropType.object,
  priority: PropType.object,
  address: PropType.object,
  contact: PropType.object,
  search: PropType.string,
  id: PropType.string,
  currentStep: PropType.number,
  currentActiveStep: PropType.number,
};

export default withPage(PickupOrderPage);
