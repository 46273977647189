import { REMEMBER_ME, SESSION_ID } from "../../reducers/session/sessionVariables";

const LOCAL_STORAGE = "LOCAL_STORAGE";
const SESSION_STORAGE = "SESSION_STORAGE";

const _moveTo = async destinationStorage => {
  return destinationStorage === LOCAL_STORAGE
    ? await localStorage.setItem(SESSION_ID, await sessionStorage.getItem(SESSION_ID))
    : await sessionStorage.setItem(SESSION_ID, await localStorage.getItem(SESSION_ID));
};

const _getRememberMe = async rememberMe => {
  if (rememberMe === undefined || rememberMe === null) {
    //Try to get the remember me setting, first from local Storage, then session Storage
    rememberMe = JSON.parse(await localStorage.getItem(REMEMBER_ME));
    if (rememberMe === null) rememberMe = JSON.parse(await sessionStorage.getItem(REMEMBER_ME));

    //Fallback
    if (rememberMe === null) rememberMe = false;
  }

  return typeof rememberMe === "string" ? rememberMe.toUpperCase() === "TRUE" : !!rememberMe;
};

function _getStorage(rememberMe) {
  return !rememberMe ? sessionStorage : localStorage;
}

//TODO, LOAD_ITEM SHOULD NOT BE USED FOR rememberMe!

async function loadItem(item) {
  //Use local storage only if rememberMe is true
  const data = await _getStorage(await _getRememberMe()).getItem(item);

  //If no success - return null
  if (data === null) {
    return data;
  }

  try {
    //Try to parse as json object
    return JSON.parse(data);
  } catch (e) {
    //No success, try as boolean
    if (data === "true") return true;
    else if (data === "false") return false;

    return isNaN(data) ? (data === "null" ? null : data) : Number(data);
  }
}

async function saveItem(item, data, rememberMe) {
  if (typeof data !== "string") {
    data = data === null ? null : JSON.stringify(data);
  }

  //If it is the rememberMe setting itself, use it!
  if (item === REMEMBER_ME) {
    const previousRememberMe = await _getRememberMe(data);
    if (rememberMe === previousRememberMe) return;
    rememberMe = data;

    if (!rememberMe) {
      _moveTo(SESSION_STORAGE);
      await localStorage.clear();
    } else {
      _moveTo(LOCAL_STORAGE);
    }
  }

  return await _getStorage(rememberMe).setItem(item, data);
}

async function deleteItem(item) {
  const successLocal = await localStorage.removeItem(item);
  const successSession = await sessionStorage.removeItem(item);
  return successLocal && successSession;
}

export default { loadItem, saveItem, deleteItem };
