import { useSelector } from "react-redux";
import { User } from "../../../../assets/icons";
import colors from "../../../../style/exports.module.scss";
import styles from "./MyMylocMenu.module.scss";
import { useTranslate } from "../../../../language/i18n";
import { Link } from "react-router-dom";
import pages from "../../../../utils/pages";

const MyMylocMenu = () => {
  const session = useSelector(state => state.session);
  const userInformation = useSelector(state => state.appData.user.information);
  const translate = useTranslate();

  const fullName = `${userInformation?.firstName} ${userInformation?.surname}`;

  return (
    <>
      {!!session.sessionId && (
        <section className={styles.menuWrapper}>
          <Link to={pages.MY_MYLOC.PATH} className={styles.userButton}>
            <User size="40" color={colors.iconColor} customCssClass={styles.userIcon} />
            <div>
              <div className={styles.title}>{translate("MY_MYLOC")}</div>
              <div>{fullName}</div>
            </div>
          </Link>
        </section>
      )}
    </>
  );
};

export default MyMylocMenu;
