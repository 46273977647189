import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";

import styles from "../FittingDetailPage.module.scss";
import { useEffect, useState } from "react";
import pages from "../../../utils/pages";
import { Car, Clock, Place, Room } from "../../../assets/icons";
import { THEME } from "@myloc/myloc-gui";
import CheckAuthButton from "../../shared/Buttons/CheckAuthButton";
import AnnulFittingMeetingModal from "../Modals/AnnulFittingMeetingModal";

const annulled = "CANCELED";
const PreviousMeeting = ({ fitting, setBreadcrumbs, embeddedFitting, setFetchFittings }) => {
  const translate = useTranslate();
  const [modal, setModal] = useState("");
  const [meetingId, setMeetingId] = useState();
  const previousMeeting = fitting?.previousMeetings;
  const isCompleted = fitting?.status?.value === 90 || fitting?.status?.value === 99;

  useEffect(() => {
    setBreadcrumbs([
      {
        text: embeddedFitting?.text
          ? translate(embeddedFitting?.text)
          : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}`,
        link: embeddedFitting?.path ? embeddedFitting?.path : pages.MY_FITTINGS.PATH,
      },
      {
        text: `${translate(pages.FITTING_DETAIL.NAME)} - ${translate(pages.MY_FITTINGS_PREVIOUS_MEETING.NAME)}`,
      },
    ]);
  }, [embeddedFitting, setBreadcrumbs, translate]);

  const openModal = meeting => {
    setMeetingId(meeting.id);
    setModal("ANNUL_MEETING");
  };

  const closeModal = () => {
    setMeetingId("");
    setModal("");
  };
  return (
    <section className={styles.previousMeeting}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.split}>
            <th className={styles.columnWidth}>{translate("FITTING_SUB_NAV_PREVIOUS_MEETINGS")}</th>
            <th className={styles.columnWidth}>{translate("CONSULTANT")}</th>
            <th className={styles.columnWidth}>{translate("TECHNICIAN")}</th>
          </tr>
        </thead>

        {previousMeeting && previousMeeting?.length ? (
          <tbody>
            {previousMeeting?.map(meeting => (
              <tr className={styles.row} key={meeting.id}>
                <div className={styles.split}>
                  <td className={styles.columnWidth}>
                    <div className={styles.info}>
                      <Clock customCssClass={styles.icon} />
                      {`${meeting.date || ""} ${meeting.fromTime || ""} - ${meeting.toTime || ""}`}
                    </div>
                    <div className={styles.info}>
                      <Place customCssClass={styles.icon} />
                      {`${meeting.address?.street || ""}, ${meeting.address?.postalNo || ""} ${
                        meeting.address?.city || ""
                      }`}
                    </div>
                    <div className={styles.info}>
                      {meeting.room && meeting.car && (
                        <div className={styles.roomAndCar}>
                          <p>
                            <Room customCssClass={styles.icon} />
                            {meeting.room?.label || ""}
                          </p>
                          <p>
                            <Car customCssClass={styles.icon} /> {meeting.car?.value}
                          </p>
                        </div>
                      )}
                      {meeting.room && !meeting.car && (
                        <p>
                          <Room customCssClass={styles.icon} />
                          {meeting.room?.label || ""}
                        </p>
                      )}
                      {!meeting.room && meeting.car && (
                        <p>
                          <Car customCssClass={styles.icon} /> {meeting.car?.value}
                        </p>
                      )}
                    </div>
                    {!isCompleted && meeting.status !== annulled && (
                      <div className={styles.button}>
                        <CheckAuthButton
                          theme={THEME.PLAIN_PRIMARY}
                          onClick={() => openModal(meeting)}
                          title={translate("ANNUL_FITTING_MEETING")}
                          authId={"FSP_CancelWebOrderFittingMeeting"}
                        />
                      </div>
                    )}
                  </td>
                  <td className={styles.columnWidth}>
                    <div className={styles.info}>
                      {`${meeting.consultant?.firstName || ""} ${meeting.consultant?.surname || ""}`}
                    </div>
                    {meeting.consultant?.phoneNo && <div className={styles.info}>{meeting.consultant?.phoneNo} </div>}
                    {meeting.consultant?.email && <div className={styles.info}>{meeting.consultant?.email}</div>}
                  </td>
                  <td className={styles.columnWidth}>
                    <div className={styles.info}>
                      {`${meeting.technician?.firstName || ""} ${meeting.technician?.surname || ""}`}
                    </div>
                    {meeting.technician?.phonoNo && <p> {meeting.technician?.phoneNo}</p>}
                    {meeting.technician?.email && <p> {meeting.technician?.email}</p>}
                  </td>
                  {meeting.status === annulled && (
                    <td>
                      <div className={styles.status}>
                        <span>{translate("ANNULLED")}</span>
                      </div>
                    </td>
                  )}
                </div>
              </tr>
            ))}
          </tbody>
        ) : (
          <p>{translate("NO_MEETINGS")}</p>
        )}
        <AnnulFittingMeetingModal
          visible={modal === "ANNUL_MEETING"}
          fittingId={fitting.id}
          meetingId={meetingId}
          onClose={() => closeModal()}
          setFetchFittings={setFetchFittings}
        />
      </table>
    </section>
  );
};

PreviousMeeting.propTypes = {
  fitting: PropType.object,
  setBreadcrumbs: PropType.func,
  embeddedFitting: PropType.object,
  setFetchFittings: PropType.func,
};

export default PreviousMeeting;
