import type { URL } from "@myloc/myloc-utils";
import { endpoint } from "../../../../config/settings";

// Define types for the API methods if needed
export const api = {
  bankid: {
    initiateAuth: (): URL => endpoint("/bankid/authentication"),
    collect: (): URL => endpoint("/bankid/collect"),
  },
};
