import classNames from "classnames";
import PropType from "prop-types";
import calculatePrice from "../../../utils/calculatePrice";
import styles from "./Price.module.scss";

const Price = ({ quantity, price, priceQuantity, decimals, currency, customCssClass }) => {
  if (!price) return null;

  return (
    <p className={classNames(styles.price, customCssClass)}>
      {calculatePrice(quantity, price, priceQuantity, decimals)} {currency ?? "SEK"}
    </p>
  );
};

Price.propTypes = {
  quantity: PropType.number,
  price: PropType.number,
  priceQuantity: PropType.number,
  decimals: PropType.number,
  currency: PropType.string,
  customCssClass: PropType.string,
};
export default Price;
