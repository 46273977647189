import { Button, Modal } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";
import { useTranslate } from "../../../language/i18n";
import ProductImage, { ImagePreset } from "../../Products/ProductImage/ProductImage";
import { accessoryProps, inventoryProps } from "./commonProps";
import styles from "./InventoryPopup.module.scss";

const InventoryPopup = ({ inventory, onOrder, onClose, disabled }) => {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  const onClickOrder = async () => {
    setIsLoading(true);
    const response = await onOrder(inventory.quantity, inventory);
    setIsLoading(false);
    if (response.isOk()) {
      onClose();
    }
  };

  return (
    <Modal title={translate("INVENTORY")} visible={!!inventory} onClose={onClose} customCssClass={styles.modal}>
      <div className={styles.product}>
        <ProductImage
          preset={ImagePreset.ICON}
          images={inventory?.images}
          isOrderable={inventory?.isOrderable}
          customCssClass={styles.notOrderable}
        />
        <section className={styles.productArea}>
          <p className={styles.header}>{translate("INVENTORY_NUMBER_SHORT")}:</p>
          <p className={styles.value}>{inventory?.inventoryNumber}</p>
          <p className={styles.header}>{translate("PRODUCT_NUMBER_SHORT")}:</p>
          <p className={styles.value}>{inventory?.productNumber}</p>
          <p className={styles.header}>{translate("PRODUCT_NAME")}:</p>
          <p className={styles.value}>{inventory?.name}</p>
        </section>
      </div>

      <p className={styles.title}>{translate("ACCESSORIES")}</p>
      <div className={styles.product}>
        <section className={styles.accessories}>
          <p className={styles.header}>{translate("PRODUCT_NUMBER_SHORT")}</p>
          <p className={styles.header}>{translate("PRODUCT_NAME")}</p>
        </section>
        {inventory?.accessories?.map(accessory => (
          <Accessory key={accessory.id} accessory={accessory} />
        ))}
      </div>
      <Button isLoading={isLoading} onClick={onClickOrder} disabled={disabled}>
        {translate("ADD_TO_CART")}
      </Button>
    </Modal>
  );
};

const Accessory = ({ accessory }) => (
  <>
    <ProductImage preset={ImagePreset.ICON} images={accessory.images} />
    <div className={styles.accessories}>
      <p>{accessory.productNumber}</p>
      <p>{accessory.name}</p>
    </div>
  </>
);

Accessory.propTypes = {
  accessory: PropType.shape(accessoryProps),
};

InventoryPopup.propTypes = {
  inventory: PropType.shape(inventoryProps),
  onOrder: PropType.func,
  onClose: PropType.func,
  disabled: PropType.bool,
  dontShowLabel: PropType.bool,
};

export default InventoryPopup;
