import { generatePath, Link } from "react-router-dom";
import pages from "../../../../utils/pages";
import { Basket } from "../../../../assets/icons";
import colors from "../../../../style/exports.module.scss";
import styles from "./CartButton.module.scss";
import { useSelector } from "react-redux";

function CartButton() {
  const order = useSelector(state => state.appData.order);

  if (!order || !order.id) {
    return (
      <div className={styles.cartLink}>
        <Basket color={colors.grey} customCssClass={styles.icon} />
      </div>
    );
  }

  return (
    <Link to={generatePath(pages.CART.PATH, { order: order.id })} className={styles.cartLink} aria-label="cart">
      <Basket color={colors.iconColor} customCssClass={styles.icon} />
    </Link>
  );
}

export default CartButton;
