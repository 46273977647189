import { useState } from "react";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { Modal, Button, THEME } from "@myloc/myloc-gui";
import styles from "./CartModals.module.scss";

function DeleteOrderItemModal({ orderData, visible, onClose, onDelete }) {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal title={translate("DELETE_ORDER_ITEM_TITLE")} visible={visible} onClose={onClose} small>
      <p>{translate("DELETE_ORDER_ITEM_TEXT")}</p>
      <footer className={styles.buttons}>
        <Button theme={THEME.SECONDARY} onClick={onClose}>
          {translate("NO")}
        </Button>
        <Button
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            onDelete(orderData);
          }}
        >
          {translate("YES")}
        </Button>
      </footer>
    </Modal>
  );
}

DeleteOrderItemModal.propTypes = {
  orderData: PropType.object,
  visible: PropType.bool,
  onClose: PropType.func,
  onDelete: PropType.func,
};

export default DeleteOrderItemModal;
