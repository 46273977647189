import { Button, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Fragment, memo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, generatePath } from "react-router-dom";
import { useTranslate } from "../../../../language/i18n";
import { clearReceiverMenu } from "../../../../reducers/appData/appDataActions";
import { selectHasFSPAccess } from "../../../../reducers/session/sessionSelectors";
import { CLIENT_TYPE } from "../../../../utils/constants";
import { createMenu } from "../../../../utils/menu";
import OnClickOutside from "../../../../utils/OnClickOutside";
import pages from "../../../../utils/pages";
import Collapse from "../../../shared/Collapse/Collapse";
import styles from "./Menu.module.scss";

function Menu({ userLoggedIn }) {
  const translate = useTranslate();
  const receiverType = useSelector(state => state.appData.order?.receiver?.type);
  const orderId = useSelector(state => state.appData.order?.id);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const hasFSPAccess = useSelector(selectHasFSPAccess);
  const isBaseStorage = receiverType === CLIENT_TYPE.BASE_STORAGE;
  const hideMenuItems = {
    WORK_ORDER_MENU: isBaseStorage,
    FITTING_MENU: isBaseStorage,
    [pages.PICKUP_ORDER.NAME]: isBaseStorage,
    [pages.PRODUCTS_EXTERNAL_ASSORTMENT.NAME]: isBaseStorage,
  };
  const menuItems = createMenu("MENU", hideMenuItems);

  let hideMenu;

  const handleOnMouseLeave = () => {
    hideMenu = setTimeout(() => {
      setMenuVisible(false);
    }, 1000);
  };

  const handleOnMouseEnter = () => {
    clearTimeout(hideMenu);
  };

  const toggleMenu = item => {
    if (item && item?.id === activeMenuItem?.id) {
      setMenuVisible(!menuVisible);
    } else if (item) {
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
    setActiveMenuItem(item);
  };

  const checkIfSearchClient = page => {
    if (page.label === "MENU_CLIENTS") {
      clearReceiverMenu();
    }
  };

  return (
    <OnClickOutside call={() => setMenuVisible(false)}>
      <nav className={styles.navBar} onMouseLeave={handleOnMouseLeave} onMouseEnter={handleOnMouseEnter}>
        {hasFSPAccess && userLoggedIn && (
          <Fragment>
            <div className={styles.navContent}>
              <ul className={styles.menu}>
                {menuItems.map(item => {
                  return (
                    <li key={item.id} className={styles.menuItem}>
                      {item.subMenus && item.subMenus.length > 0 ? (
                        <button
                          className={classNames(
                            styles.menuButton,
                            activeMenuItem && activeMenuItem.id === item.id && menuVisible && styles.active,
                          )}
                          onClick={() => toggleMenu(item)}
                        >
                          {translate(item.label)}
                        </button>
                      ) : (
                        <NavLink
                          to={item.to || "/"}
                          className={styles.menuLink}
                          activeClassName={styles.selected}
                          onClick={() => toggleMenu()}
                        >
                          {translate(item.label)}
                        </NavLink>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>

            <Collapse visible={menuVisible} customCssClass={styles.submenuWrapper}>
              <div className={styles.subMenuContent}>
                {activeMenuItem &&
                  activeMenuItem.subMenus.map((subMenuItem, id) => {
                    return (
                      <figure key={`submenu-${id}`} className={styles.subMenu}>
                        {subMenuItem.title && (
                          <figcaption className={styles.title}>{translate(subMenuItem.title)}</figcaption>
                        )}
                        <ul>
                          {subMenuItem.items.map((i, id) => {
                            return (
                              !i.hidden && (
                                <li key={`submenu-link-${id}`} className={styles.link} onClick={() => toggleMenu()}>
                                  {i.link ? (
                                    <Link
                                      to={generatePath(i.link, { order: orderId || "none" })}
                                      onClick={() => checkIfSearchClient(i)}
                                    >
                                      {translate(i.label)}
                                    </Link>
                                  ) : i.action ? (
                                    <Button theme={THEME.PLAIN} onClick={i.action}>
                                      {translate(i.label)}
                                    </Button>
                                  ) : (
                                    <p>{translate(i.label)}</p>
                                  )}
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </figure>
                    );
                  })}
              </div>
            </Collapse>
          </Fragment>
        )}
      </nav>
    </OnClickOutside>
  );
}

Menu.propTypes = {
  userLoggedIn: PropTypes.bool,
  receiverType: PropTypes.string,
};

export default memo(Menu);
