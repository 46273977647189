import { Button, InputField } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useHistory as navigationRouter } from "react-router-dom";
import { useTranslate } from "../../language/i18n";
import pages from "../../utils/pages";
import { useMobile } from "../../utils/viewport";
import Page from "../shared/Page/Page";

import Search from "../../assets/icons/Search";
import productService from "../../services/product/productService";
import Paginate from "../shared/Pagination/Paginate";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import styles from "./InventoryPage.module.scss";

const InventoryPage = () => {
  const paramHistory = useHistory();
  const navigationHistory = navigationRouter();
  const translate = useTranslate();
  const isMobile = useMobile();
  const params = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState(params.get("q") || "");
  const [searchValueText, setSearchValueText] = useState();
  const [errorMessage, setErrorMessage] = useState({ show: false, message: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data?.inventoryNumber) {
      navigationHistory.push({
        pathname: generatePath(pages.FIXED_ASSET_INVENTORY.PATH, {
          product: data?.id,
        }),
        state: {
          referrer: `${window.location.pathname}${window.location.search}`,
          baseStorage: data?.location?.facility,
        },
      });
    }
  }, [data, navigationHistory]);

  const filterSearch = () => {
    setSearchValueText(searchValue);
  };

  const getProvider = useCallback(
    filter => {
      if (!searchValueText) {
        const message = { show: true, message: "INVALID_SEARCH_QUERY" };
        setErrorMessage(message);
      } else {
        setErrorMessage({ show: true, message: "" });
        return productService.getFixedAsset({ product: searchValueText, ...filter });
      }
    },
    [searchValueText],
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!searchValue) {
      params.delete("q");
    } else if (searchValue !== params.get("q")) {
      params.set("q", searchValue);
    }
    paramHistory.replace(pages.INVENTORIES.PATH, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onKeyDown = event => {
    if (event.key === "Enter") {
      setSearchValueText(event.target.value);
    }
  };

  const onClearSearch = () => {
    setSearchValue("");
  };

  return (
    <Page title={translate(pages.INVENTORIES.NAME)}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setData}
        loadOnMount={false}
        errorMessage={errorMessage}
        provider={useCallback(filter => getProvider(filter), [getProvider])}
        skipSearch={!searchValueText}
      >
        <div className={styles.generalSearch}>
          <InputField
            label={translate("SEARCH_FOR_INVENTORY_NUMBER")}
            value={searchValue}
            onChange={event => setSearchValue(event.target.value)}
            onKeyDown={onKeyDown}
            onClear={onClearSearch}
          />
          <Button onClick={() => filterSearch()} customCssClass={styles.button}>
            {isMobile ? <Search /> : translate("SEARCH")}
          </Button>
        </div>
        {isLoading && <LoadingSpinner title="INVENTORIES_LOADING" />}
      </Paginate>
    </Page>
  );
};

InventoryPage.propTypes = {
  setBreadcrumbs: PropType.func,
};

export default InventoryPage;
