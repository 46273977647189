import { AnyAction, combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";

import { appDataReducer } from "./appData/appDataReducer";
import commonReducer from "./common/commonReducer";
import { dialogReducer } from "./dialog/dialogReducer";
import { embeddedReducer } from "./embedded/embeddedReducer";
import { sessionReducer } from "./session/sessionReducer";
import { RESET_SESSION } from "./session/sessionVariables";
import settingReducer from "./setting/settingReducer";

export interface RootState {
  session: ReturnType<typeof sessionReducer>;
  dialog: ReturnType<typeof dialogReducer>;
  common: ReturnType<typeof commonReducer>;
  setting: ReturnType<typeof settingReducer>;
  appData: ReturnType<typeof appDataReducer>;
  embedded: ReturnType<typeof embeddedReducer>;
}

const combinedReducer = combineReducers({
  session: sessionReducer,
  dialog: dialogReducer,
  appData: appDataReducer,
  common: commonReducer,
  embedded: embeddedReducer,
  setting: settingReducer,
});

const rootReducer: Reducer<RootState | undefined, AnyAction> = (state, action) => {
  if (action.type === RESET_SESSION) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export default store;
