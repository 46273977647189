import { Cross, IconButton } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { CircleAddFilled } from "../../../../assets/icons";
import { useTranslate } from "../../../../language/i18n";
import { FileDownload } from "../../../shared/File/FileDownload/FileDownload";
import styles from "./FileUpload.module.scss";

function FileUpload({ files: filesProp = [], acceptedFilesList, setFiles }) {
  const translate = useTranslate();

  const getFileIndex = file => {
    return filesProp.findIndex(
      uploadedFile =>
        uploadedFile.name === file.name &&
        uploadedFile.size === file.size &&
        uploadedFile.lastModified === file.lastModified,
    );
  };

  const handleOnChange = event => {
    const _files = [...filesProp];
    const selectedFiles = event.target.files;

    if (selectedFiles?.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];

        file.url = URL.createObjectURL(file);

        _files.push(file);
      }

      setFiles(_files);

      event.target.value = "";
    }
  };

  const onDelete = (event, file) => {
    event.preventDefault();

    const matchingFileIndex = getFileIndex(file);

    if (matchingFileIndex > -1) {
      const _files = [...filesProp];
      _files.splice(matchingFileIndex, 1);

      setFiles(_files);
    }
  };

  return (
    <section className={styles.fileUploadWrapper}>
      <label htmlFor={`fileupload-deviation`} className={styles.label}>
        <CircleAddFilled /> <span className={styles.text}>{translate("UPLOAD_DOCUMENT")}</span>
      </label>
      <input
        type="file"
        name="file"
        id={`fileupload-deviation`}
        multiple
        onChange={event => handleOnChange(event)}
        className={styles.inputFile}
        accept={acceptedFilesList}
      />

      <ul className={styles.selectedList}>
        {filesProp.map((file, index) => (
          <li key={`${file?.name}-${index}`} className={styles.selected}>
            <FileDownload file={file} />
            <IconButton onClick={event => onDelete(event, file)} customCssClass={styles.iconBtn}>
              <Cross size={"16"} />
            </IconButton>
          </li>
        ))}
      </ul>
    </section>
  );
}
FileUpload.propTypes = {
  acceptedFilesList: PropTypes.string,
  files: PropTypes.array,
  setFiles: PropTypes.func,
};

FileUpload.defaultProps = {
  acceptedFilesList:
    "image/*, .pdf, .xls, .xlsx, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export default FileUpload;
