import { useCallback, useState } from "react";

import singleSignOnService from "../services/singleSignOn/singleSignOnService";
import storage from "../utils/store/storage";
import { SINGLE_SIGN_ON_RESPONSE } from "../utils/store/storeVariables";

const useFetchSingleSignOnUserData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const loadUserData = useCallback(async () => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);
    setErrorMessage(undefined);

    const response = await singleSignOnService.getUserData();

    let isOk = false;

    if (response?.data?.id_token) {
      await storage.saveItem(SINGLE_SIGN_ON_RESPONSE, response.data.id_token);
      isOk = true;
    }

    setIsSuccess(isOk);
    setIsError(!isOk);

    if (!isOk) setErrorMessage(response.message);

    setIsLoading(false);

    if (isOk) {
      return { isError: false };
    } else {
      return {
        isError: true,
        message: response.message,
        httpStatusCode: response.statusCode,
      };
    }
  }, []);

  return { loadUserData, isLoading, isSuccess, isError, errorMessage };
};

export default useFetchSingleSignOnUserData;
