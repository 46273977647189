import { useSelector } from "react-redux";
import { useTranslate } from "../../../../language/i18n";
import styles from "./FacilityAddressInfo.module.scss";

function FacilityAddressInfo() {
  const translate = useTranslate();
  const receiver = useSelector(state => state.appData.order?.receiver);
  const user = useSelector(state => state.appData.user?.information);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{translate("ADDRESS")}</h4>
      <address className={styles.address}>
        <span>
          {receiver?.value} - {receiver?.label}
        </span>
        <span>{receiver?.address?.street}</span>
        <span>
          {receiver.address?.postalNo} {receiver.address?.city}
        </span>
      </address>
      <h4 className={styles.title}>{translate("CONTACT_PERSON")}</h4>
      <p>
        {user?.firstName} {user?.surname}
      </p>
      <p>{user?.phoneNo}</p>
      <p>{user?.email}</p>
    </div>
  );
}
export default FacilityAddressInfo;
