import { InputField, TextArea } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import messageService from "../../../../services/message/messageService";
import { getTimestamp } from "../../../../utils/DateUtil";
import FileUpload from "../../../Deviation/Description/FileUpload/FileUpload";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";
import SelectField from "../../../shared/SelectField/SelectField";
import styles from "./NewMessageModal.module.scss";

const NewMessageModal = ({ visible, setVisible, replyToMessage = null }) => {
  const translate = useTranslate();
  const [availableRecipients, setAvailableRecipients] = useState([]);
  const [recipient, setRecipient] = useState();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);

  const onCancel = () => {
    setFiles([]);
    setSubject(null);
    setMessage(null);
    setRecipient(undefined);
    setVisible(false);
  };

  const onSubmit = async () => {
    const messageToSend = {
      receiverId: decodeURIComponent(recipient.id),
      receiverEmail: recipient.email,
      subject,
      body: message,
    };

    const response = await messageService.sendMessage(messageToSend, files ?? []);

    if (response.statusCode === 200) {
      setVisible(false);
      window.location.reload();
    }
  };

  const buildPreviousMessage = useCallback(
    previousMessage => {
      const line = "-".repeat(120);
      let message = "";
      message += `\n\n`;
      message += `${line}\n`;
      message += `${previousMessage.subject} ${" ".repeat(60 - previousMessage.subject.length)} ${getTimestamp(
        previousMessage.sentDate,
      )}\n`;
      message += `${translate("FROM")}: ${previousMessage.sender.email}\n`;
      message += `${translate("TO")}: ${previousMessage.receiver.email}\n`;
      message += `${line}\n`;
      message += `${previousMessage.body}\n`;

      return message;
    },
    [translate],
  );

  const setInformationFromMessage = useCallback(() => {
    setRecipient(replyToMessage.sender);
    setSubject(`RE: ${replyToMessage.subject}`);
    setMessage(buildPreviousMessage(replyToMessage));
  }, [replyToMessage, buildPreviousMessage]);

  useEffect(() => {
    if (replyToMessage) setInformationFromMessage();
  }, [replyToMessage, setInformationFromMessage]);

  useEffect(() => {
    if (visible)
      messageService.getMessageReceivers({ all: true }).then(response => {
        setAvailableRecipients(response.data.page);
      });
  }, [visible]);

  const onSelect = selected => {
    setRecipient(selected);
  };

  return (
    <ConfirmModal
      title={translate("NEW_MESSAGE")}
      declineButtonText={translate("CANCEL")}
      confirmButtonText={translate("SEND")}
      visible={visible}
      cancel={onCancel}
      submit={onSubmit}
      small={false}
      confirmButtonDisabled={!recipient || !subject || !message}
    >
      <div>
        <SelectField
          options={availableRecipients?.map(current => {
            return {
              id: current.id,
              value: `${current.firstName} ${current.surname} - ${current.id}`,
              email: current.email,
            };
          })}
          selectedId={recipient?.id}
          label={`${translate("TO")}:`}
          onSelect={onSelect}
        />
        <div>
          <InputField
            label={translate("SUBJECT")}
            value={subject}
            onChange={e => setSubject(e.target.value)}
            customCssClass={styles.input}
          />
        </div>
        <TextArea placeholder={translate("WRITE_MESSAGE")} value={message} onChange={e => setMessage(e.target.value)} />
        <FileUpload files={files} setFiles={setFiles} />
      </div>
    </ConfirmModal>
  );
};

NewMessageModal.propTypes = {
  visible: PropType.bool.isRequired,
  setVisible: PropType.func.isRequired,
  replyToMessage: PropType.object,
};

export default NewMessageModal;
