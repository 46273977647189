import PropType from "prop-types";
import LogisticsConfirmModal from "../../shared/Modals/LogisticsConfirmModal";
import { useTranslate } from "../../../language/i18n";
import styles from "../FittingDetailPage";

const CloseFittingModal = ({ visible, onClose, action, onSubmit }) => {
  const translate = useTranslate();

  return (
    <LogisticsConfirmModal
      title={translate(`${action}_FITTING`)}
      text={translate(`${action}_FITTING_DESCRIPTION`)}
      visible={visible}
      cancel={onClose}
      small={false}
      submit={onSubmit}
      customCssClass={styles.modal}
    ></LogisticsConfirmModal>
  );
};

CloseFittingModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  action: PropType.string,
  onSubmit: PropType.func,
};

export default CloseFittingModal;
