import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, InputField, THEME } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import { useTranslate } from "../../language/i18n";
import baseStorageService from "../../services/baseStorage/baseStorageService";
import { CLIENT_TYPE } from "../../utils/constants";
import pages from "../../utils/pages";

import useDebounce from "../../hooks/useDebounce";
import { decodeParam } from "../../utils/decodeParam";
import { encodeParam } from "../../utils/encodeParam";
import ProductFilter from "../Products/ProductFilter/ProductFilter";
import FacilitySelector from "../shared/FacilitySelector/FacilitySelector";
import Page from "../shared/Page/Page";
import Paginate from "../shared/Pagination/Paginate";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import BaseStorageItem from "./BaseStorageItem";
import styles from "./BaseStoragesPage.module.scss";
import CreateBaseStorageMoveModal from "./Move/CreateBaseStorageMoveModal";

const productFilters = [
  { id: "itemClasses", title: "ITEM_CLASS" },
  { id: "isoCodes", title: "ISO_CODE" },
  { id: "suppliers", title: "SUPPLIER" },
  { id: "productStatuses", title: "PRODUCT_STATUS" },
];

// Converts encoded product filter params to usable filters
const getProductFiltersFromParams = params => {
  const reducedFilters = productFilters.reduce(
    (filters, val) => ({
      ...filters,
      [val.id]: {
        id: decodeParam(params.get(val.include)) || decodeParam(params.get(val.id)) || [],
        title: val.title,
        include: decodeParam(params.get(val.id[0])) || val.include,
      },
    }),
    {},
  );

  if (
    reducedFilters.includeNotOrderable?.id?.length &&
    JSON.parse(reducedFilters.includeNotOrderable?.id[0]) !== reducedFilters.includeNotOrderable?.include
  ) {
    reducedFilters.includeNotOrderable.include = JSON.parse(reducedFilters.includeNotOrderable?.id[0]);
  }
  return reducedFilters;
};

const BaseStoragesPage = () => {
  const history = useHistory();
  const translate = useTranslate();
  const userFacility = useSelector(state => state.appData?.user?.facility);
  const order = useSelector(state => state.appData?.order);
  const params = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateOrderMoveModal, setShowCreateOrderMoveModal] = useState(false);

  const getBaseStorage = () => {
    const paramStorage = params.get("storage");
    if (paramStorage) return { id: paramStorage };
    else if (userFacility?.category?.value > 0) return { id: userFacility?.id };
    else return null;
  };

  const [baseStorage, setBaseStorage] = useState(getBaseStorage());
  const [search, setSearch] = useState(params.get("q") || "");
  const debouncedSearchValue = useDebounce(search);
  const [stock, setStock] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(true);
  const [filters, setFilters] = useState({
    freeTextSearch: debouncedSearchValue,
    baseStorage: baseStorage?.id ? decodeURIComponent(baseStorage?.id) : null,
  });
  const [showFilter, setShowFilter] = useState(false);

  const [productFilters, setProductFilters] = useState(getProductFiltersFromParams(params));

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!baseStorage?.id) {
      params.delete("storage");
    } else if (baseStorage?.id && params.get("storage") !== baseStorage.id) {
      params.set("storage", baseStorage.id);
    } else if (!params.get("storage")) {
      if (userFacility?.category?.value > 0) {
        params.set("storage", userFacility.id);
      }
    }

    if (!debouncedSearchValue) {
      params.delete("q");
    } else if (debouncedSearchValue !== params.get("q")) {
      params.set("q", debouncedSearchValue);
    }

    const productFiltersChanged = (params, filters) => {
      if (!filters) return false;

      return Object.entries(filters).some(([key, value]) => params.get(key) != encodeParam(value));
    };

    if (productFiltersChanged(params, productFilters)) {
      Object.entries(productFilters).forEach(([key, value]) => {
        if (key === "includeNotOrderable") {
          value.include ? params.set(key, value.include) : params.delete(key);
        } else {
          value.id?.length ? params.set(key, encodeParam(value)) : params.delete(key);
        }
      });
    }

    history.replace(pages.BASE_STORAGES.PATH, params);
  }, [userFacility, baseStorage, debouncedSearchValue, history, productFilters]);

  const clear = filterCategory => {
    let _filters = { ...productFilters };
    if (filterCategory === "includeNotOrderable") {
      _filters[filterCategory].include = false;
    }

    _filters[filterCategory].id = [];

    setProductFilters(_filters);
  };

  const activeFilters = () => {
    if (!productFilters) {
      return [];
    }

    const filters = Object.keys(productFilters).filter(
      category => !!productFilters[category].id?.length || productFilters[category].include,
    );

    return filters.map(filterCategory => (
      <span className={styles.badge} key={filterCategory}>
        {translate(productFilters[filterCategory].title)} ({productFilters[filterCategory].id.length || "1"})
        <button type="button" onClick={() => clear(filterCategory)}>
          ✕
        </button>
      </span>
    ));
  };

  const breadcrumbs = [
    {
      text: translate(pages.BASE_STORAGES.NAME),
    },
  ];

  const baseStorageId = baseStorage?.id;

  const fullSearch = useCallback(() => {
    setFilters({
      freeTextSearch: debouncedSearchValue,
      baseStorage: baseStorageId ? decodeURIComponent(baseStorageId) : null,
    });
  }, [debouncedSearchValue, baseStorageId]);

  useEffect(() => {
    fullSearch();
  }, [debouncedSearchValue, baseStorage, fullSearch]);

  const handleChange = useCallback(baseStorage => {
    if (baseStorage) {
      setBaseStorage(baseStorage);
    } else {
      setBaseStorage(null);
    }
  }, []);

  const sortedStock = stock?.length
    ? stock.sort((a, b) => {
        if (a?.product?.name > b?.product?.name) return 1;
        if (a?.product?.name < b?.product?.name) return -1;
        return 0;
      })
    : [];

  const getProvider = useCallback(
    async filter => {
      if (filters?.baseStorage !== null) {
        setShowErrorMessage(true);
        return baseStorageService.getBalance({
          ...filters,
          isoCode: productFilters?.isoCodes?.id.map(iso => decodeURIComponent(iso))?.[0],
          supplier: productFilters?.suppliers?.id.map(supplier => decodeURIComponent(supplier))?.[0],
          itemClass: productFilters?.itemClasses?.id.map(itemClass => decodeURIComponent(itemClass))?.[0],
          productStatus: productFilters?.productStatuses?.id.map(status => decodeURIComponent(status))?.[0],
          ...filter,
        });
      } else {
        setShowErrorMessage(false);
      }
    },
    [filters, productFilters],
  );

  return (
    <Page title={translate("STOCK_BALANCE")} breadcrumbs={breadcrumbs}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setStock}
        provider={useCallback(filter => getProvider(filter), [getProvider])}
        errorMessage={{ show: showErrorMessage, message: "" }}
        loadOnMount={!!baseStorage?.id}
      >
        <ProductFilter
          showFilter={showFilter}
          hideFilter={() => setShowFilter(false)}
          productFilters={productFilters}
          setProductFilters={setProductFilters}
        >
          <section>
            <section className={styles.search}>
              <FacilitySelector preSelected={baseStorage} onSelect={handleChange} />
              <div className={styles.searchAndButtonWrapper}>
                <InputField
                  label={translate("SEARCH_PRODUCT_NR_OR_NAME")}
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                  customCssClass={styles.noErrorField}
                />
              </div>
              {baseStorage && (
                <div className={styles.filterButton}>
                  <Button theme={THEME.SECONDARY} onClick={() => setShowFilter(filter => !filter)}>
                    {translate("FILTER")}
                  </Button>
                </div>
              )}
            </section>
            <section className={styles.toolBar}>
              <div className={styles.activeFilters}>{activeFilters()}</div>
              {(order?.receiver == null || order.receiver?.type != CLIENT_TYPE.BASE_STORAGE) && (
                <div className={styles.baseStorageMoveButton}>
                  <Button
                    customCssClass={styles.createBaseStorageMoveButton}
                    onClick={() => setShowCreateOrderMoveModal(true)}
                  >
                    {translate("CREATE_BASE_STORAGE_MOVE_ORDER")}
                  </Button>
                </div>
              )}
            </section>
          </section>
          {sortedStock.length > 0 && (
            <section className={styles.results}>
              {isLoading ? (
                <LoadingSpinner title="PRODUCTS_LOADING" />
              ) : (
                sortedStock.map(stockItem => (
                  <BaseStorageItem
                    key={stockItem.id}
                    item={stockItem}
                    baseStorage={baseStorage}
                    hideLinks={
                      !baseStorage?.id ||
                      (order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE && order?.receiver?.id !== baseStorage?.id)
                    }
                    showBaseStorageMoveOption={
                      order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE && order?.receiver?.id !== baseStorage?.id
                    }
                  />
                ))
              )}
            </section>
          )}
        </ProductFilter>
      </Paginate>
      <CreateBaseStorageMoveModal
        defaultBaseStorage={baseStorage}
        visible={showCreateOrderMoveModal}
        onCancel={() => setShowCreateOrderMoveModal(false)}
      />
    </Page>
  );
};

export default BaseStoragesPage;
