import { LoadingButton } from "@mui/lab";
import colors from "../../../style/exports.module.scss";
import styles from "./style/spinningButton.module.scss";

const THREE_HUNDRED = 300;

export type SpinningButtonVariant = "text" | "outlined" | "contained";

// Base interface for common props
interface BaseButtonProps {
  buttonAction: () => void;
  loading?: boolean;
  children: React.ReactNode;
  variant?: SpinningButtonVariant;
  widthPX?: number;
}

// Icon case: Icon must be provided, iconPosition must be "start" or "end"
interface ButtonWithIconProps extends BaseButtonProps {
  icon: React.ReactNode; // Icon must be provided
  iconPosition: "start" | "end"; // Icon position must be provided when an icon is set
}

// No icon case: icon and iconPosition are not allowed
interface ButtonWithoutIconProps extends BaseButtonProps {
  icon?: never; // Icon is not allowed
  iconPosition?: never; // iconPosition is not allowed
}

// Use a union type to enforce the relationship between 'icon' and 'iconPosition'
type ButtonComponentProps = ButtonWithIconProps | ButtonWithoutIconProps;

const SpinningButton = ({
  buttonAction,
  loading = false,
  icon,
  iconPosition,
  children,
  variant = "contained",
  widthPX = THREE_HUNDRED,
}: ButtonComponentProps) => {
  return (
    <LoadingButton
      variant={variant}
      startIcon={icon && iconPosition === "start" ? <div className={styles.iconWrapper}>{icon}</div> : undefined} // Workaround to show the icon on iphone
      endIcon={icon && iconPosition === "end" ? <div className={styles.iconWrapper}>{icon}</div> : undefined}
      sx={{
        width: `${widthPX}px`,
        height: "40px",
        fontSize: "16px",
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        backgroundColor: variant === "contained" ? colors.primary : undefined,
        color: variant === "outlined" ? colors.primary : undefined,
        "&:hover": {
          backgroundColor: variant === "contained" ? colors.primaryDark : undefined,
        },
        marginY: 2,
        borderRadius: "8px",
      }}
      onClick={buttonAction}
      loading={loading}
    >
      {children}
    </LoadingButton>
  );
};

export default SpinningButton;
