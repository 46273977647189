import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../../language/i18n";
import { CLIENT_TYPE } from "../../../../utils/constants";
import { useTablet } from "../../../../utils/viewport";
import { openOrderPopup } from "../../../../reducers/dialog/dialogAction";
import { Button, THEME } from "@myloc/myloc-gui";
import MessageBox, { Type } from "../../../shared/MessageBox/MessageBox";
import OrderButton from "./OrderButton";
import OrderControl from "./OrderControl";
import ProductQuantity from "./ProductQuantity";
import ReceiverLocationSelector from "./ReceiverLocationSelector";
import StorageSelector from "./StorageSelector";
import StorageInformation from "./StorageInformation";
import { DELIVERY_LOCATION, getOrderControlValue, isOrderControlMandatory, ORDER_CONTROL } from "./productConstants";
import styles from "./ProductOrder.module.scss";

const ProductOrder = ({
  onClick,
  receiverLocation,
  onSetReceiverLocation,
  product,
  selectedStorage,
  onSelectStorage,
  isActiveReferencedOrder,
}) => {
  const translate = useTranslate();
  const receiver = useSelector(state => state.appData.order?.receiver);
  const isTablet = useTablet();

  const [quantity, setQuantity] = useState(1);
  const [orderControlChecked, setOrderControlChecked] = useState(isOrderControlMandatory(product?.orderControl));
  const hideBaseStorageDelivery = receiver?.type === CLIENT_TYPE.BASE_STORAGE && !product.isOrderable;

  useEffect(() => {
    onSetReceiverLocation(
      receiver?.type === CLIENT_TYPE.BASE_STORAGE || !product?.storageBalances?.length || isActiveReferencedOrder
        ? DELIVERY_LOCATION.DELIVERY
        : DELIVERY_LOCATION.BASE_STORAGE,
    );
  }, [onSetReceiverLocation, product?.storageBalances?.length, receiver, isActiveReferencedOrder]);

  useEffect(() => {
    setOrderControlChecked(
      receiverLocation.id === DELIVERY_LOCATION.DELIVERY.id && isOrderControlMandatory(product?.orderControl),
    );
  }, [receiverLocation, product?.orderControl]);

  const onOrder = async () => {
    const orderControl = orderControlChecked ? getOrderControlValue(product?.orderControl) : ORDER_CONTROL.NONE.value;
    await onClick(quantity, null, orderControl);
  };

  return (
    <section className={styles.container}>
      {!receiver && !isActiveReferencedOrder && (
        <>
          <MessageBox
            type={Type.Warning}
            message={translate("NO_RECEIVER_SELECTED")}
            customCssClass={styles.messageBox}
          >
            <Button
              theme={THEME.SECONDARY}
              onClick={() => openOrderPopup({ includeNavigation: false })}
              customCssClass={styles.messageButton}
            >
              {translate("NEW_ORDER")}
            </Button>
          </MessageBox>
        </>
      )}

      {isTablet ? (
        <>
          {((product?.isInventory && product.isOrderable) || (!product.isInventory && !hideBaseStorageDelivery)) && (
            <>
              <ProductQuantity
                receiver={receiver}
                receiverLocation={receiverLocation.id}
                setQuantity={setQuantity}
                isInventory={product?.isInventory}
              />
              <OrderButton
                receiver={receiver}
                onClick={onOrder}
                receiverLocation={receiverLocation.id}
                product={product}
                selectedStorage={selectedStorage}
              />
            </>
          )}
          {receiver?.type === CLIENT_TYPE.BASE_STORAGE && product.isOrderable && (
            <div className={styles.baseStorageReceiver}>{`${translate("PRODUCT_DELIVERY_TO")} ${receiver.value} - ${
              receiver.label
            } (${product?.replenishmentGroup?.label})`}</div>
          )}
          {!hideBaseStorageDelivery && (
            <>
              <ReceiverLocationSelector
                setReceiverLocation={onSetReceiverLocation}
                selected={receiverLocation.id}
                receiver={receiver}
                product={product}
                isActiveReferencedOrder={isActiveReferencedOrder}
              />
              <StorageSelector
                storageBalances={product?.storageBalances}
                selectedStorage={selectedStorage}
                onSelectStorage={onSelectStorage}
              />
            </>
          )}
          {product?.isOrderable && (
            <OrderControl
              receiverLocation={receiverLocation.id}
              checked={orderControlChecked}
              onToggle={() => setOrderControlChecked(!orderControlChecked)}
              orderControl={product?.orderControl}
            />
          )}
        </>
      ) : (
        <>
          {receiver?.type === CLIENT_TYPE.BASE_STORAGE && product.isOrderable ? (
            <div className={styles.baseStorageReceiver}>{`${translate("PRODUCT_DELIVERY_TO")} ${receiver.value} - ${
              receiver.label
            } (${product?.replenishmentGroup?.label})`}</div>
          ) : (
            <>
              {!hideBaseStorageDelivery && (
                <>
                  <ReceiverLocationSelector
                    setReceiverLocation={onSetReceiverLocation}
                    selected={receiverLocation.id}
                    receiver={receiver}
                    product={product}
                    isActiveReferencedOrder={isActiveReferencedOrder}
                  />
                  {!isActiveReferencedOrder && (
                    <StorageInformation
                      product={product}
                      selectedStorage={selectedStorage}
                      onSelectStorage={onSelectStorage}
                      receiverLocation={receiverLocation.id}
                      checked={orderControlChecked}
                      onToggle={() => setOrderControlChecked(!orderControlChecked)}
                    />
                  )}
                </>
              )}
            </>
          )}
          <div className={styles.inline}>
            {((product?.isInventory && product.isOrderable) || (!product.isInventory && !hideBaseStorageDelivery)) && (
              <>
                <ProductQuantity
                  receiver={receiver}
                  receiverLocation={receiverLocation.id}
                  setQuantity={setQuantity}
                  isInventory={product?.isInventory}
                  isActiveReferencedOrder={isActiveReferencedOrder}
                />
                <OrderButton
                  receiver={receiver}
                  onClick={onOrder}
                  receiverLocation={receiverLocation.id}
                  product={product}
                  selectedStorage={selectedStorage}
                  isActiveReferencedOrder={isActiveReferencedOrder}
                />
              </>
            )}
          </div>
        </>
      )}
    </section>
  );
};

ProductOrder.propTypes = {
  product: PropTypes.shape({
    isInventory: PropTypes.bool,
    orderControl: PropTypes.string,
    quantityDecimals: PropTypes.number,
    quantity: PropTypes.number,
    accessories: PropTypes.array,
    isOrderable: PropTypes.bool,
    storageBalances: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
    replenishmentGroup: PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  selectedStorage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    quantity: PropTypes.number,
  }),
  onSelectStorage: PropTypes.func,
  receiverLocation: PropTypes.shape({
    id: PropTypes.string,
  }),
  onSetReceiverLocation: PropTypes.func,
  onClick: PropTypes.func,
  isActiveReferencedOrder: PropTypes.bool,
};

export default ProductOrder;
