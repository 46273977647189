import { memo } from "react";
import { useSelector } from "react-redux";
import { Modal } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import { closeHoldingPopup } from "../../../reducers/dialog/dialogAction";
import styles from "./HoldingPopup.module.scss";
import ClientHoldingList from "../ClientHoldingList/ClientHoldingList";

function HoldingPopup() {
  const holdingPopup = useSelector(state => state.dialog.holdingPopup);
  const receiver = useSelector(state => state.appData.order?.receiver);
  const translate = useTranslate();

  const closeModal = () => {
    closeHoldingPopup();
  };

  return (
    <div className={styles.modal}>
      <Modal visible={!!holdingPopup?.visible} title={translate("HOLDING_LIST")} onClose={closeModal}>
        {receiver && <ClientHoldingList clientId={receiver.id} readOnly />}
      </Modal>
    </div>
  );
}

export default memo(HoldingPopup);
