import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../config/settings";
import { totalQuantity, totalReportedQuantity, totalReturnedQuantity } from "../utils/calculateAllocation";
import { ACTION_TYPES } from "../utils/constants";
import setQueryFilters from "../utils/setQueryFilters";
import defaultRestOptions from "./utils/defaultRestOptions";

async function getTasks(filters = {}) {
  const url = api.tasks();
  if (filters) setQueryFilters(filters, url);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getTask(id) {
  const url = api.tasks(id);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function doAllocationTask({ id, allocations, action, task }) {
  const url = api.tasks(id);
  const request = new Request(url, Method.POST);

  const close =
    totalReturnedQuantity(task.lines) + totalReportedQuantity(task.lines) + totalQuantity(allocations) ===
    totalQuantity(task.lines);
  allocations.forEach(allocation => (allocation.action = action));

  return await sendRequest(
    request,
    {
      action: ACTION_TYPES.REPORT,
      payload: {
        close,
        allocations,
      },
    },
    await defaultRestOptions(),
  );
}

async function doLineTask({ id, lines, task, closeTask = false }) {
  const url = api.tasks(id);
  const request = new Request(url, Method.POST);

  const totalQuantity = lines => lines.reduce((sum, line) => sum + line.quantity, 0);
  const reduceReportedQuantity = lines => lines.reduce((sum, line) => sum + (line.quantity - line.reportedQuantity), 0);

  const close = closeTask || totalQuantity(lines) === reduceReportedQuantity(task.lines);

  return await sendRequest(
    request,
    {
      action: ACTION_TYPES.REPORT,
      payload: {
        close,
        lines,
      },
    },
    await defaultRestOptions(),
  );
}

export default {
  getTasks,
  getTask,
  doAllocationTask,
  doLineTask,
};
