import { Button, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { useSelector } from "react-redux";
import { Dot } from "../../../../assets/icons";
import { useTranslate } from "../../../../language/i18n";
import messageService from "../../../../services/message/messageService";
import colors from "../../../../style/exports.module.scss";
import { getTimestamp } from "../../../../utils/DateUtil";
import { MESSAGE_TYPE } from "../MessageTypes";
import styles from "./MessageRow.module.scss";

const MessageRow = ({
  message,
  handleOnClickMessage,
  activeMessage,
  setMessages,
  setSelectedMessageId,
  messageType,
}) => {
  const translate = useTranslate();
  const user = useSelector(state => state.appData.user);
  const userIsReceiver = message.receiver.id === decodeURIComponent(user.information.id);

  const getReceiverOrSender = () => {
    if (messageType === MESSAGE_TYPE.SENT) return `${message.receiver.firstName} ${message.receiver.surname}`;
    if (userIsReceiver) return `${message.sender.firstName} ${message.sender.surname}`;

    return `${message.receiver.firstName} ${message.receiver.surname}`;
  };

  const toggleArchived = async () => {
    const data = {
      action: messageType === MESSAGE_TYPE.ARCHIVED ? "UNARCHIVE" : "ARCHIVE",
    };
    const response = await messageService.performMessageAction(message.id, data);

    if (response.isOk()) {
      if (setMessages)
        setMessages(prev => {
          const index = prev.findIndex(m => m.id === message.id);
          const newMessages = [...prev];
          newMessages.splice(index, 1);
          return newMessages;
        });
      setSelectedMessageId("");
    }
  };

  return (
    <div
      className={classNames(styles.results, activeMessage && styles.active)}
      onClick={() => handleOnClickMessage(message)}
    >
      <div className={styles.sender}>
        <span className={classNames(!message.receiver.isRead && userIsReceiver && styles.unread)}>
          {!message.receiver.isRead && userIsReceiver && <Dot size={"16"} color={colors.primary} />}
        </span>
        <span className={classNames(styles.title, (message.receiver.isRead || !userIsReceiver) && styles.read)}>
          {getReceiverOrSender()}
        </span>
      </div>
      <div className={styles.date}>{getTimestamp(message.sentDate)}</div>
      <div className={styles.subject}>{message.subject}</div>

      {messageType != MESSAGE_TYPE.ALL && (
        <div className={styles.archive}>
          <Button theme={THEME.PLAIN_PRIMARY} onClick={toggleArchived}>
            {translate(messageType === MESSAGE_TYPE.ARCHIVED ? "UNARCHIVE" : "ARCHIVE")}
          </Button>
        </div>
      )}
    </div>
  );
};

MessageRow.propTypes = {
  message: PropType.object,
  handleOnClickMessage: PropType.func,
  activeMessage: PropType.bool,
  setMessages: PropType.func,
  setSelectedMessageId: PropType.func,
  messageType: PropType.string,
};

export default MessageRow;
