// LoginWelcome.js
import { Button, THEME } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useEffect } from "react";

import pages from "../../../utils/pages";
//import BankIdAuthentication, { BankIDLoginAlternatives } from "../../shared/BankId/BankIdAuthentication";
import classNames from "classnames";
import { BankIDLoginAlternatives } from "../../shared/BankId/BankIdAuthentication";
import SpinningButton from "../../shared/SpinningButton/SpinningButton";
import Login from "../Login/Login";
import styles from "../Login/Login.module.scss";
import useAvailableLoginMethods, { LOGIN_METHODS } from "./hooks/useAvailableLoginMethods";
import useSelectedLoginMethod from "./hooks/useSelectedLoginMethod";
import Welcome from "./Welcome";

/*
const LoginFormSelectorButton = ({ children }) => (
  <div className={styles.loginFormSelectorButton}>
    <Button> {children}</Button>
  </div>
);
*/

const LoginFormLogin = ({ isSelected, onSelect, ...props }) => (
  <div className={styles.loginFormContainer} onClick={onSelect}>
    {isSelected ? (
      <Login {...props} registerUrl={pages.REGISTER} forgotPasswordUrl={"/"} />
    ) : (
      <SpinningButton>Logga in med användarnamn</SpinningButton>
    )}
  </div>
);
LoginFormLogin.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const BankIDSelector = ({ isSelected, onSelect, children }) => {
  return (
    <>
      {isSelected ? (
        children
      ) : (
        <div onClick={onSelect}>
          <SpinningButton>Logga in med BankID</SpinningButton>
        </div>
      )}
    </>
  );
};

BankIDSelector.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const Logins = ({ availableLoginMethods, isLoginFormSelected, isBankIDSelected, setSelectedLoginMethod, ...props }) => {
  return (
    <div className={styles.loginFormsOuterContainer}>
      <div className={styles.loginFormContainer}>
        {availableLoginMethods.length > 1 && !(isLoginFormSelected() || isBankIDSelected()) && (
          <div className={classNames(styles.sectionTitle, styles.textAlignLeft)}>Välj inloggningsmetod</div>
        )}
      </div>
      {availableLoginMethods.map((method, index) => {
        if (method === LOGIN_METHODS.BANKID && !isLoginFormSelected()) {
          return (
            <div className={styles.loginFormContainer} key={`LoginForm-${index}`}>
              <BankIDSelector
                isSelected={isBankIDSelected()}
                onSelect={() => setSelectedLoginMethod(LOGIN_METHODS.BANKID)}
              >
                <BankIDLoginAlternatives />
              </BankIDSelector>
            </div>
          );
        } else if (method === LOGIN_METHODS.LOGIN_FORM && !isBankIDSelected()) {
          return (
            <LoginFormLogin
              key={`LoginForm-${index}`}
              isSelected={isLoginFormSelected()}
              onSelect={() => setSelectedLoginMethod(LOGIN_METHODS.LOGIN_FORM)}
              {...props}
            />
          );
        }
        return null;
      })}
    </div>
  );
};
Logins.propTypes = {
  availableLoginMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoginFormSelected: PropTypes.func.isRequired,
  isBankIDSelected: PropTypes.func.isRequired,
  setSelectedLoginMethod: PropTypes.func.isRequired,
};

const LoginMethodsContainer = ({ availableLoginMethods, reset, selectedLoginMethod, ...props }) => (
  <>
    <Logins availableLoginMethods={availableLoginMethods} {...props} />
    {selectedLoginMethod && availableLoginMethods.length > 1 && (
      <div className={styles.loginFormContainer} onClick={reset}>
        <Button theme={THEME.PLAIN_PRIMARY} customCssClass={styles.passwordButton}>
          Ändra inloggningssätt
        </Button>
      </div>
    )}
  </>
);
LoginMethodsContainer.propTypes = {
  availableLoginMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  reset: PropTypes.func.isRequired,
  selectedLoginMethod: PropTypes.string,
};

const LoginWelcome = ({ ...props }) => {
  const { availableLoginMethods, checkValidLoginMethods } = useAvailableLoginMethods();
  const {
    selectedLoginMethod,
    setSelectedLoginMethod,
    reset,
    isLoginFormSelected,
    isBankIDSelected,
  } = useSelectedLoginMethod(availableLoginMethods);

  useEffect(() => {
    checkValidLoginMethods();
  }, [checkValidLoginMethods]);

  return (
    <Welcome {...props}>
      <LoginMethodsContainer
        availableLoginMethods={availableLoginMethods}
        reset={reset}
        selectedLoginMethod={selectedLoginMethod}
        setSelectedLoginMethod={setSelectedLoginMethod}
        isLoginFormSelected={isLoginFormSelected}
        isBankIDSelected={isBankIDSelected}
        {...props}
      />
    </Welcome>
  );
};

LoginWelcome.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  customCssClass: PropTypes.string,
};

export default LoginWelcome;
