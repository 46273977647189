import PropTypes from "prop-types";

export function getPlace(meeting, client) {
  if (meeting?.addressType?.value === client) {
    return `${meeting?.addressType?.value} (${meeting?.address?.city})`;
  }
  return meeting?.addressType?.value;
}

getPlace.PropTypes = {
  meeting: PropTypes.object,
  client: PropTypes.string,
};
