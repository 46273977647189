import PropType from "prop-types";
import { useEffect } from "react";
import { useTranslate } from "../../../language/i18n";
import Orders from "../../MyMyloc/Sections/Orders";
import pages from "../../../utils/pages";
import styles from "./Orders.module.scss";

const ClientOrders = ({ setBreadcrumbs, receiver, referrer, fromReceiverMenu }) => {
  const translate = useTranslate();

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([{ text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_ORDERS.NAME)} ` }]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_ORDERS.NAME)} ` },
      ]);
    }
  }, [referrer, setBreadcrumbs, translate, fromReceiverMenu]);

  return (
    <div>
      <h3 className={styles.contentTitle}>{translate("CLIENTS_MENU_ORDERS")}</h3>
      <Orders setBreadcrumbs={setBreadcrumbs} fromClient={receiver?.id} customCssClass={styles.page} />
    </div>
  );
};

ClientOrders.propTypes = {
  setBreadcrumbs: PropType.func,
  receiver: PropType.object,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
};

export default ClientOrders;
