import PropType from "prop-types";
import { useEffect } from "react";
import { useTranslate } from "../../../language/i18n";

import PageSize from "./PageSize";
import Pagination from "./Pagination";

import usePagination from "../../../hooks/usePagination";
import { pageSize } from "./PageSize";
import styles from "./Paginate.module.scss";

const Paginate = ({
  children,
  onChange,
  provider,
  loadOnMount,
  errorMessage = { show: true, message: "" },
  setIsLoading,
  isLoading,
  hidePageSize = false,
  skipSearch = false,
  defaultPageSize = pageSize.SMALL,
}) => {
  const translate = useTranslate();

  const { data, settings, handlePageChange, handlePageSizeChange, page, setPage } = usePagination({
    request: provider,
    loadOnMount,
    loading: setIsLoading,
    skipSearch,
    defaultPageSize,
  });

  const shownError = () => {
    if (!isLoading) {
      if (errorMessage?.show) {
        if (errorMessage.message) {
          return translate(errorMessage.message);
        } else {
          return translate("NO_RESULTS_FOUND");
        }
      }
    }
  };

  useEffect(() => {
    onChange(data);
  }, [data, onChange]);

  return (
    <>
      {children}

      <div className={styles.paginationRow}>
        {data?.length > 0 ? (
          <>
            {!hidePageSize && (
              <PageSize
                onChange={handlePageSizeChange}
                size={parseInt(settings.amount, 10)}
                customCssClass={styles.pageSize}
              />
            )}
            <Pagination
              totalCount={settings.count}
              pageSize={parseInt(settings.amount, 10)}
              currentPage={Math.ceil(settings.from / settings.amount) + 1}
              onPageChange={handlePageChange}
              customCssClass={styles.pagination}
              page={page}
              setPage={setPage}
            />
          </>
        ) : (
          shownError()
        )}
      </div>
    </>
  );
};

Paginate.propTypes = {
  children: PropType.oneOfType([PropType.object, PropType.array]),
  onChange: PropType.func,
  provider: PropType.func,
  loadOnMount: PropType.bool,
  errorMessage: PropType.shape({
    show: PropType.bool,
    message: PropType.string,
  }),
  setIsLoading: PropType.func,
  isLoading: PropType.bool,
  hidePageSize: PropType.bool,
  skipSearch: PropType.bool,
  defaultPageSize: PropType.number,
};

export default Paginate;
