import { useEffect, useState } from "react";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import productService from "../../../services/product/productService";
import clientService from "../../../services/client/clientService";
import { useTablet } from "../../../utils/viewport";
import { inventoryProps } from "./commonProps";
import InventoryListCompact from "./InventoryListCompact";
import InventoryListDesktop from "./InventoryListDesktop";
import InventoryPopup from "./InventoryPopup";
import styles from "./InventoryList.module.scss";
import { useParams } from "react-router";

const InventoryList = ({ productList, onOrder, disabled, facility, receiverId }) => {
  const isCompact = useTablet();
  const translate = useTranslate();
  const [selectedInventory, setSelectedInventory] = useState();
  const [inventory, setInventory] = useState([]);
  const queryParams = useParams();
  const title = facility.value
    ? `${translate("INVENTORIES_IN")} ${facility.value} - ${facility.label}`
    : translate("SELECT_INVENTORY");

  useEffect(() => {
    if (!facility.id) return;

    productService.getInventory(decodeURI(queryParams.product), decodeURI(facility.id)).then(response => {
      const inventoryWithQuantity = response?.data?.fixedAssets?.filter(
        asset => asset.quantity - asset.allocatedQuantity > 0,
      );
      setInventory(inventoryWithQuantity);
    });
  }, [queryParams.product, facility.id]);

  useEffect(() => {
    if (!receiverId) return;

    clientService.getHolding({ clientId: receiverId }).then(response => setInventory(response.data));
  }, [receiverId]);

  const onClose = () => {
    setSelectedInventory(null);
  };

  return (
    <section className={styles.section}>
      <h4>{title}</h4>
      {isCompact ? (
        <InventoryListCompact inventories={inventory} onSelectInventory={setSelectedInventory} disabled={disabled} />
      ) : (
        <InventoryListDesktop
          inventories={inventory || productList}
          onOrder={onOrder}
          onSelectInventory={setSelectedInventory}
          disabled={disabled}
        />
      )}
      <InventoryPopup inventory={selectedInventory} onOrder={onOrder} onClose={onClose} disabled={disabled} />
    </section>
  );
};

InventoryList.propTypes = {
  productId: PropType.string,
  facility: PropType.shape({
    id: PropType.string,
    label: PropType.string,
    value: PropType.string,
  }),
  receiverId: PropType.string,
  inventory: PropType.arrayOf(PropType.shape(inventoryProps)),
  productList: PropType.arrayOf(PropType.shape()),
  onOrder: PropType.func,
  disabled: PropType.bool,
  type: PropType.string,
};

InventoryList.defaultProps = {
  productId: undefined,
  facility: {},
  receiverId: undefined,
  inventory: null,
  onOrder: () => {},
  disabled: false,
  type: undefined,
};

export default InventoryList;
