export default ({ data, files }) => {
  const formData = new FormData();

  if (data) formData.append("data", typeof data === "string" ? data : JSON.stringify(data));

  if (files) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      formData.append("file" + index, file);
    }
  }

  return formData;
};
