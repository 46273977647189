import PropTypes from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import { CLIENT_TYPE } from "../../../../utils/constants";
import { useTablet } from "../../../../utils/viewport";
import RadioButtonGroup from "../../../shared/RadioButton/RadioButtonGroup";
import { DELIVERY_LOCATION } from "./productConstants";
import styles from "./ReceiverLocationSelector.module.scss";

const ReceiverLocationSelector = ({
  receiver,
  selected,
  setReceiverLocation,
  product: { replenishmentGroup, isOrderable },
  isActiveReferencedOrder,
}) => {
  const translate = useTranslate();
  const isCompact = useTablet();
  const disabled = !receiver || receiver?.type === CLIENT_TYPE.BASE_STORAGE;

  let radioValues;
  if (!isOrderable) {
    selected = DELIVERY_LOCATION.BASE_STORAGE.id;
    radioValues = [{ id: DELIVERY_LOCATION.BASE_STORAGE.id, value: translate(DELIVERY_LOCATION.BASE_STORAGE.value) }];
  } else {
    let values = [];
    Object.keys(DELIVERY_LOCATION).map(id => {
      let radio = { id: DELIVERY_LOCATION[id].id, value: translate(DELIVERY_LOCATION[id].value) };
      if (id === "DELIVERY") {
        radio.value = replenishmentGroup?.label
          ? `${translate(DELIVERY_LOCATION[id].value)} (${replenishmentGroup?.label})`
          : translate(DELIVERY_LOCATION[id].value);
      }
      values.push(radio);
    });
    radioValues = values;
  }

  const RadioButtons = () => (
    <RadioButtonGroup
      values={radioValues}
      onChange={setReceiverLocation}
      selected={selected}
      disabled={disabled}
      customCssClass={styles.receiverLocation}
    />
  );

  return (
    <>
      {isCompact ? (
        <div className={styles.compactWrapper}>
          {receiver === CLIENT_TYPE.USER || !isOrderable ? (
            <div>{translate(DELIVERY_LOCATION.BASE_STORAGE.value)}</div>
          ) : (
            <RadioButtons />
          )}
        </div>
      ) : (
        !isActiveReferencedOrder && (
          <div>
            {receiver === CLIENT_TYPE.USER || !isOrderable ? (
              <div>{translate(DELIVERY_LOCATION.BASE_STORAGE.value)}</div>
            ) : (
              <RadioButtons />
            )}
          </div>
        )
      )}
    </>
  );
};

ReceiverLocationSelector.propTypes = {
  receiver: PropTypes.shape({ type: PropTypes.string }),
  selected: PropTypes.string,
  setReceiverLocation: PropTypes.func,
  product: PropTypes.object,
  isActiveReferencedOrder: PropTypes.bool,
};

export default ReceiverLocationSelector;
