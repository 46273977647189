import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import defaultRestOptions from "../utils/defaultRestOptions";

async function receiveDeliveries(data) {
  const url = api.receive.deliveries();
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

export default { receiveDeliveries };
