import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Spinner from "../../../assets/images/spinner.gif";
import styles from "./LoadingSpinner.module.scss";

const LoadingSpinner = ({ title }) => {
  const translate = useTranslate();
  return (
    <figure className={styles.spinner}>
      <img src={Spinner} className={styles.spinnerImage} />
      <figcaption>{translate(title)}</figcaption>
    </figure>
  );
};

LoadingSpinner.propTypes = {
  title: PropType.string,
};
export default LoadingSpinner;
