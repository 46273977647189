import { useCallback, useEffect, useRef } from "react";

const makeCancellable = promise => {
  let isCancelled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(value => (isCancelled ? resolve({ cancelled: true, value }) : resolve({ cancelled: false, value })))
      .catch(error => (isCancelled ? reject({ cancelled: true, error }) : reject({ cancelled: false, error })));
  });

  const cancel = () => {
    isCancelled = true;
  };

  return {
    promise: wrappedPromise,
    cancel,
  };
};

const useCancellablePromise = ({ triggerValue } = {}) => {
  const promises = useRef([]);

  useEffect(() => {
    return () => {
      promises.current.forEach(p => p.cancel());
      promises.current = [];
    };
  }, [triggerValue]);

  const cancellablePromise = useCallback(promise => {
    const cPromise = makeCancellable(promise);
    promises.current.push(cPromise);
    return cPromise.promise;
  }, []);

  return { cancellablePromise };
};

export default useCancellablePromise;
