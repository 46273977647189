import { CompactList } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { inventoryProps } from "./commonProps";
import styles from "./InventoryListCompact.module.scss";

const InventoryListCompact = ({ inventories, onSelectInventory }) => {
  const translate = useTranslate();

  const onClick = (e, inventory) => {
    onSelectInventory?.(inventory);
  };

  const productQuantity = (quantity, allocatedQuantity) => {
    return quantity - allocatedQuantity;
  };

  const InventoryItem = inventory => (
    <div>
      <p className={styles.title}>{inventory.inventoryNumber}</p>
      <div className={styles.content}>
        <p className={styles.name}>{translate("PRODUCT_NUMBER_SHORT")}</p>
        <p>{inventory.productNumber}</p>

        <p className={styles.name}>{translate("PRODUCT_NAME")}</p>
        <p>{inventory.name}</p>

        <p className={styles.name}>{translate("QUANTITY")}</p>
        <p>{productQuantity(inventory.quantity, inventory.allocatedQuantity)}</p>
      </div>
    </div>
  );

  return <CompactList items={inventories ? inventories : []} onClick={onClick} itemTemplate={InventoryItem} />;
};

InventoryListCompact.propTypes = {
  inventories: PropType.arrayOf(PropType.shape(inventoryProps)),
  onSelectInventory: PropType.func,
};

export default InventoryListCompact;
