import { useEffect, useState } from "react";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import clientService from "../../../services/client/clientService";
import AddressModal from "../../shared/AddressAndContact/AddressModal/AddressModal";
import { List, ListItem } from "./Components";
import pages from "../../../utils/pages";
import { useParams } from "react-router";
import DeleteAddressModal from "./Modals/DeleteAddressModal";

function Address({ receiver, setBreadcrumbs, referrer, fromReceiverMenu }) {
  const queryParams = useParams();
  const translate = useTranslate();
  const [addresses, setAddresses] = useState([]);
  const [fetchAddresses, setFetchAddresses] = useState(false);
  const [addressModalProps, setAddressModalProps] = useState({
    visible: false,
    edit: false,
    selectedAddress: null,
  });
  const [addressList, setAddressList] = useState([]);
  const [deleteModalProps, setDeleteModalProps] = useState({ visible: false, selectedAddress: null });

  useEffect(() => {
    clientService.getAddress(queryParams.id).then(response => setAddresses(response.data?.addresses));
    if (fetchAddresses) setFetchAddresses(false);
  }, [queryParams.id, fetchAddresses]);

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([{ text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_ADDRESSES.NAME)} ` }]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_ADDRESSES.NAME)} ` },
      ]);
    }
  }, [referrer, setBreadcrumbs, translate, fromReceiverMenu]);

  useEffect(() => {
    if (addresses) {
      setAddressList(addresses);
    }
  }, [addresses]);

  const handleEditAddress = address => {
    setAddressModalProps({ visible: true, edit: true, selectedAddress: address });
  };

  const handleAddNewAddress = () => {
    setAddressModalProps({ visible: true, edit: false, selectedAddress: null });
  };

  const handleDeleteAddress = address => {
    setDeleteModalProps({ visible: true, selectedAddress: address });
  };

  const handleModalClose = addr => {
    if (addr) {
      const newAddressList = [...addressList];
      const addressIndex = newAddressList.findIndex(element => element.id == addr.id);
      if (addressIndex !== -1) {
        newAddressList[addressIndex] = addr;
      } else {
        newAddressList.push(addr);
      }
      setAddressList(newAddressList);
    }
    setAddressModalProps({ visible: false, selectedAddress: null });
  };

  return (
    <>
      <List
        title={translate("RECEIVER_DETAIL_PAGE_ADDRESS_TITLE")}
        addBtnTitle={translate("NEW_ADDRESS")}
        onAddNew={handleAddNewAddress}
      >
        {addressList &&
          addressList.map(address => (
            <ListItem
              key={address.id}
              title={address.description}
              item={address}
              onEdit={handleEditAddress}
              onDelete={handleDeleteAddress}
            >
              {address.careOf && (
                <span>
                  {translate("CARE_OF")} {address.careOf}
                </span>
              )}
              <span>{address.street}</span>
              <span>
                {address.postalNo} {address.city}
              </span>
            </ListItem>
          ))}
      </List>

      <AddressModal
        userId={receiver?.id}
        visible={addressModalProps.visible}
        address={addressModalProps.edit ? addressModalProps.selectedAddress : null}
        onClose={handleModalClose}
      />

      <DeleteAddressModal
        clientId={receiver?.id}
        addressId={deleteModalProps.selectedAddress?.id}
        visible={deleteModalProps.visible}
        onClose={() => setDeleteModalProps({ ...deleteModalProps, visible: false })}
        setFetchAddresses={setFetchAddresses}
      />
    </>
  );
}

Address.propTypes = {
  receiver: PropType.object,
  addresses: PropType.array,
  setBreadcrumbs: PropType.func,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
};

export default Address;
