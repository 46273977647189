import { IconButton } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { Trash } from "../../../../assets/icons";
import styles from "./CommentRow.module.scss";

const CommentRow = ({ comment, onDeleteClicked }) => {
  return (
    <div key={comment.id} className={styles.commentCard}>
      <div className={styles.header}>
        <span className={styles.author}>{comment.createdBy}</span>
        <div className={styles.right}>
          <span className={styles.timestamp}>{comment.createdDateTime}</span>
          <IconButton onClick={() => onDeleteClicked(comment)} customCssClass={styles.delete}>
            <Trash />
          </IconButton>
        </div>
      </div>

      <div className={styles.message}>{comment.comment}</div>
    </div>
  );
};

CommentRow.propTypes = {
  comment: PropType.object,
  onDeleteClicked: PropType.func,
};

export default CommentRow;
