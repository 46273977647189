import { useState } from "react";
import PropType from "prop-types";
import { Button, Form, TextArea } from "@myloc/myloc-gui";
import { useTranslate } from "../../language/i18n";
import orderService from "../../services/order/orderService";

function Meeting({ data, setData, onNext }) {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async () => {
    setIsLoading(true);

    const response = await orderService.createFittingMeeting({
      fittingId: data.id,
      comment: data?.nextMeeting?.comment,
    });

    setIsLoading(false);

    if (response.isOk()) {
      onNext(response.data);
    }
  };
  return (
    <Form onSubmit={submitHandler}>
      <TextArea
        name="comment"
        label={translate("SPECIFY_MEETING")}
        placeholder={translate("SPECIFY_MEETING_DESCRIPTION")}
        onChange={event =>
          setData({ ...data, nextMeeting: { ...data.nextMeeting, comment: event.currentTarget.value } })
        }
        value={data?.nextMeeting?.comment ?? ""}
      />
      <Button type="submit" isLoading={isLoading}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
}

Meeting.propTypes = {
  contentId: PropType.string,
  onNext: PropType.func,
  data: PropType.object,
  setData: PropType.func,
};

export default Meeting;
