import PropType from "prop-types";
import Counter from "../../shared/Counter/Counter";
import { Button } from "@myloc/myloc-gui";
import styles from "../ReceiveDeliveryOrderPage.module.scss";
import { useTranslate } from "../../../language/i18n";

const ReceiveOrderAmount = ({ data, addData, setCurrentStep, nextStep }) => {
  const translate = useTranslate();

  return (
    <article className={styles.container}>
      <section className={styles.section}>
        <Counter startValue={data?.amount ? data?.amount : 1} min={1} onChange={num => addData("amount", num)} />
      </section>

      <Button type="submit" customCssClass={styles.button} onClick={() => setCurrentStep(nextStep)}>
        {translate("NEXT")}
      </Button>
    </article>
  );
};

ReceiveOrderAmount.propTypes = {
  data: PropType.object,
  addData: PropType.func,
  setCurrentStep: PropType.func,
  nextStep: PropType.number,
};

export default ReceiveOrderAmount;
