import { useParams } from "react-router";
import { Link } from "react-router-dom";

import pages from "../../utils/pages";
import { useTranslate } from "../../language/i18n";

import Page from "../shared/Page/Page";

import Folder from "../../assets/icons/Folder";
import FileIcon from "../shared/File/FileIcon/FileIcon";

import styles from "./DocumentsPage.module.scss";
import { useCallback, useEffect, useState } from "react";
import documentService from "../../services/documentService";
import Paginate from "../shared/Pagination/Paginate";

const DocumentsPage = () => {
  const translate = useTranslate();
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const queryParams = useParams();

  useEffect(() => {
    documentService.getFolders().then(response => setFolders(response.data.page));
  }, []);

  useEffect(() => {
    documentService.getDocuments({ category: queryParams.folder }).then(response => setFiles(response.data.page));
  }, [queryParams.folder]);

  const { folder } = useParams();

  const documentProvider = useCallback(
    filter =>
      documentService.getDocuments({
        category: queryParams.folder,
        ...filter,
      }),
    [queryParams.folder],
  );

  const breadcrumbs = [{ text: translate(pages.DOCUMENTS.NAME), link: pages.DOCUMENTS.PATH }];

  if (folder) {
    breadcrumbs.push({ text: folder });
  }

  return (
    <Page title={translate("DOCUMENTS")} customCssClass={styles.page} breadcrumbs={breadcrumbs}>
      {!queryParams.folder && (
        <section className={styles.directory}>
          {folders?.map(folder => (
            <Link key={folder} to={`${pages.DOCUMENTS.PATH}/${folder}`}>
              <div className={styles.folder}>
                <div className={styles.icon}>
                  <Folder size="36" />
                </div>

                <div className={styles.title}>{folder}</div>
              </div>
            </Link>
          ))}
        </section>
      )}

      {queryParams.folder && (
        <Paginate onChange={setFiles} provider={documentProvider}>
          <section className={styles.files}>
            {files.map(file => (
              <div key={file.id} className={styles.file}>
                <div className={styles.icon}>{<FileIcon fileName={file.name} size={30} />}</div>

                <a href={file.url} target="_blank" rel="noreferrer">
                  <div className={styles.title}>{file.name}</div>
                </a>
              </div>
            ))}
          </section>
        </Paginate>
      )}
    </Page>
  );
};

export default DocumentsPage;
