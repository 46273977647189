import { Button, Form, TextArea } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";

import { useTranslate } from "../../language/i18n";
import orderService from "../../services/order/orderService";

import ClientHoldingList from "../shared/ClientHoldingList/ClientHoldingList";

import { useSelector } from "react-redux";
import { useParams } from "react-router";
import styles from "./Aid.module.scss";

const MAX_SELECTABLE = 1;
function Aid({ order, onNext, data, setData }) {
  const queryParams = useParams();
  const translate = useTranslate();

  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState(data?.product ? [data?.product] : []);
  const activeReferenceOrder = useSelector(state => state.embedded.referencedOrder);
  const currentActiveStep = useSelector(state => state.appData.currentStep);

  const btnDisabled = !order || (!data?.product && !data?.text) || selected.length > MAX_SELECTABLE;

  const submitHandler = async () => {
    setLoading(true);

    const dataToSend = { ...data };
    dataToSend.currentStep = currentActiveStep;

    let response;
    if (activeReferenceOrder) {
      response = queryParams.content
        ? await orderService.updateContent(queryParams.content, dataToSend)
        : await orderService.createContentWithParent(
            {
              ...dataToSend,
              parentWebOrderContentId: activeReferenceOrder ? activeReferenceOrder?.fitting?.id : null,
            },
            order?.id,
          );
    } else {
      response = queryParams.content
        ? await orderService.updateContent(queryParams.content, dataToSend)
        : await orderService.createContent(dataToSend);
    }

    setLoading(false);

    if (response.isOk()) {
      onNext(response.data);
    }
  };

  const onSelect = items => {
    if (!items.length) {
      setSelected([]);
      setData({ product: null });
    }
    if (items.length) {
      setSelected([...items]);
      setData({ product: items[0] });
    }
  };

  return (
    <Form onSubmit={submitHandler}>
      {order?.receiver?.id && (
        <ClientHoldingList
          onSelect={onSelect}
          selected={selected}
          clientId={order?.receiver?.id}
          customCssClass={styles.list}
          multiSelect
          maxSelectable={{ amount: MAX_SELECTABLE, text: translate("MAX_ONE_AID") }}
          onlyInventories
        />
      )}
      <TextArea
        name="text"
        label={translate("DESCRIBE_AID")}
        placeholder={translate("DESCRIBE_AID_DESCRIPTION")}
        customCssClass={styles.textarea}
        onChange={e => setData({ text: e.currentTarget.value })}
        value={data.text ?? ""}
        required={data?.product ? !Object.keys(data.product).length : true}
      />
      <Button type="submit" isLoading={isLoading} customCssClass={styles.submitBtn} disabled={btnDisabled}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
}

Aid.propTypes = {
  data: PropType.object,
  setData: PropType.func,
  order: PropType.object,
  contentId: PropType.string,
  onNext: PropType.func,
};

export default Aid;
