import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMobile } from "../../../utils/viewport";
import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Page from "../Page/Page";
import MessageBox from "../MessageBox/MessageBox";
import { Button, THEME } from "@myloc/myloc-gui";
import RecieverInformation from "../RecieverInformation/RecieverInformation";
import Wizard from "../Wizard/Wizard";
import styles from "./WizardPage.module.scss";
import { openOrderPopup } from "../../../reducers/dialog/dialogAction";
import { setCurrentStep } from "../../../reducers/appData/appDataActions";

function WizardPage({
  title,
  titleDescription,
  steps,
  activeStep,
  setActiveStep,
  showUserInfo,
  requiresOrder,
  children,
}) {
  const translate = useTranslate();
  const receiver = useSelector(state => state.appData.order?.receiver);
  const isMobile = useMobile();

  return (
    <Page
      title={title}
      description={titleDescription}
      customCssClass={showUserInfo || children ? styles.gridLayout : null}
    >
      {!receiver && requiresOrder && (
        <>
          <MessageBox message={translate("NO_RECEIVER_SELECTED")} customCssClass={styles.messageBox}>
            <Button
              theme={THEME.SECONDARY}
              onClick={() => openOrderPopup({ includeNavigation: false })}
              customCssClass={styles.messageButton}
            >
              {translate("NEW_ORDER")}
            </Button>
          </MessageBox>
        </>
      )}

      <Wizard
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        stepsCssClass={styles.stepIndicator}
      />
      {showUserInfo && !isMobile && <RecieverInformation customCssClass={styles.info} />}
      {children}
    </Page>
  );
}

export const useStepper = (pageUrl, searchParams, currentStep, props) => {
  const history = useHistory();
  const queryParams = useParams();
  const contentId = queryParams?.content || searchParams.get("contentid");

  function handleNextStep(newData, currentActiveStep) {
    window.scrollTo({ top: 0 });

    if (!contentId) {
      pageUrl += `/${newData.id}`;
      history.replace(buildHistoryObject(newData, 0));

      const nextStep = getActiveStep(contentId, currentActiveStep, currentStep) + 1;
      history.push(buildHistoryObject(newData, nextStep));
    } else {
      const nextStep = getActiveStep(contentId, currentActiveStep, currentStep) + 1;
      history.push(buildHistoryObject(props, nextStep));
    }
  }

  function setActiveStep(toStep) {
    history.push(buildHistoryObject(props, toStep));
  }

  function buildHistoryObject(currentData, nextStep) {
    const newState = Object.assign(currentData, { currentActiveStep: nextStep });
    return {
      pathname: pageUrl,
      state: newState,
      search: searchParams.toString(),
    };
  }

  return { handleNextStep, setActiveStep };
};

export function getActiveStep(contentId, currentActiveStep, currentStep) {
  if (!contentId) return 0;
  if (currentActiveStep >= 0 && currentStep >= currentActiveStep) return currentActiveStep;
  if (currentStep >= 0) return currentStep;

  return undefined;
}

//only implemented in FittingPage, needs testing for further implementations
export function setActiveStep(contentId, currentActiveStep, currentStep) {
  const step = getActiveStep(contentId, currentActiveStep, currentStep);
  if (step !== undefined) {
    setCurrentStep(step);
  }

  return step;
}

WizardPage.propTypes = {
  title: PropTypes.string,
  titleDescription: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      titleShort: PropTypes.string,
      children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
    }),
  ),
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  showUserInfo: PropTypes.bool,
  requiresOrder: PropTypes.bool,
  children: PropTypes.node,
};

WizardPage.defaultProps = {
  showUserInfo: true,
  requiresOrder: true,
};

export default WizardPage;
