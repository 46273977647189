import store from "../rootReducer";
import { STATUS, REFERENCED_ORDER, CLEAR_REFERENCED_ORDER_STATE, FITTING } from "./embeddedVariables";

export function setEmbeddedStatus(status) {
  store.dispatch({ type: STATUS, status: status });
}

export function setReferencedOrder(referencedOrder) {
  store.dispatch({ type: REFERENCED_ORDER, referencedOrder });
}
export function setEmbeddedFitting(fitting) {
  store.dispatch({ type: FITTING, fitting });
}

export function clearReferencedOrder() {
  store.dispatch({ type: CLEAR_REFERENCED_ORDER_STATE });
}
