import { ID_SPLITTER } from "./constants";

export function totalQuantity(allocations) {
  if (!allocations) return 0;
  return allocations.reduce((sum, allocation) => sum + allocation.quantity, 0);
}

export function totalReturnedQuantity(allocations) {
  if (!allocations) return 0;
  return allocations.reduce((sum, allocation) => sum + allocation.returnedQuantity, 0);
}

export function totalReportedQuantity(allocations) {
  if (!allocations) return 0;
  return allocations.reduce((sum, allocation) => sum + allocation.reportedQuantity, 0);
}

export function isSameId(allocation, lineId) {
  if (!allocation.taskOrderLine) return false;

  const taskOrderLineId = decodeURIComponent(allocation.taskOrderLine.id);
  if (taskOrderLineId === lineId) return true;
  if (!taskOrderLineId.endsWith(`${ID_SPLITTER}0`)) return false;
  return taskOrderLineId.substring(0, taskOrderLineId.length - 2) === lineId;
}
