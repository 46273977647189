import { Button, Form, TextArea } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import ClientHoldingList from "../../shared/ClientHoldingList/ClientHoldingList";
import styles from "./Aid.module.scss";

function Aid({ onNext, data, setData }) {
  const translate = useTranslate();
  const queryParams = useParams();
  const order = useSelector(state => state.appData?.order);
  const [isLoading, setLoading] = useState(false);
  const [pickupList, setPickupList] = useState([]);

  useEffect(() => {
    if (data.pickupList) {
      setPickupList(data.pickupList);
    }
    if (!data.pickupList) {
      setPickupList([]);
    }
  }, [data.pickupList]);

  const getProvider = useCallback(
    filters => {
      if (queryParams.content) {
        return orderService.getClientHoldingsByContent({
          orderId: queryParams.order,
          contentId: queryParams.content,
          filters,
        });
      } else if (order?.id || queryParams.order !== "none") {
        return orderService.getClientHoldings({ orderId: order?.id || queryParams.order, filters });
      }
    },
    [queryParams, order],
  );

  const handleSubmit = async () => {
    setLoading(true);

    const { text, contentType } = data;

    const response = queryParams.content
      ? await orderService.updateContent(queryParams.content, { text, contentType, pickupList })
      : await orderService.createContent({ text, contentType, pickupList });

    setLoading(false);

    if (response.isOk()) {
      onNext(response.data);
    }
  };

  const handleQuantityChange = (row, num) => {
    if (!pickupList) return;

    if (pickupList.some(item => item.id === row.id)) {
      const updatedPickupList = pickupList.filter(item => item.id !== row.id);
      updatedPickupList.push({ ...row, quantity: num });
      setPickupList([...updatedPickupList]);
    }
  };

  const isDisabled = !order?.receiver || ((pickupList?.length === 0 || !pickupList?.length) && !data.text);

  return (
    <Form onSubmit={handleSubmit}>
      <ClientHoldingList
        multiSelect
        onSelect={setPickupList}
        selected={pickupList}
        amountChangeable
        onAmountChange={handleQuantityChange}
        provider={getProvider}
        skipSearch={!queryParams.content && !order?.id && queryParams.order === "none"}
        disableAllocated
      />
      <TextArea
        name="text"
        label={translate("DESCRIBE_AID")}
        placeholder={translate("DESCRIBE_AID_DESCRIPTION")}
        customCssClass={styles.textarea}
        onChange={e => setData({ text: e.currentTarget.value })}
        value={data.text ?? ""}
        required={data?.pickupList?.length === 0}
      />
      <Button type="submit" isLoading={isLoading} customCssClass={styles.submitBtn} disabled={isDisabled}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
}

Aid.propTypes = {
  data: PropType.object,
  setData: PropType.func,
  order: PropType.object,
  onNext: PropType.func,
};

export default Aid;
