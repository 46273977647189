import { Checkbox } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import ProductImage, { ImagePreset } from "../../../Products/ProductImage/ProductImage";
import Counter from "../../Counter/Counter";
import Label from "../../Label/Label";
import styles from "./InventoryAccessoryRow.module.scss";

const InventoryAccessoryRow = ({ accessory, isSelected, onToggle, handleQuantityChange }) => {
  const translate = useTranslate();

  const truncateDescription = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }
    return text;
  };

  return (
    <div className={styles.accessories}>
      <div className={styles.accessory}>
        <Checkbox onChange={() => onToggle(accessory)} checked={isSelected} customCssClass={styles.checkbox} />
        <div className={styles.image}>
          <ProductImage images={accessory.images} preset={ImagePreset.ICON} className={styles.productImage} />
        </div>
        <div className={styles.details}>
          <div className={styles.head}>
            <div className={styles.title}>{truncateDescription(accessory.description, 40)}</div>
            <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={accessory?.productNumber} />
            <Label inline label={translate("ARTICLE_CLASS")} value={accessory.itemClass?.label} hideEmpty />
            <Label inline label={translate("INVENTORY_NUMBER_SHORT")} value={accessory?.inventoryNumber} hideEmpty />
          </div>
          <div className={classNames(isSelected && styles.selected)}>
            {isSelected ? (
              <Counter
                min={1}
                max={accessory.quantity}
                onChange={num => handleQuantityChange(accessory, num)}
                customCssClass={styles.counter}
              />
            ) : (
              `${accessory.quantity} ${accessory.unit}`
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

InventoryAccessoryRow.propTypes = {
  accessory: PropType.object.isRequired,
  isSelected: PropType.bool,
  onToggle: PropType.func.isRequired,
  handleQuantityChange: PropType.func.isRequired,
};
export default InventoryAccessoryRow;
