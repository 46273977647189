import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsSingleSignOn } from "../reducers/session/sessionAction";
import storage from "../utils/store/storage";
import { SINGLE_SIGN_ON_RESPONSE } from "../utils/store/storeVariables";
import useFetchSingleSignOnUserData from "./useFetchSingleSignOnUserData";

let fetching = false;

const useIsSingleSignOn = () => {
  const dispatch = useDispatch();
  const isSingleSignOn = useSelector(state => state.session?.isSingleSignOn);
  const { loadUserData } = useFetchSingleSignOnUserData();

  const getSSOUserData = useCallback(async () => {
    if (!fetching) {
      fetching = true;

      const response = await loadUserData();
      dispatch(setIsSingleSignOn(!response.isError));
      storage.saveItem(SINGLE_SIGN_ON_RESPONSE, false, response);
      fetching = false;
    }
  }, [dispatch, loadUserData]);

  useEffect(() => {
    if (isSingleSignOn === undefined) {
      getSSOUserData();
    }
  }, [getSSOUserData, isSingleSignOn]);

  return isSingleSignOn;
};

export default useIsSingleSignOn;
