import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getBalance(filters = {}) {
  const url = api.baseStorage.baseStorageBalance();
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function scrapBalance(data) {
  const url = api.baseStorage.scrap();
  const request = new Request(url, Method.POST);
  return await sendRequest(
    request,
    {
      ...data,
    },
    await defaultRestOptions(),
  );
}

async function moveBalance(balanceId, data) {
  const url = api.baseStorage.move(balanceId);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getWarehouses(baseStorageId) {
  const url = api.baseStorage.warehousesForBaseStorage(baseStorageId);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getWarehouseLocations(baseStorageId, warehouseId) {
  const url = api.baseStorage.locationsForBaseStorageWarehouse(baseStorageId, warehouseId);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default { getBalance, scrapBalance, moveBalance, getWarehouses, getWarehouseLocations };
