import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../config/settings";
import setQueryFilters from "../utils/setQueryFilters";
import defaultRestOptions from "./utils/defaultRestOptions";

async function getFolders() {
  const url = api.documents.folders();
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getDocuments({ category, filters = {} }) {
  const url = api.documents.documents();
  if (filters) setQueryFilters(filters, url);
  url.addParameter("category", category);
  url.addParameter("all", true);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default { getDocuments, getFolders };
