import PropTypes from "prop-types";
import classNames from "classnames";
import { Collapse } from "@myloc/myloc-gui";
import { ChevronUp, ChevronDown } from "../../../assets/icons";
import styles from "./AccordionItem.module.scss";
import { useEffect, useState } from "react";

const AccordionItem = ({ title, children, onToggle, customCssClass, ...props }) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const onClick = () => {
    setIsOpen(!isOpen);
    onToggle?.();
  };

  return (
    <section className={classNames(styles.accordion, customCssClass)}>
      <button onClick={onClick} className={classNames(styles.header)}>
        <span className={styles.title}>{title}</span>
        {isOpen ? (
          <ChevronUp size="32" customCssClass={styles.expandIcon} />
        ) : (
          <ChevronDown size="32" customCssClass={styles.expandIcon} />
        )}
      </button>
      <Collapse visible={isOpen}>
        <section className={styles.content}>{children}</section>
      </Collapse>
    </section>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customCssClass: PropTypes.string,
};

export default AccordionItem;
