import PropTypes from "prop-types";
import styles from "./ProductMake.module.scss";

const ProductMake = ({ makes }) => {
  if (!makes || makes.length === 0) return <></>;

  return (
    <ul className={styles.list}>
      {makes.map(make => (
        <li key={make.id}>
          <span className={styles.title}>{make.label}: </span>
          <span>{make.value}</span>
        </li>
      ))}
    </ul>
  );
};

ProductMake.propTypes = {
  makes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

export default ProductMake;
