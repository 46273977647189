import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Label.module.scss";

// todo better key

const Label = ({ label, value, inline, hideEmpty = false, customCssClass, customLabelCss }) => {
  if (hideEmpty && !value) return <></>;

  return (
    <div className={classNames(styles.text, customCssClass)}>
      {label && <span className={classNames(styles.label, inline && styles.inline)}>{label}:</span>}
      {value && Array.isArray(value) ? (
        value.map((value, i) => (
          <span className={inline && styles.inline} key={i}>
            {value}
          </span>
        ))
      ) : (
        <span className={classNames(inline && styles.inline, customLabelCss)}>{value}</span>
      )}
    </div>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object), PropTypes.number]),
  inline: PropTypes.bool,
  hideEmpty: PropTypes.bool,
  customCssClass: PropTypes.string,
  customLabelCss: PropTypes.string,
};

export default Label;
