import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import clientService from "../../../../services/client/clientService";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";
import SelectField from "../../../shared/SelectField/SelectField";
import styles from "./SetPrimaryContactModal.module.scss";

const SetPrimaryContactModal = ({ userId, title, visible, cancel, primaryContact, contacts, setRefetchContacts }) => {
  const translate = useTranslate();
  const [selected, setSelected] = useState(null);

  const onSubmit = async () => {
    if (!selected) return;

    const response = await clientService.updateContact(userId, selected.id, {
      relation: selected.relation,
      firstName: selected.firstName,
      surname: selected.surname,
      phoneNo: selected.phoneNo,
      email: selected.email,
      isPrimaryContact: true,
    });

    if (response.isOk()) {
      if (setRefetchContacts) setRefetchContacts(true);
    }
  };

  const contactTemplate = useCallback(
    item => (
      <>
        <p>{item.relation}</p>
        <p>
          {item.firstName} {item.surname}
        </p>
        <p>{item.phoneNo}</p>
        <p>{item.email}</p>
      </>
    ),
    [],
  );

  useEffect(() => {
    onSelect(primaryContact);
  }, [primaryContact]);

  const onSelect = selected => {
    if (selected) setSelected(selected);
  };

  const onCancel = () => {
    setSelected(primaryContact ?? null);
    cancel();
  };

  return (
    <ConfirmModal
      title={title}
      visible={visible}
      cancel={onCancel}
      small={false}
      confirmButtonText={translate("SAVE")}
      declineButtonText={translate("CANCEL")}
      submit={onSubmit}
    >
      <div>
        <SelectField
          options={contacts}
          selectedId={selected?.id}
          onSelect={onSelect}
          customListCss={styles.list}
          customSettings={{ template: contactTemplate, display: "relation" }}
        ></SelectField>
      </div>
    </ConfirmModal>
  );
};

SetPrimaryContactModal.propTypes = {
  userId: PropType.string,
  title: PropType.string,
  visible: PropType.bool.isRequired,
  cancel: PropType.func.isRequired,
  primaryContact: PropType.object,
  contacts: PropType.array,
  setRefetchContacts: PropType.func,
};
export default SetPrimaryContactModal;
