import { Button, Collapse, List } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";
import { ChevronDown, ChevronUp } from "../../../assets/icons";
import { useTranslate } from "../../../language/i18n";
import { inventoryProps } from "./commonProps";
import styles from "./InventoryListDesktop.module.scss";

const InventoryListDesktop = ({ inventories, onOrder, onSelectInventory, disabled }) => {
  const translate = useTranslate();
  const { toColumn } = useToColumns(onOrder, disabled);

  function headerContent(label) {
    return {
      content: <p className={styles.header}>{label}</p>,
    };
  }

  const onClick = (e, inventory) => {
    e.preventDefault();
    onSelectInventory?.(inventory);
  };

  const columnSizes = [1, 1, 2, 1, 1];
  const headers = [
    headerContent(translate("INVENTORY_NUMBER_SHORT")),
    headerContent(translate("PRODUCT_NUMBER_SHORT")),
    headerContent(translate("PRODUCT_NAME")),
    headerContent(translate("QUANTITY")),
    headerContent(),
  ];

  const columns = !inventories ? [] : inventories.map(current => toColumn(current, onClick)).flat();

  return <List columnSizes={columnSizes} headers={headers} columns={columns} />;
};

function useToColumns(onOrder, disabled) {
  const [openIds, setOpenIds] = useState([]);
  const [inventoryIsLoading, setLoadingInventory] = useState(null);
  const translate = useTranslate();

  const onClickInventory = inventory => {
    const ids = [...openIds];
    if (!openIds.includes(inventory.id)) {
      ids.push(inventory.id);
      setOpenIds(ids);
    } else {
      ids.splice(ids.indexOf(inventory.id), 1);
      setOpenIds(ids);
    }
  };

  const isVisible = inventory => {
    return openIds.includes(inventory.id);
  };

  const onOrderInventory = async inventory => {
    setLoadingInventory(inventory.id);
    await onOrder?.(inventory.quantity, inventory);
    setLoadingInventory(null);
  };

  const toColumn = (inventory, onClick) => {
    const onClickRow = onClick
      ? e => {
          onClick(e, inventory);
        }
      : null;

    const productQuantity = (quantity, allocatedQuantity) => {
      return quantity - allocatedQuantity;
    };

    return [
      {
        content: (
          <div className={styles.inventoryNumber} onClick={() => onClickInventory(inventory)}>
            <p>{inventory.inventoryNumber}</p>
            {isVisible(inventory) ? (
              <ChevronUp customCssClass={styles.icon} />
            ) : (
              <ChevronDown customCssClass={styles.icon} />
            )}
          </div>
        ),
      },
      {
        content: (
          <div>
            <p>{inventory.productNumber}</p>
            <Collapse visible={openIds.includes(inventory.id)}>
              {inventory.accessories?.map(accessory => (
                <p key={accessory.id} className={styles.listAccessory}>
                  {accessory.productNumber}
                </p>
              ))}
            </Collapse>
          </div>
        ),
        onClickRow: onClickRow,
      },
      {
        content: (
          <div>
            <p>{inventory.name}</p>
            <Collapse visible={openIds.includes(inventory.id)}>
              {inventory.accessories?.map(accessory => (
                <p key={accessory.id} className={styles.listAccessory}>
                  {accessory.name}
                </p>
              ))}
            </Collapse>
          </div>
        ),
        onClickRow: onClickRow,
      },
      {
        content: (
          <div>
            <p>{productQuantity(inventory.quantity, inventory.allocatedQuantity)}</p>
            <Collapse visible={openIds.includes(inventory.id)}>
              {inventory.accessories?.map(accessory => (
                <p key={accessory.id} className={styles.listAccessory}>
                  {productQuantity(accessory.quantity, accessory.allocatedQuantity)}
                </p>
              ))}
            </Collapse>
          </div>
        ),
        onClickRow: onClickRow,
      },
      {
        content: (
          <Button
            onClick={() => onOrderInventory(inventory)}
            isLoading={inventoryIsLoading === inventory.id}
            disabled={disabled || (!!inventoryIsLoading && inventoryIsLoading !== inventory.id)}
          >
            {translate("ADD_TO_CART")}
          </Button>
        ),
      },
    ];
  };

  return { toColumn };
}

InventoryListDesktop.propTypes = {
  inventories: PropType.arrayOf(PropType.shape(inventoryProps)),
  onOrder: PropType.func,
  onSelectInventory: PropType.func,
  disabled: PropType.bool,
};

export default InventoryListDesktop;
