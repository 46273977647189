import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import MyFittingPage from "../../components/_embedded/MyFittingPage";
import pages from "../../utils/pages";
import MessageList from "../Message/MessageList/MessageList";
import ProductOrderPage from "../ProductOrder/ProductOrderPage";
import ProductDetailPage from "../Products/ProductDetail/ProductDetail";
import ProductsPage from "../Products/ProductsPage";
import WorkOrderPage from "../WorkOrder/WorkOrderPage";
import FittingsPage from "../_embedded/FittingsPage";
import BankIdAuthentication from "../shared/BankId/BankIdAuthentication";

const EmbeddedRouter = () => {
  const sessionId = useSelector(state => state.session?.sessionId);
  if (!sessionId) {
    return (
      <Switch>
        <Route path={pages.BANKID_LOGIN.PATH}>
          <BankIdAuthentication />
        </Route>
        <Route>
          <></>
        </Route>
      </Switch>
    );
  }
  return (
    <Switch>
      <Route path={pages.EMBEDDED_MY_MESSAGES.PATH}>
        <MessageList />
      </Route>
      <Route exact path={pages.EMBEDDED_MY_FITTINGS.PATH}>
        <FittingsPage />
      </Route>
      <Route path={pages.EMBEDDED_MY_FITTING.PATH}>
        <MyFittingPage />
      </Route>
      <Route exact path={pages.EMBEDDED_PRODUCTS.PATH}>
        <ProductsPage />
      </Route>
      <Route path={`${pages.EMBEDDED_PRODUCTS.PATH}/:product`}>
        <ProductDetailPage />
      </Route>
      <Route exact path={pages.EMBEDDED_PRODUCT_ORDER.PATH}>
        <ProductOrderPage />
      </Route>
      <Route exact path={pages.EMBEDDED_WORK_ORDER.PATH}>
        <WorkOrderPage />
      </Route>
    </Switch>
  );
};

export default EmbeddedRouter;
