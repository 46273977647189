import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import pages from "../../utils/pages";
import BaseStoragesPage from "../BaseStorages/BaseStoragesPage";
import Inventory from "../BaseStorages/Inventory/Inventory";
import InventoryDetailPage from "../BaseStorages/InventoryDetail/InventoryDetailPage";
import TaskTypeBSM from "../BaseStorages/WorkList/TaskDetails/TaskTypeBSM";
import WorkList from "../BaseStorages/WorkList/WorkList";
import CartPage from "../Cart/CartPage";
import OrderConfirmationPage from "../Cart/OrderConfirmation/OrderConfirmationPage";
import ClientSearchPage from "../Clients/ClientSearchPage";
import ClientsPage from "../Clients/ClientsPage";
import DeviationPage from "../Deviation/DeviationPage";
import DocumentsPage from "../Documents/DocumentsPage";
import FittingDetailPage from "../FittingDetail/FittingDetailPage";
import FittingPage from "../FittingOrder/FittingPage";
import FunctionOrderPage from "../FunctionOrder/FunctionOrderPage";
import InventoryPage from "../Inventory/InventoryPage";
import MessageList from "../Message/MessageList/MessageList";
import DeviationDetailsPage from "../MyMyloc/DeviationDetailsPage/DeviationDetailsPage";
import MyMylocPage from "../MyMyloc/MyMylocPage";
import NotFoundPage from "../NotFound/NotFoundPage";
import OrderDetailPage from "../OrderDetail/OrderDetailPage";
import OrdersPage from "../OrdersPage/OrdersPage";
import PickupOrderPage from "../PickupOrder/PickupOrderPage";
import ProductOrderPage from "../ProductOrder/ProductOrderPage";
import ExternalAssortmentPage from "../Products/ExternalAssortmentPage/ExternalAssortmentPage";
import ProductDetailPage from "../Products/ProductDetail/ProductDetail";
import ProductsPage from "../Products/ProductsPage";
import ReceiveDeliveryOrderPage from "../Receive/ReceiveDeliveryOrderPage";
import ReceiveDeliveryPage from "../Receive/ReceiveDeliveryPage";
import ResetPasswordPage from "../Start/ResetPassword/ResetPasswordPage";
import StartPage from "../Start/StartPage";
import UserSettingsPage from "../UserSettings/UserSettingsPage";
import WorkOrderPage from "../WorkOrder/WorkOrderPage";
import BankIdAuthentication from "../shared/BankId/BankIdAuthentication";

const PageRouter = () => {
  const sessionId = useSelector(state => state.session?.sessionId);
  const sessionInitialized = useSelector(state => state.session.initialized);

  if (!sessionInitialized) {
    return <></>;
  }

  return (
    <>
      {!sessionId ? (
        <Switch>
          <Route exact path={pages.HOME}>
            <StartPage desiredPage={window.location.pathname + window.location.search} />
          </Route>
          <Route exact path={pages.RESET_PASSWORD.PATH}>
            <ResetPasswordPage />
          </Route>
          <Route path={pages.REGISTER}>
            <StartPage />
          </Route>
          <Route path={pages.BANKID_LOGIN.PATH}>
            <BankIdAuthentication />
          </Route>
          <Redirect to={pages.HOME} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={pages.HOME}>
            <StartPage />
          </Route>
          <Route exact path={`${pages.FUNCTION_ORDER.PATH}`}>
            <FunctionOrderPage />
          </Route>
          <Route exact path={pages.PRODUCTS.PATH}>
            <ProductsPage />
          </Route>
          <Route exact path={pages.PRODUCT_DETAIL.PATH}>
            <ProductDetailPage />
          </Route>
          <Route exact path={pages.PRODUCTS_EXTERNAL_ASSORTMENT.PATH}>
            <ExternalAssortmentPage />
          </Route>
          <Route exact path={pages.PRODUCT_ORDER.PATH}>
            <ProductOrderPage />
          </Route>
          <Route exact path={pages.WORK_ORDER.PATH}>
            <WorkOrderPage />
          </Route>
          <Route exact path={pages.RECEIVE_DELIVERIES.PATH}>
            <ReceiveDeliveryPage />
          </Route>
          <Route exact path={pages.RECEIVE_DELIVERY_ORDER.PATH}>
            <ReceiveDeliveryOrderPage />
          </Route>
          <Route exact path={pages.DEVIATION.PATH}>
            <DeviationPage />
          </Route>
          <Route exact path={pages.PICKUP_ORDER.PATH}>
            <PickupOrderPage />
          </Route>

          <Route exact path={pages.FITTING.PATH}>
            <FittingPage />
          </Route>

          <Route path={`${pages.MY_FITTINGS.PATH}/:fitting`}>
            <FittingDetailPage />
          </Route>

          <Route exact path={pages.FITTING_DETAIL.PATH}>
            <FittingDetailPage />
          </Route>
          <Route path={pages.USER_SETTINGS.PATH}>
            <UserSettingsPage />
          </Route>
          <Route path={pages.CART.PATH}>
            <CartPage />
          </Route>
          <Route path={pages.ORDER_CONFIRMATION.PATH}>
            <OrderConfirmationPage />
          </Route>
          <Route exact path={pages.ORDERS.PATH}>
            <OrdersPage />
          </Route>
          <Route exact path={pages.ORDER_DETAIL.PATH}>
            <OrderDetailPage />
          </Route>

          <Route path={pages.MY_DEVIATION_DETAILS.PATH}>
            <DeviationDetailsPage />
          </Route>
          <Route path={pages.MY_DEVIATIONS_INFO.PATH}>
            <DeviationDetailsPage />
          </Route>
          <Route path={pages.MY_DEVIATIONS_ATTACHMENTS.PATH}>
            <DeviationDetailsPage />
          </Route>
          <Route path={pages.MESSAGES.PATH}>
            <MessageList />
          </Route>
          <Route path={`${pages.CLIENT_TASKS.PATH}/:taskId`}>
            <ClientsPage />
          </Route>
          <Route path={`${pages.MY_TASKS.PATH}/:id`}>
            <MyMylocPage />
          </Route>

          <Route path={pages.MY_MYLOC.PATH}>
            <MyMylocPage />
          </Route>
          <Route path={`${pages.CLIENTS.PATH}/:id`}>
            <ClientsPage />
          </Route>
          <Route exact path={pages.CLIENTS.PATH}>
            <ClientSearchPage />
          </Route>

          <Route exact path={`${pages.DOCUMENTS.PATH}/:folder?`}>
            <DocumentsPage />
          </Route>

          <Route exact path={pages.FITTING_DETAIL.PATH}>
            <FittingDetailPage />
          </Route>

          <Route exact path={pages.BASE_STORAGES.PATH}>
            <BaseStoragesPage />
          </Route>

          <Route exact path={pages.BASE_STORAGE_INVENTORY_DETAIL.PATH}>
            <InventoryDetailPage />
          </Route>

          <Route exact path={pages.BASE_STORAGE_INVENTORY.PATH}>
            <Inventory />
          </Route>

          <Route exact path={pages.BASE_STORAGE_WORK_LIST.PATH}>
            <WorkList />
          </Route>

          <Route exact path={`${pages.BASE_STORAGE_WORK_LIST.PATH}/:taskId`}>
            <TaskTypeBSM />
          </Route>
          <Route exact path={pages.INVENTORIES.PATH}>
            <InventoryPage />
          </Route>

          <Route exact path={pages.FIXED_ASSET.PATH}>
            <InventoryDetailPage />
          </Route>
          <Route exact path={pages.FIXED_ASSET_INVENTORY.PATH}>
            <InventoryDetailPage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      )}
    </>
  );
};

export default PageRouter;
