import PropType from "prop-types";
import { useEffect, useState } from "react";
import Problem from "../../../assets/icons/Problem";
import { useTranslate } from "../../../language/i18n";
import baseStorageService from "../../../services/baseStorage/baseStorageService";
import { CONTENT_TYPE } from "../../../utils/constants";
import Counter from "../../shared/Counter/Counter";
import Label from "../../shared/Label/Label";
import ConfirmModal from "../../shared/Modals/ConfirmModal";
import SelectField from "../../shared/SelectField/SelectField";
import styles from "./OrderBaseStorageMoveModal.module.scss";

const OrderBaseStorageMoveModal = ({
  visible,
  onClose,
  item,
  onSubmit,
  fromBaseStorage,
  toBaseStorage,
  isDirectMove,
}) => {
  const translate = useTranslate();
  const [chosenBalance, setChosenBalance] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const submit = async () => {
    let data;

    if (isDirectMove) {
      data = {
        quantity: chosenBalance,
        toWarehouseLocation: {
          id: selectedLocationId,
        },
      };
    } else {
      data = {
        contentType: CONTENT_TYPE.BASE_STORAGE_MOVE,
        fromFacility: {
          id: fromBaseStorage?.id,
        },
        product: {
          id: item.isFixedAsset ? item.inventoryId : item.id,
          quantity: chosenBalance,
        },
        toWarehouseLocation: {
          id: selectedLocationId,
        },
      };
    }

    await onSubmit(data, item.id);
  };

  const onCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (visible && toBaseStorage?.id) {
      baseStorageService.getWarehouses(toBaseStorage.id).then(response => setWarehouses(response.data));
    }
  }, [visible, toBaseStorage?.id]);

  useEffect(() => {
    if (selectedWarehouseId && toBaseStorage?.id) {
      baseStorageService
        .getWarehouseLocations(toBaseStorage.id, selectedWarehouseId)
        .then(response => setLocations(response.data));
    }
  }, [toBaseStorage?.id, selectedWarehouseId]);

  return (
    <ConfirmModal
      small={false}
      visible={visible}
      cancel={onCancel}
      title={isDirectMove ? translate("MOVE") : translate("ORDER_MOVE")}
      submit={submit}
      confirmButtonText={isDirectMove ? translate("MOVE") : translate("DO_ORDER")}
      declineButtonText={translate("CANCEL")}
      confirmButtonDisabled={!selectedLocationId}
    >
      <div>
        <section className={styles.itemSection}>
          <div>
            <p>
              {item?.product?.name}
              {item?.product?.hasDeviation && <Problem customCssClass={styles.deviation} />}
            </p>
            <Label label={translate("PRODUCT_NUMBER_SHORT")} value={item?.product?.productNumber} inline />
            <Label
              label={translate("INVENTORY_NUMBER_SHORT")}
              value={item?.product?.inventoryNumber}
              inline
              hideEmpty
            />
          </div>
          <div className={styles.amountToMove}>
            <span>
              {item.isFixedAsset ? (
                <span className={styles.availableBalance}>{`${
                  item.product?.quantity - item.product?.allocatedQuantity
                } ${item.product.unit}`}</span>
              ) : (
                <span className={styles.counter}>
                  <Counter
                    onChange={setChosenBalance}
                    min={item.product?.quantity - item.product?.allocatedQuantity > 0 ? 1 : 0}
                    max={item.product?.quantity - item.product?.allocatedQuantity}
                    startValue={item.product?.quantity - item.product?.allocatedQuantity > 0 ? 1 : 0}
                  />
                </span>
              )}
            </span>
          </div>
        </section>
        <section>
          <p>{translate("MOVE_DESCRIPTION")}</p>
          <div className={styles.warehouseLocations}>
            <SelectField
              options={warehouses}
              onSelect={warehouse => setSelectedWarehouseId(warehouse.id)}
              label={translate("BASE_STORAGE")}
              noResultLabel={translate("BASE_STORAGE_MISSING")}
              selectedId={selectedWarehouseId}
              customListCss={styles.list}
            />
            <SelectField
              options={locations}
              onSelect={location => setSelectedLocationId(location.id)}
              label={translate("LOCATION")}
              noResultLabel={translate("LOCATION_MISSING")}
              selectedId={selectedLocationId}
              customListCss={styles.list}
            />
          </div>
        </section>
      </div>
    </ConfirmModal>
  );
};

OrderBaseStorageMoveModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  item: PropType.object,
  onSubmit: PropType.func,
  fromBaseStorage: PropType.object,
  toBaseStorage: PropType.object,
  isDirectMove: PropType.bool,
};

export default OrderBaseStorageMoveModal;
