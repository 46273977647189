import { Button } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../../language/i18n";
import FacilitySelector from "../../FacilitySelector/FacilitySelector";

function Facility({ onComplete, onSelectOnMount }) {
  const translate = useTranslate();
  const userFacility = useSelector(state => state.appData?.user?.facility);
  const [facility, setFacility] = useState(userFacility?.id ? userFacility : null);

  return (
    <>
      <FacilitySelector onSelect={setFacility} preSelected={facility} onSelectOnMount={onSelectOnMount} />
      <Button disabled={!facility} onClick={() => onComplete(facility?.id)}>
        {translate("NEXT")}
      </Button>
    </>
  );
}

Facility.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onSelectOnMount: PropTypes.bool,
};

export default Facility;
