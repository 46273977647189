import PropType from "prop-types";
import classNames from "classnames";
import Label from "../../../shared/Label/Label";
import { useTranslate } from "../../../../language/i18n";
import { Button, THEME, InputField } from "@myloc/myloc-gui";
import SelectField from "../../../shared/SelectField/SelectField";
import { ADDRESS_TYPE } from "../../../../utils/constants";
import { setError } from "../../../../reducers/dialog/dialogAction";

import styles from "../../FittingDetailPage.module.scss";
import orderService from "../../../../services/order/orderService";

import UserOrFacilityAddress from "./UserOrFacilityAddress/UserOrFacilityAddress";
import { useState } from "react";

const CreateOrEditNextMeeting = ({
  fitting,
  setMeeting,
  meeting,
  edit,
  setEdit,
  create,
  setCreate,
  options,
  setFetchFittings,
}) => {
  const translate = useTranslate();
  const [showRoom, setShowRoom] = useState(meeting?.addressType == ADDRESS_TYPE.COMPANY);

  const onInputChange = (key, value) => {
    if (meeting && meeting[key] !== value) {
      setMeeting({ ...meeting, [key]: value });
    }
  };
  const isMeetingPlanned = () => {
    return fitting?.nextMeeting?.status === "PLANNED";
  };

  const getMinIfTodaysDate = () => {
    const todaysDate = new Date();
    if (meeting?.date == todaysDate.toISOString().substr(0, 10)) {
      return `${todaysDate.getHours()}:${todaysDate.getMinutes()}`;
    } else {
      return null;
    }
  };

  const onUpdateMeeting = async () => {
    if (meeting) {
      if (edit) {
        if (isMeetingPlanned()) {
          if (!meeting.toTime || !meeting.fromTime || !meeting.date) {
            setError({ error: "exception", errorMessage: translate("FILL_RED_FIELDS") });
            return;
          }
        }
        const response = await orderService.updateFittingMeeting(fitting.id, fitting.nextMeeting?.id, meeting);
        if (response.statusCode === 200) {
          setEdit(false);
          setFetchFittings(true);
        }
      } else if (create) {
        if (meeting.toTime && meeting.fromTime && meeting.date) {
          const response = await orderService.createMeetingForFitting(fitting.id, meeting);
          //Response.isOk returns true for status 400, which is an error and it should not leave the page
          if (response.statusCode === 201) {
            setCreate(false);
            setFetchFittings(true);
          }
        } else {
          setError({ error: "exception", errorMessage: translate("FILL_RED_FIELDS") });
        }
      }
    }
  };

  const cancel = () => {
    if (edit) {
      setEdit(false);
    } else if (create) {
      setCreate(false);
    }
  };

  return (
    <>
      <div>
        <div>{translate("TIME")}</div>
        <div className={styles.dateTimeWrapper}>
          <InputField
            type="date"
            value={meeting?.date}
            customCssClass={styles.inputInline}
            onChange={event => onInputChange("date", event.target.value)}
            onClear={() => onInputChange("date", "")}
            required={isMeetingPlanned() || create}
            showErrorWhenEmpty={isMeetingPlanned() || create}
            min={new Date().toISOString().substr(0, 10)}
          />
          <InputField
            type="time"
            value={meeting?.fromTime}
            customCssClass={styles.inputInline}
            onChange={event => onInputChange("fromTime", event.target.value)}
            onClear={() => onInputChange("fromTime", "")}
            required={isMeetingPlanned() || create}
            showErrorWhenEmpty={isMeetingPlanned() || create}
            min={getMinIfTodaysDate()}
          />
          <InputField
            type="time"
            value={meeting?.toTime}
            customCssClass={styles.inputInline}
            onChange={event => onInputChange("toTime", event.target.value)}
            onClear={() => onInputChange("toTime", "")}
            required={isMeetingPlanned() || create}
            showErrorWhenEmpty={isMeetingPlanned() || create}
            min={meeting?.fromTime > meeting?.toTime ? meeting?.fromTime : null}
          />
        </div>
      </div>

      <div>
        <section className={styles.split}>
          <div className={styles.column}>
            <UserOrFacilityAddress
              client={fitting?.client}
              fitting={fitting}
              meeting={meeting}
              onInputChange={onInputChange}
              setShowRoom={setShowRoom}
              edit={edit}
            />
          </div>

          <div className={styles.column}>
            <div>
              <Label
                label={translate("ROOM")}
                inline={true}
                customCssClass={classNames(styles.labelGrid, styles.inline)}
                value={
                  <SelectField
                    disabled={!showRoom}
                    options={options.room}
                    selectedId={meeting?.roomId}
                    customSettings={{ display: "label" }}
                    onSelect={({ id }) => onInputChange("roomId", id)}
                  />
                }
              />
              <Label
                label={translate("CAR")}
                inline
                customCssClass={classNames(styles.labelGrid, styles.inline)}
                value={
                  <SelectField
                    options={options.car}
                    selectedId={meeting?.carId}
                    customSettings={{ display: "label" }}
                    onSelect={({ id }) => onInputChange("carId", id)}
                  />
                }
              />
            </div>
          </div>
        </section>
      </div>

      <div>
        <section className={styles.split}>
          <div className={styles.column}>
            <Label
              label={translate("CONSULTANT")}
              inline
              value={
                <SelectField
                  options={options.consultant}
                  selectedId={meeting?.consultantId}
                  customSettings={{ display: "label" }}
                  onSelect={({ id }) => onInputChange("consultantId", id)}
                />
              }
              customCssClass={styles.inline}
            />
          </div>

          <div className={styles.column}>
            <Label
              label={translate("TECHNICIAN")}
              inline
              value={
                <SelectField
                  options={options.technician}
                  selectedId={meeting?.technicianId}
                  customSettings={{ display: "label" }}
                  onSelect={({ id }) => onInputChange("technicianId", id)}
                />
              }
              customCssClass={styles.inline}
            />
          </div>
        </section>

        <div className={styles.buttons}>
          <Button onClick={() => onUpdateMeeting()}>{translate("SAVE")}</Button>

          <Button theme={THEME.SECONDARY} onClick={() => cancel()}>
            {translate("CANCEL")}
          </Button>
        </div>
      </div>
    </>
  );
};

CreateOrEditNextMeeting.propTypes = {
  fitting: PropType.object,
  setMeeting: PropType.func,
  meeting: PropType.object,
  edit: PropType.bool,
  setEdit: PropType.func,
  create: PropType.bool,
  setCreate: PropType.func,
  options: PropType.object,
  setFetchFittings: PropType.func,
};

export default CreateOrEditNextMeeting;
