import { Button } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import { openOrderPopup } from "../../../../reducers/dialog/dialogAction";

const ProductCatalogueOrderButton = ({ hasOrder, product, onClick, customCssClass }) => {
  const [isLoading, setIsLoading] = useState(false);
  const translate = useTranslate();

  const onClickButton = useCallback(
    async (productRef, webOrder) => {
      setIsLoading(true);
      await onClick(productRef, webOrder);
      setIsLoading(false);
    },
    [onClick],
  );

  const openPopupWithoutReferencedOrder = useCallback(() => {
    openOrderPopup({ includeNavigation: false, productRef: product.id, addToCart: onClickButton });
  }, [product.id, onClickButton]);

  {
    return (
      <Button
        customCssClass={customCssClass}
        onClick={!hasOrder ? openPopupWithoutReferencedOrder : onClickButton}
        isLoading={isLoading}
      >
        {translate("ADD_TO_CART")}
      </Button>
    );
  }
};

ProductCatalogueOrderButton.propTypes = {
  hasOrder: PropTypes.bool,
  product: PropTypes.shape({ id: PropTypes.string }),
  onClick: PropTypes.func,
  customCssClass: PropTypes.string,
};

export default ProductCatalogueOrderButton;
