import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { Modal, Form, Button } from "@myloc/myloc-gui";
import SelectField from "../../shared/SelectField/SelectField";
import styles from "../FittingDetailPage.module.scss";
const SelectFieldModal = ({
  visible,
  title,
  onSubmit,
  onClose,
  options,
  selectedId,
  onSelect,
  template,
  display,
  warningText = null,
  required,
}) => {
  const translate = useTranslate();

  return (
    <Modal visible={visible} title={title} onClose={onClose} customCssClass={styles.fittingModal}>
      {warningText && (
        <div className={styles.warningText}>
          <span>{warningText}</span>
        </div>
      )}
      <Form onSubmit={onSubmit} className={styles.form}>
        <SelectField
          label={title}
          options={options}
          selectedId={selectedId}
          onSelect={onSelect}
          customCssClass={styles.selectField}
          customSettings={{ template: template, display: display }}
          required={required}
        />
        <Button type="submit">{translate("SAVE")}</Button>
      </Form>
    </Modal>
  );
};

SelectFieldModal.propTypes = {
  visible: PropType.bool,
  title: PropType.string,
  onSubmit: PropType.func,
  onClose: PropType.func,
  options: PropType.array,
  selectedId: PropType.object,
  onSelect: PropType.func,
  template: PropType.func,
  display: PropType.string,
  warningText: PropType.string,
  required: PropType.bool,
};

export default SelectFieldModal;
