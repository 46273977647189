export const DELIVERY_LOCATION = {
  BASE_STORAGE: { id: "PRODUCT_PICKUP", value: "PICKUP_IN_BASE_STORAGE" },
  DELIVERY: { id: "PRODUCT_DELIVERY", value: "DELIVERY" },
};

export const ORDER_CONTROL = {
  NONE: { id: "NONE", value: "" },
  INSTALLATION_MANDATORY: { id: "INSTALLATION_MANDATORY", value: "INSTALLATION" },
  INSTALLATION_OPTIONAL: { id: "INSTALLATION_OPTIONAL", value: "INSTALLATION" },
  ASSEMBLY_MANDATORY: { id: "ASSEMBLY_MANDATORY", value: "ASSEMBLY" },
  ASSEMBLY_OPTIONAL: { id: "ASSEMBLY_OPTIONAL", value: "ASSEMBLY" },
};

export function isOrderControlMandatory(orderControlId) {
  return (
    ORDER_CONTROL.INSTALLATION_MANDATORY.id === orderControlId || ORDER_CONTROL.ASSEMBLY_MANDATORY.id === orderControlId
  );
}

export function getOrderControlValue(orderControlId) {
  if (ORDER_CONTROL.INSTALLATION_MANDATORY.id === orderControlId) return ORDER_CONTROL.INSTALLATION_MANDATORY.value;
  if (ORDER_CONTROL.INSTALLATION_OPTIONAL.id === orderControlId) return ORDER_CONTROL.INSTALLATION_OPTIONAL.value;
  if (ORDER_CONTROL.ASSEMBLY_MANDATORY.id === orderControlId) return ORDER_CONTROL.ASSEMBLY_MANDATORY.value;
  if (ORDER_CONTROL.ASSEMBLY_OPTIONAL.id === orderControlId) return ORDER_CONTROL.ASSEMBLY_OPTIONAL.value;

  return ORDER_CONTROL.NONE.value;
}
