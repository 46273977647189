import PropType from "prop-types";
import colors from "../../../../style/exports.module.scss";

import styles from "./Content.module.scss";
import { File, Video } from "../../../../assets/icons";
import { ExternalLink, Target } from "../../Link";
import Image from "../../Image/Image";
import FileIcon from "../../File/FileIcon/FileIcon";
import { LINKED_CATEGORIES } from "../../../../utils/constants";

const TabContent = ({ links, type }) => {
  if (!links?.length || !type) {
    return null;
  }

  const getIcon = link => {
    switch (type) {
      case LINKED_CATEGORIES.IMAGE:
        return <Image src={link.url} alt={link.name} customCssClass={styles.image} />;
      case LINKED_CATEGORIES.DOCUMENT:
        return <FileIcon fileName={link.url} size={40} />;
      case LINKED_CATEGORIES.VIDEO:
        return <Video size={40} color={colors.iconColor} />;
      default:
        return <File />;
    }
  };

  return (
    <div className={styles.container}>
      {links.map(link => (
        <div key={link.id} className={styles.row}>
          {getIcon(link)}
          <ExternalLink url={link.url} customCssClass={styles.link} target={Target.NEW_TAB}>
            {link.name}
          </ExternalLink>
        </div>
      ))}
    </div>
  );
};

TabContent.propTypes = {
  links: PropType.array,
  type: PropType.string,
};

export default TabContent;
