import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslate } from "../../../language/i18n";
import { CLIENT_TYPE } from "../../../utils/constants";
import RadioButtonGroup from "../RadioButton/RadioButtonGroup";

export default function ClientSelector({
  defaultType = CLIENT_TYPE.USER,
  includeSecretId = false,
  onChange,
  customCssClass,
}) {
  const translate = useTranslate();

  const values = [
    {
      id: CLIENT_TYPE.USER,
      value: translate("USER"),
    },
    {
      id: CLIENT_TYPE.BASE_STORAGE,
      value: translate("BASE_STORAGE"),
    },
    {
      id: CLIENT_TYPE.SECRET_ID,
      value: translate("SECRET_ID"),
    },
  ];

  const [selected, setSelected] = useState(defaultType);

  function select(value, e) {
    setSelected(value.id);
    onChange(value, e);
  }

  return (
    <RadioButtonGroup
      selected={selected}
      values={includeSecretId ? values : values.splice(0, 2)}
      onChange={select}
      customCssClass={customCssClass}
    />
  );
}

ClientSelector.propTypes = {
  defaultType: PropTypes.oneOf([CLIENT_TYPE.USER, CLIENT_TYPE.BASE_STORAGE, CLIENT_TYPE.SECRET_ID]),
  includeSecretId: PropTypes.bool,
  onChange: PropTypes.func,
  customCssClass: PropTypes.string,
};
