import { useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Collapse, Button, THEME } from "@myloc/myloc-gui";
import { ChevronUp, ChevronDown } from "../../../../assets/icons";
import { useTranslate } from "../../../../language/i18n";
import { setOrder } from "../../../../reducers/appData/appDataActions";
import { openOrderPopup } from "../../../../reducers/dialog/dialogAction";
import orderService from "../../../../services/order/orderService";
import { CLIENT_TYPE } from "../../../../utils/constants";
import OnClickOutside from "../../../../utils/OnClickOutside";
import pages from "../../../../utils/pages";
import styles from "./OrderMenu.module.scss";

function OrderMenu({ customCssClass }) {
  const translate = useTranslate();

  const [menuVisible, setMenuVisible] = useState(false);
  const order = useSelector(state => state.appData.order);
  const [orders, setOrders] = useState([]);

  const handleOnClick = async () => {
    const filters = {
      amount: 100,
      status: "0:>;20:<",
    };
    const response = await orderService.getOrders(filters);
    setOrders(response.data.page);

    setMenuVisible(!menuVisible);
  };

  const clickHandler = async (event, order) => {
    const response = await orderService.setOrder(order.id);
    if (response.isOk()) {
      setOrder(response.data);
      setMenuVisible(false);
    }
  };

  //TODO
  //Menu option: Continue order - only show if active order exist

  const buttonText = (
    <p className={styles.recieverInfo}>
      {translate("ORDER")}
      <span className={styles.orderId}>
        {order?.receiver && order?.orderNumber ? order.orderNumber : translate("NO_ORDER_SELECTED")}
      </span>
    </p>
  );

  return (
    <OnClickOutside call={() => setMenuVisible(false)}>
      <section className={classNames(styles.menuWrapper, customCssClass)}>
        <Button theme={THEME.PLAIN} onClick={handleOnClick}>
          {buttonText}
          {menuVisible ? <ChevronUp customCssClass={styles.icon} /> : <ChevronDown customCssClass={styles.icon} />}
        </Button>
        <Collapse visible={menuVisible} customCssClass={styles.submenuWrapper}>
          <div className={styles.userMenu}>
            <ul>
              <li>
                <Button theme={THEME.PLAIN} onClick={openOrderPopup}>
                  {translate("NEW_ORDER")}
                </Button>
              </li>
            </ul>

            {Boolean(orders?.length > 0) && (
              <small className={styles.divider}>{order ? translate("CHANGE_ORDER") : translate("CHOOSE_ORDER")}</small>
            )}
            <ul className={styles.orderMenu}>
              {orders?.map(o => (
                <li key={o.id} className={styles.row}>
                  <Link to={generatePath(pages.CART.PATH, { order: o.id })} onClick={event => clickHandler(event, o)}>
                    <p className={styles.receiver}>
                      {o.receiver.type === CLIENT_TYPE.BASE_STORAGE
                        ? `${o.receiver.value} - ${o.receiver.label}`
                        : `${o.receiver.firstName} ${o.receiver.surname}`}
                    </p>
                    <p className={styles.order}>
                      {translate("ORDER")}: {o.orderNumber}
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Collapse>
      </section>
    </OnClickOutside>
  );
}

OrderMenu.propTypes = {
  customCssClass: PropTypes.string,
};

export default OrderMenu;
