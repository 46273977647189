import accountService from "../../../services/account/accountService";
import SpinningButton, { SpinningButtonVariant } from "../SpinningButton/SpinningButton";

const LOGOUT_BUTTON_WIDTH = 300;

const LogoutButton = ({
  variant,
  widthPX = LOGOUT_BUTTON_WIDTH,
}: {
  variant: SpinningButtonVariant;
  widthPX?: number;
}) => {
  const logout = async () => {
    await accountService.logout();
  };

  return (
    <SpinningButton widthPX={widthPX} buttonAction={logout} variant={variant}>
      Logga ut
    </SpinningButton>
  );
};

export default LogoutButton;
