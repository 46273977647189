import PropType from "prop-types";
import LogisticsConfirmModal from "../shared/Modals/LogisticsConfirmModal";
import { useTranslate } from "../../language/i18n";
import orderService from "../../services/order/orderService";
import styles from "./FittingsPage.module.scss";

const ReleaseFittingsModal = ({ visible, onClose, meetings }) => {
  const translate = useTranslate();

  const onSubmit = async () => {
    const response = await orderService.releaseFittingMeetings(meetings);
    if (response.isOk()) {
      location.reload();
    }
  };

  return (
    <LogisticsConfirmModal
      title={translate("FITTING_RELEASE")}
      text={translate("FITTING_RELEASE_DESCRIPTION")}
      visible={visible}
      cancel={onClose}
      small={false}
      submit={onSubmit}
      customCssClass={styles.releaseModal}
    ></LogisticsConfirmModal>
  );
};

ReleaseFittingsModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  meetings: PropType.array,
};

export default ReleaseFittingsModal;
