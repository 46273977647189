import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import styles from "../FittingDetailPage.module.scss";
import { useEffect } from "react";
import pages from "../../../utils/pages";
import { FormData } from "@myloc/myg-forms";

const OtherInfo = ({ fitting, setBreadcrumbs, embeddedFitting }) => {
  const translate = useTranslate();
  useEffect(() => {
    setBreadcrumbs([
      {
        text: embeddedFitting?.text
          ? translate(embeddedFitting?.text)
          : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}`,
        link: embeddedFitting?.path ? embeddedFitting?.path : pages.MY_FITTINGS.PATH,
      },
      {
        text: `${translate(pages.FITTING_DETAIL.NAME)} - ${translate(pages.MY_FITTINGS_OTHER_INFO.NAME)}`,
      },
    ]);
  }, [embeddedFitting, setBreadcrumbs, translate]);

  return (
    <section>
      <h3 className={styles.title}>{translate("FITTING_SUB_NAV_OTHER_INFO")}</h3>
      {fitting.form ? <FormData form={fitting.form} /> : <div>{translate("NO_OTHER_INFO")}</div>}
    </section>
  );
};

OtherInfo.propTypes = {
  fitting: PropType.object,
  setBreadcrumbs: PropType.func,
  embeddedFitting: PropType.object,
};

export default OtherInfo;
