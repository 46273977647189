import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import getFormData from "../../utils/getFormData";
import setQueryFilters from "../../utils/setQueryFilters";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getMessages(filters = {}) {
  const url = api.messages.messages();
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getMessage(messageId) {
  const url = api.messages.message(messageId);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function sendMessage(message, files) {
  const url = api.messages.messages();

  const request = new Request(url, Method.POST);
  return await sendRequest(request, getFormData({ data: message, files }), await defaultRestOptions());
}

async function performMessageAction(messageId, data) {
  const url = api.messages.message(messageId);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getMessageReceivers(filters = {}) {
  const url = api.messages.receivers();
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default {
  getMessages,
  getMessage,
  sendMessage,
  performMessageAction,
  getMessageReceivers,
};
