import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import { Car, Clock, Day, Place, Room } from "../../assets/icons";
import { useTranslate } from "../../language/i18n";
import { setEmbeddedStatus } from "../../reducers/embedded/embeddedActions";
import orderService from "../../services/order/orderService";
import { FITTING_STATUS } from "../../utils/constants";
import { getPlace } from "../../utils/getPlace";
import pages from "../../utils/pages";
import Page from "../shared/Page/Page";
import RadioButtonGroup from "../shared/RadioButton/RadioButtonGroup";
import styles from "./FittingsPage.module.scss";
import Label from "./Label";

const MyFittingsPage = () => {
  const translate = useTranslate();
  const embeddedStatus = useSelector(state => state.embedded?.status);
  const [status, setStatus] = useState(
    embeddedStatus ? embeddedStatus : { id: FITTING_STATUS.ACTIVE, value: translate("FITTING_STATUS_ACTIVE") },
  );
  const [fittings, setFittings] = useState(null);

  useEffect(() => {
    orderService
      .getFittings({
        status: status.id,
        all: true,
        sort:
          status.id === FITTING_STATUS.ACTIVE
            ? "meetingStartDateTime:asc"
            : status.id === FITTING_STATUS.CLOSED
            ? "closedDateTime:desc"
            : "",
      })
      .then(response => setFittings(response.data.page));
  }, [status.id]);

  const statusValues = [
    { id: FITTING_STATUS.ACTIVE, value: translate("FITTING_STATUS_ACTIVE") },
    { id: FITTING_STATUS.WAITING, value: translate("FITTING_STATUS_WAITING") },
    { id: FITTING_STATUS.CLOSED, value: translate("FITTING_STATUS_CLOSED") },
  ];

  const showColumn = column => {
    switch (column) {
      case "MEETING_TIME_SHORT":
        return [FITTING_STATUS.ACTIVE].includes(status.id);
      case "CLOSED":
        return [FITTING_STATUS.CLOSED].includes(status.id);
      default:
        return true;
    }
  };

  const onChange = data => {
    setStatus(data);
    setEmbeddedStatus(data);
  };

  return (
    <Page title={translate("SUB_NAV_MY_FITTINGS")} customCssClass={styles.page} showRefreshButton={true}>
      <RadioButtonGroup values={statusValues} onChange={onChange} selected={status.id} customCssClass={styles.filter} />
      {status.id !== FITTING_STATUS.CLOSED && (
        <p className={styles.statusDescription}>{translate(`FITTING_STATUS_${status.id}_DESCRIPTION`)}</p>
      )}
      {fittings?.length ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{translate("FITTING")}</th>
              <th>{translate("PRESCRIBER")}</th>
              {showColumn("MEETING_TIME_SHORT") && <th>{translate("MEETING_TIME_SHORT")}</th>}
            </tr>
          </thead>

          <tbody>
            {fittings?.map(fitting => (
              <tr className={styles.row} key={fitting.id}>
                <td>
                  <div className={styles.info}>
                    <Link
                      to={{
                        pathname: generatePath(pages.EMBEDDED_MY_FITTING.PATH, { fitting: fitting.id, type: "info" }),
                        state: {
                          path: `${window.location.pathname}`,
                          text: "SUB_NAV_MY_FITTINGS",
                          fromComponent: `${window.location.pathname}${window.location.search}`,
                        },
                      }}
                    >
                      {`${translate("FITTING")} ${fitting.contentNumber} / ${fitting.fittingType?.name}`}
                    </Link>
                  </div>
                  <div className={styles.info}>{`${fitting.client?.firstName} ${fitting.client?.surname}`}</div>
                  <div className={styles.info}>{`${fitting.client?.identityNumber}`}</div>
                </td>

                <td>
                  <div className={styles.info}>{`${fitting.prescriber?.firstName} ${fitting.prescriber?.surname}`}</div>
                  <div className={styles.info}>{`${fitting.prescriber?.phoneNo}`}</div>
                  <div className={styles.info}>{`${fitting.prescriber?.email}`}</div>
                </td>

                {showColumn("MEETING_TIME_SHORT") && (
                  <td>
                    <div className={styles.row}>
                      <div>
                        <Day customCssClass={styles.icon} />
                        {fitting.nextMeeting?.date}
                      </div>
                      <div>
                        <Clock customCssClass={styles.icon} />
                        {fitting.nextMeeting?.fromTime && fitting.nextMeeting?.toTime
                          ? `${fitting.nextMeeting?.fromTime} - ${fitting.nextMeeting?.toTime}`
                          : ""}
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div>
                        <Place customCssClass={styles.icon} />
                        {getPlace(fitting?.nextMeeting, translate("CLIENT"))}
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div>
                        <Room customCssClass={styles.icon} />
                        {fitting.nextMeeting?.room?.label || ""}
                      </div>
                      <div>
                        <Label
                          label={translate("CONSULTANT")}
                          value={`${fitting.nextMeeting?.consultant?.firstName || ""} ${
                            fitting.nextMeeting?.consultant?.surname || ""
                          }`}
                          customCssClass={styles.cleanSpacing}
                        />
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div>
                        <Car customCssClass={styles.icon} />
                        {fitting.nextMeeting?.car?.label || ""}
                      </div>
                      <div>
                        <Label
                          label={translate("TECHNICIAN")}
                          value={`${fitting.nextMeeting?.technician?.firstName || ""} ${
                            fitting.nextMeeting?.technician?.surname || ""
                          }`}
                          customCssClass={styles.cleanSpacing}
                        />
                      </div>
                    </div>
                  </td>
                )}

                {showColumn("CLOSED") && (
                  <td>
                    <div className={styles.fittingCompleted}>
                      {`${fitting?.status?.value === 90 ? translate("COMPLETED") : translate("ANNULLED")}: ${
                        fitting.closedDateTime
                      }`}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        fittings && <p className={styles.noResults}>{translate("NO_RESULTS_FOUND")}</p>
      )}
    </Page>
  );
};

export default MyFittingsPage;
