import PropTypes from "prop-types";
import Counter from "../../../shared/Counter/Counter";

const ProductCatalogueQuantity = ({ setQuantity }) => {
  return <Counter startValue={1} min={1} onChange={setQuantity} />;
};

ProductCatalogueQuantity.propTypes = {
  setQuantity: PropTypes.func,
};

export default ProductCatalogueQuantity;
