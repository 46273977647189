import { useHistory } from "@myloc/myloc-utils";
import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import { Clock, Place, Room } from "../../../assets/icons";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import pages from "../../../utils/pages";
import Page from "../../shared/Page/Page";
import Paginate from "../../shared/Pagination/Paginate";
import RadioButtonGroup from "../../shared/RadioButton/RadioButtonGroup";

import { FITTING_STATUS } from "../../../utils/constants";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import styles from "./Fittings.module.scss";

const DEFAULT_FILTER = FITTING_STATUS.ACTIVE;

const Fittings = ({ setBreadcrumbs, fromComponent }) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [status, setStatus] = useState(params.get("status") || DEFAULT_FILTER);
  const [fittings, setFittings] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const translate = useTranslate();

  const setHistory = selected => {
    const params = new URLSearchParams(window.location.search);
    params.set("status", selected.id);
    history.replace(pages.MY_FITTINGS.PATH, params);
  };

  useEffect(() => {
    setBreadcrumbs([{ text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}` }]);
  }, [setBreadcrumbs, translate]);

  const statusValues = [
    {
      value: translate("FITTING_STATUS_ACTIVE"),
      id: FITTING_STATUS.ACTIVE,
    },
    {
      value: translate("FITTING_STATUS_WAITING"),
      id: FITTING_STATUS.WAITING,
    },
    {
      value: translate("FITTING_STATUS_CLOSED"),
      id: FITTING_STATUS.CLOSED,
    },
  ];

  const showColumn = column => {
    switch (column) {
      case "MEETING_TIME_SHORT":
        return [FITTING_STATUS.ACTIVE].includes(status);
      case "CLOSED":
        return [FITTING_STATUS.CLOSED].includes(status);
      default:
        return true;
    }
  };

  const handleStatusChange = async selected => {
    setHistory(selected);
    setStatus(selected.id);
  };

  return (
    <Page customCssClass={styles.page} title={translate("SUB_NAV_MY_FITTINGS")}>
      <Paginate
        setIsLoading={setLoading}
        isLoading={isLoading}
        onChange={setFittings}
        provider={useCallback(
          filter =>
            orderService.getFittings({
              status: status,
              ...filter,
              sort:
                status === FITTING_STATUS.ACTIVE
                  ? "meetingStartDateTime:asc"
                  : status === FITTING_STATUS.CLOSED
                  ? "closedDateTime:desc"
                  : "",
            }),
          [status],
        )}
      >
        <section>
          <RadioButtonGroup
            values={statusValues}
            onChange={handleStatusChange}
            selected={status}
            customCssClass={styles.filter}
          />
          {status !== FITTING_STATUS.CLOSED && (
            <p className={styles.statusDescription}>{translate(`FITTING_STATUS_${status}_DESCRIPTION`)}</p>
          )}
          {isLoading ? (
            <LoadingSpinner title="FITTINGS_LOADING" />
          ) : (
            fittings?.length > 0 && (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>{translate("FITTING")}</th>
                    {showColumn("MEETING_TIME_SHORT") && <th>{translate("MEETING_TIME_SHORT")}</th>}
                  </tr>
                </thead>

                <tbody>
                  {fittings?.map(fitting => (
                    <tr className={styles.row} key={fitting.id}>
                      <td>
                        <div className={styles.info}>
                          <Link
                            to={{
                              pathname: generatePath(pages.MY_FITTINGS_INFO.PATH, { fitting: fitting.id }),
                              state: {
                                referrer: {
                                  path: `${window.location.pathname}${window.location.search}`,
                                  text: "",
                                  fromComponent: fromComponent,
                                },
                              },
                            }}
                          >
                            {`${translate("FITTING")} ${fitting.contentNumber} / ${fitting.fittingType?.name}`}
                          </Link>
                        </div>
                        <div className={styles.info}>{`${fitting.client?.firstName} ${fitting.client?.surname}`}</div>
                        <div className={styles.info}>{`${fitting.client?.identityNumber}`}</div>
                      </td>

                      {showColumn("MEETING_TIME_SHORT") && (
                        <td>
                          <div className={styles.split}>
                            <div>
                              <div className={styles.info}>
                                <Clock customCssClass={styles.icon} />
                                {`${fitting.nextMeeting?.date} ${fitting.nextMeeting?.fromTime} - ${fitting.nextMeeting?.toTime}`}
                              </div>
                              <div className={styles.info}>
                                <Place customCssClass={styles.icon} />{" "}
                                {`${fitting.nextMeeting?.address?.street}, ${fitting.nextMeeting?.address?.postalNo} ${fitting.nextMeeting?.address?.city}`}
                              </div>
                              <div className={styles.info}>
                                <Room customCssClass={styles.icon} />
                                {fitting.nextMeeting?.room?.label || ""}
                              </div>
                            </div>
                            <div>
                              <div className={styles.info}>
                                <span>{translate("CONSULTANT")}: </span>
                                {`${fitting.nextMeeting?.consultant?.firstName || ""} ${
                                  fitting.nextMeeting?.consultant?.surname || ""
                                }`}
                              </div>
                              <div className={styles.info}>
                                <span>{translate("TECHNICIAN")}: </span>
                                {`${fitting.nextMeeting?.technician?.firstName || ""} ${
                                  fitting.nextMeeting?.technician?.surname || ""
                                }`}
                              </div>
                            </div>
                          </div>
                        </td>
                      )}

                      {showColumn("CLOSED") && (
                        <td>
                          <div className={styles.completedStatus}>{`${
                            fitting?.status?.value === 90 ? translate("COMPLETED") : translate("ANNULLED")
                          }: ${fitting.closedDateTime}`}</div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          )}
        </section>
      </Paginate>
    </Page>
  );
};

Fittings.propTypes = {
  setBreadcrumbs: PropType.func,
  fromComponent: PropType.string,
};

export default Fittings;
