// import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { IconButton } from "@myloc/myloc-gui";
import { ChevronLeft, ChevronRight } from "../../../assets/icons";
import styles from "./Pagination.module.scss";
import classNames from "classnames";

function Pagination({ totalCount = 0, pageSize, currentPage, onPageChange, customCssClass, page, setPage }) {
  const translate = useTranslate();
  const pageCount = Math.ceil(totalCount / pageSize);

  //Dont show pagination if there is only one page
  if (pageCount === 1 || totalCount === 0) return null;

  const onKeyDown = event => {
    if (event.key === "Enter" && page) {
      //Blur event calls the onPageChange
      event.target.blur();
    }
  };

  const pages = [...Array(pageCount).keys()].map(i => i + 1);

  const previousPage = () => {
    const currPage = parseInt(currentPage, 10);
    currPage > 1 && onPageChange(currPage - 1);
  };

  const nextPage = () => {
    const currPage = parseInt(currentPage, 10);
    currPage < pages.length && onPageChange(currPage + 1);
  };

  const handleOnBlur = e => {
    const val = e.target.value;
    if (!val) {
      onPageChange(1);
    } else {
      onPageChange(page);
    }
  };

  const onInputChange = e => {
    const value = e.target.value;
    if (!value) {
      setPage("");
      return;
    }
    const parsedVal = parseInt(value, 10);
    if (parsedVal <= pages.length && parsedVal >= 1) {
      setPage(parsedVal);
    }
  };

  return (
    <section className={classNames(styles.pagination, customCssClass)}>
      <IconButton onClick={previousPage} customCssClass={styles.pageButton} disabled={currentPage === 1}>
        <ChevronLeft size="32" />
      </IconButton>
      <div className={styles.pageInfo}>
        <input
          type="number"
          value={page}
          onBlur={handleOnBlur}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          className={styles.currentPageInput}
          min={1}
          max={pages.length}
        />
        <p className={styles.totalPages}>
          <span className={styles.text}>{translate("OF")}</span>
          {pages.length}
        </p>
      </div>
      <IconButton onClick={nextPage} customCssClass={styles.pageButton} disabled={currentPage === pages.length}>
        <ChevronRight size="32" />
      </IconButton>
    </section>
  );
}

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onPageChange: PropTypes.func.isRequired,
  customCssClass: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setPage: PropTypes.func,
};

export default Pagination;
