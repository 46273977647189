import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getPageContent() {
  const request = new Request(api.pageContent.content(), Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default { getPageContent };
