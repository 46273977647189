import PropTypes from "prop-types";

const Video = ({ color = "currentColor", size = 24, customCssClass }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M21 3H3c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.11-.9-2-2-2zm0 14H3V5h18v12zm-5-6l-7 4V7z"></path>
  </svg>
);

Video.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  customCssClass: PropTypes.string,
};

export default Video;
