import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import AddressModal from "../AddressModal/AddressModal";
import Selector from "../Selector/Selector";
import styles from "./UserAddress.module.scss";

function UserAddress({
  user,
  addresses,
  showAddButton = true,
  selectedAddress,
  customCssClass,
  onSelect,
  onReload,
  required,
}) {
  const translate = useTranslate();
  const [addressModalProps, setAddressModalProps] = useState({ visible: false, edit: false });

  const handleEditAddress = () => {
    setAddressModalProps({ visible: true, edit: true });
  };

  const handleAddNewAddress = () => {
    setAddressModalProps({ visible: true, edit: false });
  };

  const handleModalClose = address => {
    if (!addressModalProps.edit && address) onSelect(address);
    if (address) onReload?.();

    setAddressModalProps({ visible: false });
  };

  const showSelected = useCallback(
    addr => (
      <>
        <address className={styles.address}>
          {addr.careOf && (
            <p>
              {translate("CARE_OF")} {addr.careOf}
            </p>
          )}
          {addr.street && <p>{addr.street}</p>}
          <p>
            {addr.postalNo} {addr.city}
          </p>
        </address>
      </>
    ),
    [translate],
  );

  const clientAddrTemplate = useCallback(
    item => (
      <>
        <p>{item.description}</p>
        {item.careOf && <p>c/o {item.careOf}</p>}
        <p>{item.street}</p>
        <p>
          {item.postalNo} {item.city}
        </p>
      </>
    ),
    [],
  );

  return (
    <>
      <Selector
        label={translate("ADDRESS")}
        addNewButtonTitle={translate("NEW_ADDRESS")}
        displayValue="description"
        options={addresses}
        selected={selectedAddress}
        listTemplate={clientAddrTemplate}
        displaySelected={showSelected}
        onAdd={showAddButton ? handleAddNewAddress : null}
        onEdit={showAddButton ? handleEditAddress : null}
        onChange={onSelect}
        customCssClass={customCssClass}
        required={required}
      />
      <AddressModal
        userId={user?.id}
        visible={addressModalProps.visible}
        address={addressModalProps.edit ? selectedAddress : null}
        onClose={handleModalClose}
      />
    </>
  );
}

const addressPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  street: PropTypes.string,
  careOf: PropTypes.string,
  postalNo: PropTypes.string,
  city: PropTypes.string,
  isEditable: PropTypes.bool,
});

UserAddress.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  showAddButton: PropTypes.bool,
  addresses: PropTypes.arrayOf(addressPropType),
  selectedAddress: addressPropType,
  onReload: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  customCssClass: PropTypes.string,
  required: PropTypes.bool,
};

export default UserAddress;
