import { useState, useEffect } from "react";

export function useMobile() {
  const currentBreakpoint = useCurrentBreakpoint();
  return currentBreakpoint === Breakpoints.Small;
}

export function useTablet() {
  const currentBreakpoint = useCurrentBreakpoint();
  return currentBreakpoint <= Breakpoints.Medium;
}

export function useDesktop() {
  const currentBreakpoint = useCurrentBreakpoint();
  return currentBreakpoint >= Breakpoints.Large;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function useCurrentBreakpoint() {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(getBreakpoint());

  function getBreakpoint() {
    const width = getWindowDimensions().width;
    if (width <= Breakpoints.Small) return Breakpoints.Small;
    if (width <= Breakpoints.Medium) return Breakpoints.Medium;
    if (width <= Breakpoints.Large) return Breakpoints.Large;

    return Breakpoints.Full;
  }

  useEffect(() => {
    function handleResize() {
      setCurrentBreakpoint(getBreakpoint());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return currentBreakpoint;
}

const Breakpoints = {
  Small: 600,
  Medium: 1000,
  Large: 1366,
  Full: 1920,
};

export function isTouchScreen() {
  if ("ontouchstart" in document.documentElement) {
    return true;
  }
  return false;
}
