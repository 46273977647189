import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import { CLIENT_TYPE } from "../../../../utils/constants";
import { Button } from "@myloc/myloc-gui";
import { DELIVERY_LOCATION, ORDER_CONTROL } from "./productConstants";
import styles from "./OrderButton.module.scss";

const OrderButton = ({
  product: { isInventory = false, accessories = [], isOrderable },
  receiver,
  receiverLocation,
  formSetting,
  selectedStorage,
  orderControl,
  orderControlChecked,
  onClick,
  isActiveReferencedOrder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const translate = useTranslate();
  const hasAccessories = accessories && accessories.length > 0;
  const isClient = receiver?.type !== CLIENT_TYPE.BASE_STORAGE;
  const isPickup = receiverLocation === DELIVERY_LOCATION.BASE_STORAGE.id;

  const hasToSelectAccessories = isClient && hasAccessories;
  const hasToSelectInventory = isPickup && isInventory;

  const hasForm = () => {
    if (!orderControlChecked) return !!formSetting?.hasDeliveryForm;
    if (orderControl === ORDER_CONTROL.ASSEMBLY_MANDATORY.id || orderControl === ORDER_CONTROL.ASSEMBLY_OPTIONAL.id)
      return !!formSetting?.hasAssemblyForm;

    if (
      orderControl === ORDER_CONTROL.INSTALLATION_MANDATORY.id ||
      orderControl === ORDER_CONTROL.INSTALLATION_OPTIONAL.id
    )
      return !!formSetting?.hasAssemblyForm;

    return !!formSetting?.hasDeliveryForm;
  };

  const addToCart = isPickup || !(hasToSelectAccessories || hasForm() || isClient || hasToSelectInventory);
  const disabled =
    !isActiveReferencedOrder &&
    (!receiver || (isPickup && isInventory) || (isPickup && !selectedStorage) || (!selectedStorage && !isOrderable));

  const onClickButton = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <Button onClick={onClickButton} isLoading={isLoading} disabled={disabled} customCssClass={styles.button}>
      {translate(addToCart ? "ADD_TO_CART" : "CHOOSE")}
    </Button>
  );
};

OrderButton.propTypes = {
  product: PropTypes.shape({
    isInventory: PropTypes.bool,
    accessories: PropTypes.array,
    isOrderable: PropTypes.bool,
  }),
  receiver: PropTypes.shape({ type: PropTypes.string }),
  receiverLocation: PropTypes.string,
  formSetting: PropTypes.shape({
    hasDeliveryForm: PropTypes.bool,
    hasAssemblyForm: PropTypes.bool,
    hasInstallationForm: PropTypes.bool,
  }),
  selectedStorage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    quantity: PropTypes.number,
  }),
  orderControl: PropTypes.string,
  orderControlChecked: PropTypes.bool,
  onClick: PropTypes.func,
  isActiveReferencedOrder: PropTypes.bool,
};

export default OrderButton;
