import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import defaultRestOptions from "../../../../services/utils/defaultRestOptions";
import { api } from "../config/settings";

// Define the structure of the response if necessary
interface BankIdResponse {
  isOk: () => boolean;
  message: string | { text: string };
  data?: any; // You can replace 'any' with the actual expected structure of the data
}

// Initiate authentication
export async function initiateAuth(): Promise<BankIdResponse> {
  const url = api.bankid.initiateAuth();
  const request = new Request(url, Method.POST);

  const response: BankIdResponse = await sendRequest(request, {}, await defaultRestOptions());

  return response;
}

export type OrderRef = { orderRef: string };

// Collect status based on the orderRef
export async function collect(orderRef: OrderRef): Promise<BankIdResponse> {
  const url = api.bankid.collect();

  const request = new Request(url, Method.POST);

  const response: BankIdResponse = await sendRequest(request, orderRef, await defaultRestOptions());

  return response;
}
