import PropType from "prop-types";
import classNames from "classnames";
import { useTranslate } from "../../../../language/i18n";
import { FileDownload } from "../../../shared/File/FileDownload/FileDownload";
import styles from "./DeviationAttachments.module.scss";
import { useEffect } from "react";
import pages from "../../../../utils/pages";

const DeviationAttachments = ({ referrer, deviation, setBreadcrumbs }) => {
  const translate = useTranslate();

  useEffect(() => {
    setBreadcrumbs([
      {
        text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_DEVIATIONS.NAME)}`,
        link: referrer?.path || pages.MY_DEVIATIONS.PATH,
      },
      {
        text: `${translate("DEVIATION")} - ${translate("ATTACHMENTS")}`,
      },
    ]);
  }, [referrer, setBreadcrumbs, translate]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.left}>
          <h4 className={styles.title}>{`${translate("ATTACHMENTS")}`}</h4>
        </div>
      </div>

      {deviation?.files?.length ? (
        <ul>
          {deviation.files.map(file => (
            <li key={file.id} className={styles.card}>
              <div className={styles.row}>
                <div className={classNames(styles.column, styles.icon)}>
                  <FileDownload file={file} customCssClass={styles.fileDownload} />
                </div>

                <div className={classNames(styles.column, styles.grow)}>
                  <div className={styles.info}>
                    <div>{file?.createdAt}</div>
                    <div>{file?.owner}</div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>{translate("NO_ATTACHMENTS")}</p>
      )}
    </div>
  );
};

DeviationAttachments.propTypes = {
  referrer: PropType.object,
  deviation: PropType.shape({
    status: PropType.shape({
      id: PropType.string,
    }),
    files: PropType.array,
  }),
  setBreadcrumbs: PropType.func,
};

export default DeviationAttachments;
