export const Type = {
  URL: "",
  EMAIL: "mailto:",
  PHONE: "tel:",
};

export const Target = {
  SELF: "_self",
  NEW_TAB: "_blank",
  PARENT: "_parent: ",
  FULL: "_top: ",
};
