import { useEffect, useState } from "react";
import { generatePath, useLocation, useParams } from "react-router";
import { useStepper } from "../components/shared/WizardPage/WizardPage";
import orderService from "../services/order/orderService";

const useOrderWizard = (path, init = {}) => {
  const location = useLocation();
  const queryParams = useParams();
  const [data, setData] = useState(init);

  const searchParams = new URLSearchParams(location.search);

  const stepper = useStepper(generatePath(path, queryParams), searchParams, data.currentStep, {});

  const handleNext = data => {
    stepper.handleNextStep(data, data.currentActiveStep);
  };

  useEffect(() => {
    if (queryParams.content) {
      orderService.getContent({ contentId: queryParams.content }).then(response => setData(response.data));
    } else {
      setData(init);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return {
    data,
    setData,
    handleNext,
    stepper,
  };
};

export default useOrderWizard;
