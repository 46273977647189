import { Button, Form, InputField, THEME } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { setMessage } from "../../../reducers/dialog/dialogAction";
import accountService from "../../../services/account/accountService";
import styles from "./RequestResetPassword.module.scss";

function RequestResetPassword({ onClose }) {
  const translate = useTranslate();

  const resetPassword = async event => {
    const email = event.target.elements.emailField.value;

    const response = await accountService.resetPassword({ username: email });

    if (response.isOk()) {
      if (response.message?.isCustomHandled) {
        setMessage(response.message.text);
      } else {
        setMessage(response.data.message);
      }
    }

    onClose();
  };

  return (
    <Form onSubmit={resetPassword} className={styles.form}>
      <h2 className={styles.title}>{translate("FORGOT_PASSWORD")}?</h2>
      <p>{translate("FORGOT_PASSWORD_TEXT")}</p>
      <InputField type="email" label={translate("USERNAME")} name="emailField" />
      <div className={styles.buttons}>
        <Button theme={THEME.PLAIN_PRIMARY} onClick={() => onClose()}>
          {translate("CANCEL")}
        </Button>
        <Button type="submit" customCssClass={styles.submitButton}>
          {translate("RESET")}
        </Button>
      </div>
    </Form>
  );
}

RequestResetPassword.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
export default RequestResetPassword;
