import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import { getFullDate } from "../../../../utils/DateUtil";
import Counter from "../../Counter/Counter";
import { ContentType, Item, ItemInfo, Row, RowTitle } from "../ListComponents/ListComponents";
import styles from "./Types.module.scss";

function NewProduct({ list, onDelete, onQuantityChange, readOnly }) {
  const translate = useTranslate();

  const handleQuantityChange = async (newQuantity, item) => {
    if (newQuantity !== item.product.quantity) {
      const data = { product: { ...item.product, quantity: newQuantity } };
      onQuantityChange(item.id, data);
    }
  };

  return (
    <ContentType title={translate("CART_PRODUCT_NEW_TITLE")}>
      {list.map(item => (
        <Item key={item.id} orderData={item} onDelete={onDelete} readOnly={readOnly}>
          <ItemInfo>
            <div>
              <RowTitle text={item.product.name} />
              <Row title={translate("PRODUCT_NUMBER_SHORT")} text={item.product.productNumber} />
              <Row title={translate("SUPPLIER")} text={item.product.supplier.label} />
              {item.deliveryDate && <Row title={translate("ETA")} text={getFullDate(item.deliveryDate)} />}
            </div>
            {readOnly ? (
              <p className={styles.quantityText}>
                {item.product.quantity} {item.product.unit ? item.product.unit : translate("UNITS_FULL")}
              </p>
            ) : (
              <Counter
                onChange={quantity => handleQuantityChange(quantity, item)}
                min={1}
                startValue={item.product.quantity}
              />
            )}
          </ItemInfo>
        </Item>
      ))}
    </ContentType>
  );
}

NewProduct.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      address: PropType.object,
      contentType: PropType.string,
      product: PropType.shape({
        id: PropType.string,
        name: PropType.string,
        productNumber: PropType.string,
        quantity: PropType.number,
      }),
      make: PropType.array,
      priority: PropType.object,
      status: PropType.number,
      subContentType: PropType.object,
      text: PropType.string,
    }),
  ),
  onDelete: PropType.func,
  onQuantityChange: PropType.func,
  readOnly: PropType.bool,
};

export default NewProduct;
