import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { Form, Button } from "@myloc/myloc-gui";
import styles from "./DeviationType.module.scss";
import deviationService from "../../../services/deviation/deviationService";
import SelectField from "../../shared/SelectField/SelectField";

function DeviationType({ data, setData, onNext }) {
  const translate = useTranslate();

  const [isLoading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    deviationService.getCategories().then(response => {
      setCategories(response.data);
    });
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    onNext(data);

    setLoading(false);
  };

  const disabled = !data.category || !data.type;

  const categoryHandler = category => {
    setData({
      category: !category ? {} : { id: category.id, value: category.value },
      type: null,
      reference: {
        id: "0",
        value: "Ingen referens",
      },
      code: "",
    });
  };

  return (
    <Form className={styles.content} onSubmit={handleSubmit}>
      <SelectField
        options={categories}
        onSelect={categoryHandler}
        selectedId={data?.category?.id}
        label={translate("CATEGORY")}
      />
      <SelectField
        options={categories.length && categories.find(category => category.id === data?.category?.id)?.types}
        onSelect={type => setData({ type: type || null })}
        selectedId={data?.type?.id}
        label={translate("DEVIATION_CODE")}
        customSettings={{ display: "label" }}
      />

      <Button type="submit" disabled={disabled} isLoading={isLoading} customCssClass={styles.submitBtn}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
}

DeviationType.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  deviation: PropTypes.object,
};

export default DeviationType;
