import { Button, THEME } from "@myloc/myloc-gui";
import { dialogVariables, withPage } from "@myloc/myloc-utils";
import PropType from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router";
import { useTranslate } from "../../language/i18n";
import { setMessage } from "../../reducers/dialog/dialogAction";
import accountService from "../../services/account/accountService";
import { TASK_TYPES } from "../../utils/constants";
import pages from "../../utils/pages";
import Page from "../shared/Page/Page";
import SubPageMenu from "../shared/SubPageMenu/SubPageMenu";
import styles from "./MyMylocPage.module.scss";
import Deviations from "./Sections/Deviations";
import Fittings from "./Sections/Fittings";
import Orders from "./Sections/Orders";
import Profile from "./Sections/Profile";
import Tasks from "./Sections/Tasks";
import TaskDetails from "./TaskDetails/TaskDetails";

function MyMylocPage({ receiver }) {
  const translate = useTranslate();
  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_TASKS.NAME)}` },
  ]);

  const user = useSelector(state => state.appData.user);
  const isSingleSignOn = useSelector(state => state.session.isSingleSignOn);

  const clientName = `${user?.information?.firstName} ${user?.information?.surname}`;

  const logout = async () => {
    const response = await accountService.logout(isSingleSignOn);

    if (!response.isOk()) {
      setMessage(translate("UNABLE_TO_LOG_OUT"), dialogVariables.ACTION.MESSAGE_ERROR);
    }
  };

  const params = useParams();

  const links = [pages.MY_TASKS, pages.MY_ORDERS, pages.MY_FITTINGS, pages.MY_DEVIATIONS, pages.MY_PROFILE];

  return (
    <Page
      title={translate(pages.MY_MYLOC.NAME)}
      description={clientName}
      customCssClass={styles.page}
      breadcrumbs={breadcrumbs}
    >
      <div className={styles.pageContent}>
        <SubPageMenu links={links}>
          <div className={styles.logout}>
            <Button theme={THEME.PLAIN} customCssClass={styles.navButton} onClick={logout}>
              {translate("LOGOUT")}
            </Button>
          </div>
        </SubPageMenu>
        <Switch>
          <Route exact path={pages.MY_TASKS.PATH}>
            <Tasks setBreadcrumbs={setBreadcrumbs} taskTypes={[TASK_TYPES.CA, TASK_TYPES.CH]} />
          </Route>

          <Route exact path={`${pages.MY_TASKS.PATH}/:id`}>
            <TaskDetails id={params.id} setBreadcrumbs={setBreadcrumbs} />
          </Route>

          <Route exact path={pages.MY_ORDERS.PATH}>
            <Orders setBreadcrumbs={setBreadcrumbs} />
          </Route>

          <Route exact path={pages.MY_FITTINGS.PATH}>
            <Fittings
              setBreadcrumbs={setBreadcrumbs}
              fromComponent={`${window.location.pathname}${window.location.search}`}
            />
          </Route>

          <Route exact path={pages.MY_DEVIATIONS.PATH}>
            <Deviations setBreadcrumbs={setBreadcrumbs} />
          </Route>

          <Route exact path={pages.MY_PROFILE.PATH}>
            <Profile receiver={receiver} setBreadcrumbs={setBreadcrumbs} />
          </Route>

          <Route path="/">
            <Redirect to={pages.MY_TASKS.PATH} setBreadcrumbs={setBreadcrumbs} />
          </Route>
        </Switch>
      </div>
    </Page>
  );
}

MyMylocPage.propTypes = {
  receiver: PropType.object,
};

export default withPage(MyMylocPage);
