import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import clientService from "../../../../services/client/clientService";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";

const DeleteContactModal = ({ visible, onClose, clientId, setRefetchContacts, contactId }) => {
  const translate = useTranslate();
  const onSubmit = async () => {
    const response = await clientService.deleteContact(clientId, contactId);
    if (response.isOk()) setRefetchContacts(true);
  };

  return (
    <ConfirmModal
      title={translate("DELETE_CONTACT")}
      text={translate("DELETE_CONTACT_DESCRIPTION")}
      visible={visible}
      cancel={onClose}
      submit={onSubmit}
    />
  );
};

DeleteContactModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  clientId: PropType.string.isRequired,
  contactId: PropType.string.isRequired,
  setRefetchContacts: PropType.func,
};

export default DeleteContactModal;
