import PropType from "prop-types";
import { Button } from "@myloc/myloc-gui";
import styles from "../ReceiveDeliveryOrderPage.module.scss";
import { useTranslate } from "../../../language/i18n";
import { InputField } from "@myloc/myloc-gui";

const ReceiveOrderExecuter = ({ data, attributes, addData, setCurrentStep, nextStep }) => {
  const translate = useTranslate();
  function handleStartValue(addedData, storedattribute) {
    if (addedData === null || addedData === undefined) {
      if (storedattribute) {
        addData(storedattribute?.id, storedattribute?.value);
        return addedData;
      }
    }
    return addedData;
  }

  return (
    <article>
      <section className={styles.container}>
        <div className={styles.input}>
          {attributes?.attribute1 && (
            <InputField
              label={attributes?.attribute1?.label}
              value={handleStartValue(data?.Attribute1, attributes?.attribute1)}
              onChange={event => addData(attributes?.attribute1?.id, event.target.value)}
            />
          )}
        </div>

        <div className={styles.input}>
          {attributes?.attribute2 && (
            <InputField
              label={attributes?.attribute2?.label}
              value={handleStartValue(data?.Attribute2, attributes?.attribute2)}
              onChange={event => addData(attributes?.attribute2?.id, event.target.value)}
            />
          )}
        </div>

        <div className={styles.input}>
          {attributes?.attribute3 && (
            <InputField
              label={attributes?.attribute3?.label}
              value={handleStartValue(data?.Attribute3, attributes?.attribute3)}
              onChange={event => addData(attributes?.attribute3?.id, event.target.value)}
            />
          )}
        </div>

        <div className={styles.input}>
          {attributes?.attribute4 && (
            <InputField
              label={attributes?.attribute4?.label}
              value={handleStartValue(data?.Attribute4, attributes?.attribute4)}
              onChange={event => addData(attributes?.attribute4?.id, event.target.value)}
            />
          )}
        </div>

        <div className={styles.input}>
          {attributes?.attribute5 && (
            <InputField
              label={attributes?.attribute5?.label}
              value={handleStartValue(data?.Attribute5, attributes?.attribute5)}
              onChange={event => addData(attributes?.attribute5?.id, event.target.value)}
            />
          )}
        </div>

        <div className={styles.input}>
          {attributes?.attribute6 && (
            <InputField
              label={attributes?.attribute6?.label}
              value={handleStartValue(data?.Attribute6, attributes?.attribute6)}
              onChange={event => addData(attributes?.attribute6?.id, event.target.value)}
            />
          )}
        </div>

        <div className={styles.input}>
          {attributes?.attribute7 && (
            <InputField
              label={attributes?.attribute7?.label}
              value={handleStartValue(data?.Attribute7, attributes?.attribute7)}
              onChange={event => addData(attributes?.attribute7?.id, event.target.value)}
            />
          )}
        </div>
      </section>

      <Button type="submit" customCssClass={styles.button} onClick={() => setCurrentStep(nextStep)}>
        {translate("NEXT")}
      </Button>
    </article>
  );
};

ReceiveOrderExecuter.propTypes = {
  data: PropType.object,
  attributes: PropType.object,
  addData: PropType.func,
  setCurrentStep: PropType.func,
  nextStep: PropType.number,
};

export default ReceiveOrderExecuter;
