import PropType from "prop-types";
import { useEffect } from "react";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import UserSettingsPage from "../../UserSettings/UserSettingsPage";

const Profile = ({ setBreadcrumbs }) => {
  const translate = useTranslate();
  useEffect(() => {
    setBreadcrumbs([{ text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_PROFILE.NAME)}` }]);
  }, [setBreadcrumbs, translate]);

  return <UserSettingsPage />;
};

Profile.propTypes = {
  setBreadcrumbs: PropType.func,
};

export default Profile;
