import { URL } from "@myloc/myloc-utils";
import PropTypes from "prop-types";
import { Target, Type } from "../Constants/constants";
import styles from "./ExternalLink.module.scss";

const ExternalLink = ({ url, type = Type.URL, target, children, customCssClass }) => (
  <a href={type + (url instanceof URL ? url.toString : url)} target={target && target} className={customCssClass}>
    {children}
  </a>
);

ExternalLink.propTypes = {
  url: PropTypes.oneOfType([PropTypes.instanceOf(URL), PropTypes.string]).isRequired,
  type: PropTypes.oneOf([Type.URL, Type.EMAIL, Type.PHONE]),
  target: PropTypes.oneOf([Target.SELF, Target.NEW_TAB, Target.PARENT, Target.FULL]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  customCssClass: PropTypes.string,
};

export const ExternalLinkList = ({ externalLinks, target, customCssClass }) => (
  <ul className={customCssClass}>
    {externalLinks.map(link => (
      <li key={link.id} className={styles.link}>
        <ExternalLink url={link.url} target={target}>
          {link.name}
        </ExternalLink>
      </li>
    ))}
  </ul>
);

ExternalLinkList.propTypes = {
  externalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.oneOfType([PropTypes.instanceOf(URL), PropTypes.string]).isRequired,
    }),
  ).isRequired,
  target: PropTypes.oneOf([Target.SELF, Target.NEW_TAB, Target.PARENT, Target.FULL]),
  customCssClass: PropTypes.string,
};

export default ExternalLink;
