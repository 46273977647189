import PropTypes from "prop-types";
import { useTranslate } from "../../../../../language/i18n";
import SelectField from "../../../SelectField/SelectField";
import styles from "./DeliveryMethods.module.scss";

const DeliveryMethods = ({ options, selectedId, onSelect }) => {
  const translate = useTranslate();

  return (
    <div className={styles.deliveryMethods}>
      <SelectField
        label={translate("DELIVERY_METHOD")}
        selectedId={selectedId}
        options={options}
        customSettings={{ display: "name" }}
        onSelect={onSelect}
        customCssClass={styles.selectField}
      />
    </div>
  );
};

DeliveryMethods.propTypes = {
  options: PropTypes.array,
  selectedId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};
export default DeliveryMethods;
