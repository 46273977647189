import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../config/settings";
import setQueryFilters from "../utils/setQueryFilters";
import defaultRestOptions from "./utils/defaultRestOptions";

async function getResourceGroup(id, filters = {}) {
  if (!id) return;

  const url = api.resourceGroup.resources(id);
  if (filters) setQueryFilters(filters, url);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default {
  getResourceGroup,
};
