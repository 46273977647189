import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";

const InventoryModal = ({ visible, onClose, onSubmit }) => {
  const translate = useTranslate();

  return (
    <ConfirmModal
      title={translate(`${visible}_INVENTORY`)}
      text={translate(`${visible}_INVENTORY_DESCRIPTION`)}
      visible={visible}
      cancel={onClose}
      submit={onSubmit}
    ></ConfirmModal>
  );
};

InventoryModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  action: PropType.string,
  onSubmit: PropType.func,
};

export default InventoryModal;
