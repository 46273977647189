import { Button, Form } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslate } from "../../language/i18n";
import clientService from "../../services/client/clientService";
import orderService from "../../services/order/orderService";
import Checkbox from "../shared/Checkbox/Checkbox.jsx";
import EmptyList from "../shared/EmptyList/EmptyList";
import styles from "./AccessoryList.module.scss";

function AccessoryList({ order, data, setData, onNext }) {
  const currentActiveStep = useSelector(state => state.appData.currentStep);
  const translate = useTranslate();
  const [accessoryList, setAccessoryList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (data.product?.id) {
      clientService
        .getHolding({ clientId: order.receiver.id, inventoryId: encodeURIComponent(data.product.id) })
        .then(response => setAccessoryList(response.data.accessories));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.receiver.id]);

  const clickHandler = row => {
    const rows = data.accessories || [];
    const exists = rows.find(item => item.id === row.id);
    const accessories = exists ? rows.filter(accessory => accessory.id !== row.id) : [...rows, row];

    setData({ accessories });
  };

  const toggleAll = () => {
    const allChecked = data?.accessories?.length === accessoryList.length;
    setData({ accessories: allChecked ? [] : accessoryList });
  };

  const submitHandler = async () => {
    setLoading(true);
    const dataToSend = { ...data };
    dataToSend.currentStep = currentActiveStep;
    const response = await orderService.updateContent(params.content, dataToSend);

    setLoading(false);

    if (response.isOk()) {
      onNext(response.data);
    }
  };

  const ProductList = () => (
    <>
      <div className={styles.checkboxListHeader}>
        <div className={classNames(styles.headerCheck)}>
          <Checkbox
            onChange={toggleAll}
            checked={data?.accessories && data.accessories.length === accessoryList?.length}
          />
        </div>
        <p className={classNames(styles.header, styles.name)}>{translate("PRODUCT_NAME")}</p>
        <p className={classNames(styles.header, styles.inventory)}>{translate("INVENTORY_NUMBER_SHORT")}</p>
        <p className={classNames(styles.header, styles.product)}>{translate("PRODUCT_NUMBER_SHORT")}</p>
      </div>
      <section className={styles.checkboxList}>
        {accessoryList &&
          accessoryList.map(row => {
            const isSelected = data?.accessories?.some(item => item?.id === row.id);

            return (
              <Fragment key={row.id}>
                <div className={classNames(styles.checkWrapper, isSelected && styles.selected)}>
                  <Checkbox
                    id={row.id}
                    name="aid"
                    onChange={() => clickHandler(row)}
                    customCssClass={styles.check}
                    checked={isSelected}
                  />
                </div>
                <p className={classNames(styles.description, isSelected && styles.selected)}>{row.description}</p>

                <p className={classNames(styles.inventoryNo, isSelected && styles.selected)}>
                  <span className={styles.lineTitle}>{translate("INVENTORY_NUMBER_SHORT")}: </span>
                  {row.inventoryNumber}
                </p>

                <p className={classNames(styles.productNo, isSelected && styles.selected)}>
                  <span className={styles.lineTitle}>{translate("PRODUCT_NUMBER_SHORT")}: </span>
                  {row.productNumber}
                </p>
              </Fragment>
            );
          })}
      </section>
    </>
  );

  const EmptySection = () => (
    <>
      <header className={styles.emptyHeader}>{translate("ACCESSORIES_FOR_CHOSEN_AID")}</header>
      <EmptyList text="ACCESSORIES_MISSING" />
    </>
  );

  return (
    <Form onSubmit={submitHandler}>
      {accessoryList?.length ? <ProductList /> : <EmptySection />}
      <Button type="submit" isLoading={isLoading} customCssClass={styles.submitBtn}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
}

AccessoryList.propTypes = {
  order: PropType.object,
  data: PropType.object,
  setData: PropType.func,
  onNext: PropType.func.isRequired,
};

export default AccessoryList;
