import PropType from "prop-types";
import classNames from "classnames";
import { RadioButton } from "@myloc/myloc-gui";
import styles from "./RadioButtonGroup.module.scss";

const RadioButtonGroup = ({
  label,
  name,
  selected,
  values,
  displayValue,
  idValue,
  onChange,
  disabled,
  customCssClass,
}) => {
  return (
    <div>
      {label && <p id={`${name}-title`}>{label}</p>}
      <div className={classNames(styles.container, customCssClass)} aria-labelledby={`${name}-title`} role="radiogroup">
        {values?.map(value => (
          <RadioButton
            key={value[idValue]}
            name="select-receiver"
            id={value[idValue]}
            label={value[displayValue]}
            checked={value[idValue] === selected}
            onChange={e => onChange(value, e)}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  label: PropType.string,
  name: PropType.string,
  selected: PropType.string,
  values: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      value: PropType.string,
    }),
  ),
  displayValue: PropType.string,
  idValue: PropType.string,
  onChange: PropType.func,
  disabled: PropType.bool,
  customCssClass: PropType.string,
};

RadioButtonGroup.defaultProps = {
  displayValue: "value",
  idValue: "id",
};

export default RadioButtonGroup;
