import { AnyAction } from "redux";
import { CLEAR_REFERENCED_ORDER_STATE, FITTING, REFERENCED_ORDER, STATUS } from "./embeddedVariables";

interface FittingState {
  path: string | null;
  text: string | null;
}

interface EmbeddedState {
  status: string | null;
  referencedOrder: any;
  fitting: FittingState;
}

const initialState: EmbeddedState = {
  status: null,
  referencedOrder: null,
  fitting: { path: null, text: null },
};

export const embeddedReducer = (state: EmbeddedState = initialState, action: AnyAction): EmbeddedState => {
  switch (action.type) {
    case STATUS:
      return {
        ...state,
        status: action.status,
      };
    case REFERENCED_ORDER:
      return {
        ...state,
        referencedOrder: action.referencedOrder,
      };
    case FITTING:
      return {
        ...state,
        fitting: action.fitting,
      };
    case CLEAR_REFERENCED_ORDER_STATE:
      return {
        ...state,
        referencedOrder: initialState.referencedOrder,
      };
    default:
      return state;
  }
};
