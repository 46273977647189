import { Button } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslate } from "../../../language/i18n";
import clientService from "../../../services/client/clientService";
import pages from "../../../utils/pages";
import ContactModal from "../../shared/AddressAndContact/ContactModal/ContactModal";
import { List, ListItem } from "./Components";
import styles from "./ContactList.module.scss";
import DeleteContactModal from "./Modals/DeleteContactModal";
import SetPrimaryContactModal from "./Modals/SetPrimaryContactModal";

function Contact({ receiver, setBreadcrumbs, referrer, fromReceiverMenu }) {
  const translate = useTranslate();
  const queryParams = useParams();
  const [contactModalProps, setContactModalProps] = useState({
    visible: false,
    edit: false,
    selectedContact: null,
  });
  const [deleteModalProps, setDeleteModalProps] = useState({ visible: false, selectedContact: null });
  const [refetchContacts, setRefetchContacts] = useState(false);
  const [contactList, setContactList] = useState([]);
  const hasPrimaryContactSet = contactList?.find(contact => contact?.isPrimaryContact);

  const [contacts, setContacts] = useState([]);
  const [primaryContactProps, setPrimaryContactProps] = useState({
    visible: false,
    selectedPrimaryContact: hasPrimaryContactSet ?? null,
  });

  useEffect(() => {
    clientService.getContact(queryParams.id, null, false).then(response => setContacts(response.data.contacts));
  }, [queryParams.id]);

  useEffect(() => {
    if (refetchContacts) {
      clientService.getContact(queryParams.id, null, false).then(response => setContacts(response.data.contacts));
      setRefetchContacts(false);
    }
  }, [refetchContacts, queryParams.id]);

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([{ text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_CONTACTS.NAME)} ` }]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_CONTACTS.NAME)} ` },
      ]);
    }
  }, [referrer, setBreadcrumbs, translate, fromReceiverMenu]);

  useEffect(() => {
    if (contacts) {
      setContactList(contacts);
    }
  }, [contacts]);

  const handleEditContact = contact => {
    setContactModalProps({ visible: true, edit: true, selectedContact: contact });
  };

  const handleAddNewAddress = () => {
    setContactModalProps({ visible: true, edit: false, selectedContact: null });
  };

  const handleDeleteContact = contact => {
    setDeleteModalProps({ visible: true, selectedContact: contact });
  };

  const handleModalClose = contact => {
    if (contact) {
      const list = [...contactList];
      const listIndex = list.findIndex(element => element.id == contact.id);
      if (listIndex !== -1) {
        list[listIndex] = contact;
      } else {
        list.push(contact);
      }
      setContactList(list);
    }
    setContactModalProps({ visible: false, selectedContact: null });
  };

  return (
    <>
      <List
        title={translate("RECEIVER_DETAIL_PAGE_CONTACT_TITLE")}
        addBtnTitle={translate("NEW_CONTACT")}
        onAddNew={handleAddNewAddress}
      >
        {contactList &&
          contactList.map(contact => (
            <ListItem
              key={contact.id}
              title={contact.relation}
              item={contact}
              onEdit={handleEditContact}
              onDelete={handleDeleteContact}
              isPrimaryContact={contact.isPrimaryContact}
            >
              <span>
                {contact.firstName} {contact.surname}
              </span>
              <span>{contact.phoneNo}</span>
              <span>{contact.email}</span>
            </ListItem>
          ))}
        {contactList?.length > 1 && (
          <div className={styles.primaryButton}>
            <Button
              onClick={() =>
                setPrimaryContactProps({ visible: true, selectedPrimaryContact: hasPrimaryContactSet ?? null })
              }
            >
              {hasPrimaryContactSet != null ? translate("CHANGE_PRIMARY_CONTACT") : translate("CHOOSE_PRIMARY_CONTACT")}
            </Button>
          </div>
        )}
      </List>

      <ContactModal
        userId={receiver?.id}
        hasPrimaryContactSet={hasPrimaryContactSet != null}
        showPrimaryCheckbox={contactList?.length > 1}
        visible={contactModalProps.visible}
        contact={contactModalProps.edit ? contactModalProps.selectedContact : null}
        onClose={handleModalClose}
        setRefetchContacts={setRefetchContacts}
      />
      <DeleteContactModal
        clientId={receiver?.id}
        contactId={deleteModalProps.selectedContact?.id === undefined ? "" : deleteModalProps.selectedContact?.id}
        visible={deleteModalProps.visible}
        setRefetchContacts={setRefetchContacts}
        onClose={() => setDeleteModalProps({ visible: false, selectedContact: null })}
      />
      <SetPrimaryContactModal
        userId={receiver?.id}
        title={hasPrimaryContactSet != null ? translate("CHANGE_PRIMARY_CONTACT") : translate("CHOOSE_PRIMARY_CONTACT")}
        visible={primaryContactProps.visible}
        primaryContact={hasPrimaryContactSet ?? null}
        contacts={contactList}
        setRefetchContacts={setRefetchContacts}
        cancel={() => setPrimaryContactProps({ visible: false, selectedPrimaryContact: null })}
      />
    </>
  );
}

Contact.propTypes = {
  receiver: PropType.object,
  contacts: PropType.array,
  setBreadcrumbs: PropType.func,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
};

export default Contact;
