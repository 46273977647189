import { THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import resourceGroupService from "../../../services/resourceGroupService";
import pages from "../../../utils/pages";
import CheckAuthButton from "../../shared/Buttons/CheckAuthButton";
import Label from "../../shared/Label/Label";
import styles from "../FittingDetailPage.module.scss";
import CloseFittingModal from "../Modals/CloseFittingModal";
import SelectFieldModal from "../Modals/SelectFieldModal";

const FittingInfo = ({ fitting, setBreadcrumbs, embeddedFitting, setFetchFittings }) => {
  const translate = useTranslate();
  const history = useHistory();
  const [action, setAction] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState(fitting?.consultant);
  const [selectedTechnician, setSelectedTechnician] = useState(fitting?.technican);
  const location = useLocation();
  const isCompleted = fitting?.status?.value === 90 || fitting?.status?.value === 99;
  const meetingIsNotPlanned = fitting?.nextMeeting?.status === "BOOKED";

  useEffect(() => {
    setBreadcrumbs([
      {
        text: embeddedFitting?.text
          ? translate(embeddedFitting?.text)
          : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}`,
        link: embeddedFitting?.path ? embeddedFitting?.path : pages.MY_FITTINGS.PATH,
      },
      {
        text: `${translate(pages.FITTING_DETAIL.NAME)} - ${translate(pages.MY_FITTINGS_INFO.NAME)}`,
      },
    ]);
  }, [embeddedFitting, setBreadcrumbs, translate]);

  const handleSubmit = async () => {
    const data = {
      consultantId: selectedConsultant?.id || fitting?.consultant?.id,
      technicianId: selectedTechnician?.id || (selectedTechnician === "remove" ? "" : fitting?.technician?.id),
    };

    if (data.consultantId !== fitting?.consultant?.id || data.technicianId !== fitting?.technician?.id) {
      const response = await orderService.updateFitting(fitting?.id, data);

      if (response.isOk()) {
        setFetchFittings(true);
        setAction("");
      }
    } else {
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    if (action === "CONSULTANT") {
      setSelectedConsultant(fitting?.consultant);
    } else {
      setSelectedTechnician(fitting?.technician);
    }
    setAction("");
  };

  const onSelect = newParticipant => {
    if (action === "CONSULTANT") {
      setSelectedConsultant(newParticipant);
    } else {
      if (fitting?.technician && !newParticipant) {
        setSelectedTechnician("remove");
      } else {
        setSelectedTechnician(newParticipant);
      }
    }
  };

  const getSelectedParticipant = () => {
    if (action === "CONSULTANT") {
      return selectedConsultant?.id || fitting?.consultant?.id;
    } else if (action === "TECHNICIAN") {
      return selectedTechnician?.id || fitting?.technician?.id;
    }
  };

  const getWarningText = () => {
    if (fitting?.nextMeeting) {
      return translate(`${action}_CHANGE_WARNING`);
    }
  };

  const showModal = async action => {
    if (action === "CONSULTANT") {
      const response = await resourceGroupService.getResourceGroup(fitting?.fittingType?.consultantResourceGroup?.id, {
        mainResource: true,
      });
      setOptions(response?.data?.page);
    } else if (action === "TECHNICIAN") {
      const response = await resourceGroupService.getResourceGroup(fitting?.fittingType?.technicianResourceGroup?.id, {
        mainResource: true,
      });
      setOptions(response?.data?.page);
    }
    setAction(action);
  };
  const participantTemplate = useCallback(
    item => (
      <>
        <p>{item.label}</p>
        <p>{item.value}</p>
      </>
    ),
    [],
  );
  const getDateOnly = () => {
    const date = new Date(fitting?.closedDateTime);
    if (date) {
      const fullDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const fullMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

      return `${date.getFullYear()}-${fullMonth}-${fullDate}`;
    }
    return "";
  };

  const onClose = () => {
    setAction(null);
  };

  const onSubmit = async () => {
    const response = await orderService.closeFitting(fitting.id, action);
    if (response.statusCode === 200) {
      setAction(null);
      const fromComponent = location?.state?.fromComponent || location?.state?.referrer?.fromComponent;
      if (fromComponent) {
        if (fromComponent.includes("embedded")) {
          if (fromComponent.includes("admin")) {
            history.push(`${pages.EMBEDDED_MY_FITTINGS.PATH}/?role=admin`);
          } else {
            history.push(pages.EMBEDDED_MY_FITTINGS.PATH);
          }
        } else {
          history.push(fromComponent);
        }
      } else {
        history.push(pages.MY_FITTINGS.PATH);
      }
    }
  };

  if (!fitting) {
    return <></>;
  }

  return (
    <>
      <section>
        <h3 className={styles.title}>{translate("DETAILS")}</h3>
        <section className={styles.split}>
          <div>
            <div className={styles.orderAndcompletedDate}>
              <Label label={translate("ORDERED")} value={fitting?.orderDate} />
              {isCompleted && (
                <Label
                  label={fitting.status.value === 90 ? translate("COMPLETED") : translate("ANNULLED")}
                  value={getDateOnly()}
                />
              )}
            </div>
            <Label label={translate("FITTING_TYPE")} value={fitting?.fittingType?.name} />

            <Label
              label={translate("AID")}
              value={translate(fitting.isNewItem ? "FITTING_OF_NEW_AID" : "FITTING_OF_EXISTING_AID")}
            />

            {!fitting.isNewItem && <Label value={fitting.product?.name} />}

            <Label label={translate("PRODUCT_NUMBER_SHORT")} value={fitting?.product?.productNumber} inline hideEmpty />
            <Label
              label={translate("INVENTORY_NUMBER_SHORT")}
              value={fitting?.product?.inventoryNumber}
              inline
              hideEmpty
            />
          </div>

          <div>
            <Label
              label={translate("PRESCRIBER")}
              value={[
                `${fitting?.prescriber?.firstName} ${fitting?.prescriber?.surname}`,
                fitting?.prescriber?.phoneNo,
                fitting?.prescriber?.email,
              ]}
            />
            <div className={styles.participantInfo}>
              <div className={styles.row}>
                <span className={styles.textTitle}>{translate("CONSULTANT")}:</span>
                {!meetingIsNotPlanned && !isCompleted && (
                  <CheckAuthButton
                    theme={THEME.PLAIN_PRIMARY}
                    onClick={() => showModal("CONSULTANT")}
                    title={translate("CHOOSE_CONSULTANT")}
                    authId={"FSP_UpdateWebOrderFitting"}
                  />
                )}
              </div>
              <div className={classNames(styles.participantText, meetingIsNotPlanned && styles.emptyBlock)}>
                {!meetingIsNotPlanned && (
                  <>
                    <span className={styles.text}>{`${fitting?.consultant ? fitting?.consultant?.firstName : ""} ${
                      fitting?.consultant ? fitting?.consultant?.surname : ""
                    }`}</span>
                    {fitting?.consultant?.phoneNo && (
                      <span className={styles.text}>{fitting?.consultant?.phoneNo}</span>
                    )}
                    <span className={styles.text}>{fitting?.consultant?.email}</span>
                  </>
                )}
              </div>
            </div>
            <div className={styles.participantInfo}>
              <div className={styles.row}>
                <span className={styles.textTitle}>{translate("TECHNICIAN")}:</span>
                {!meetingIsNotPlanned && !isCompleted && (
                  <CheckAuthButton
                    theme={THEME.PLAIN_PRIMARY}
                    onClick={() => showModal("TECHNICIAN")}
                    title={translate("CHOOSE_TECHNICIAN")}
                    authId={"FSP_UpdateWebOrderFitting"}
                  />
                )}
              </div>
              <div className={classNames(styles.participantText, meetingIsNotPlanned && styles.emptyBlock)}>
                {!meetingIsNotPlanned && (
                  <>
                    <span className={styles.text}>{`${fitting?.technician ? fitting?.technician?.firstName : ""} ${
                      fitting?.technician ? fitting?.technician?.surname : ""
                    }`}</span>
                    <span className={styles.text}>{fitting?.technician?.phoneNo}</span>
                    <span className={styles.text}>{fitting?.technician?.email}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className={styles.description}>
          <Label
            label={translate("DESCRIPTION")}
            value={fitting?.isNewItem ? fitting.product?.description : fitting?.text}
          />
        </section>
        {!isCompleted && (
          <div className={styles.buttons}>
            {(!fitting?.nextMeeting || fitting?.nextMeeting?.status !== "BOOKED") && (
              <CheckAuthButton
                theme={THEME.SECONDARY}
                onClick={() => showModal("COMPLETE")}
                title={translate("COMPLETE_FITTING")}
                authId={"FSP_CompleteWebOrderFitting"}
              />
            )}
            <CheckAuthButton
              theme={THEME.SECONDARY}
              onClick={() => showModal("CANCEL")}
              title={translate("CANCEL_FITTING")}
              authId={"FSP_CancelWebOrderFitting"}
            />
          </div>
        )}

        <CloseFittingModal
          visible={action === "COMPLETE" || action === "CANCEL"}
          action={action}
          onSubmit={onSubmit}
          onClose={() => onClose()}
        />
      </section>

      <SelectFieldModal
        visible={action === "CONSULTANT" || action === "TECHNICIAN"}
        title={action === "CONSULTANT" ? translate("CHOOSE_CONSULTANT") : translate("CHOOSE_TECHNICIAN")}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        selectedId={getSelectedParticipant()}
        onSelect={onSelect}
        options={options}
        template={participantTemplate}
        display={"label"}
        warningText={getWarningText()}
        required={
          action === "CONSULTANT"
            ? fitting?.fittingType?.consultantMandatory
            : fitting?.fittingType?.technicianMandatory
        }
      />
    </>
  );
};

FittingInfo.propTypes = {
  fitting: PropType.object,
  setBreadcrumbs: PropType.func,
  setFetchFittings: PropType.func,
  embeddedFitting: PropType.object,
};

export default FittingInfo;
