import { InputField } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import classNames from "classnames";
import PropType from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import taskService from "../../../services/taskService";
import { STATUS, TASK_TYPES } from "../../../utils/constants";
import pages from "../../../utils/pages";
import { useMobile } from "../../../utils/viewport";
import FacilitySelector from "../../shared/FacilitySelector/FacilitySelector";
import Label from "../../shared/Label/Label";
import Page from "../../shared/Page/Page";
import Paginate from "../../shared/Pagination/Paginate";
import RadioButtonGroup from "../../shared/RadioButton/RadioButtonGroup";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import TopFilter from "../../shared/TopFilter/TopFilter";
import styles from "./Tasks.module.scss";

const FILTERS = ["executionDateFrom", "executionDateTo"];

const DEFAULT_STATUS = STATUS.ACTIVE;

const getSelectedFiltersFromParams = (params, availableFilters) => {
  if (!availableFilters) return;

  return FILTERS.reduce((filters, key) => {
    return {
      ...filters,
      [key]: params.get(key) || "",
    };
  }, {});
};

const Tasks = ({ setBreadcrumbs, taskTypes, isBaseStorageWorkList }) => {
  const history = useHistory();
  const translate = useTranslate();
  const isMobile = useMobile();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const defaultFilterValues = () =>
    FILTERS.reduce((c, key) => {
      return { ...c, [key]: "" };
    }, {});

  const [selectedFilters, setSelectedFilters] = useState(getSelectedFiltersFromParams(params, defaultFilterValues()));
  const [filters, setFilters] = useState({ ...selectedFilters });
  const [freeTextSearch, setFreeTextSearch] = useState(params.get("q") || "");
  const [status, setStatus] = useState(params.get("status") || DEFAULT_STATUS);
  const userFacility = useSelector(state => state.appData?.user?.facility);

  const filterStatuses = useMemo(
    () => [
      { id: STATUS.ACTIVE, value: translate("ACTIVE") },
      { id: STATUS.STARTED, value: translate("STARTED") },
      { id: STATUS.REPORTED, value: translate("REPORTED") },
      { id: STATUS.ALL, value: translate("ALL") },
    ],
    [translate],
  );

  const getBaseStorage = () => {
    if (!isBaseStorageWorkList) return null;

    const paramStorage = params.get("storage");
    if (paramStorage) return { id: paramStorage };
    if (userFacility?.category?.value > 0) return { id: userFacility?.id };
  };

  const [baseStorage, setBaseStorage] = useState(getBaseStorage());

  const filterSearch = () => {
    setFilters({ ...selectedFilters });
    setFreeTextSearch(params.get("q") || "");
  };

  const onFilterChange = (key, value) => {
    setSelectedFilters({ ...selectedFilters, [key]: value });
  };

  const handleStatusChange = async selected => {
    setStatus(selected.id);
  };

  useEffect(() => {
    if (!isBaseStorageWorkList) return;

    if (!baseStorage?.id) {
      params.delete("storage");
    } else if (params.get("storage") !== baseStorage.id) {
      params.set("storage", baseStorage.id);
    }
    if (status !== params.get("status")) {
      params.set("status", status);
    } else if (!params.get("status")) {
      params.set("status", DEFAULT_STATUS);
    }
    history.replace(pages.BASE_STORAGES.PATH, params);
  }, [userFacility, baseStorage, status, history, isBaseStorageWorkList, params]);

  const getStatus = useCallback(() => {
    switch (status) {
      case STATUS.ACTIVE:
        return "30:>=;50:<";
      case STATUS.STARTED:
        return "50:=";
      case STATUS.REPORTED:
        return "90:>=";
      default:
        return "20:>=";
    }
  }, [status]);

  useEffect(() => {
    setBreadcrumbs?.([{ text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_TASKS.NAME)}` }]);
  }, [setBreadcrumbs, translate]);

  const onInputClear = () => {
    setSelectedFilters(defaultFilterValues());
  };

  return (
    <Page
      customCssClass={isBaseStorageWorkList ? styles.isBaseStorageWorkList : styles.page}
      title={translate(pages.MY_TASKS.NAME)}
    >
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setTasks}
        provider={useCallback(
          filter =>
            taskService.getTasks({
              freeTextSearch,
              ...filters,
              ...filter,
              taskTypes,
              baseStorage: baseStorage ? decodeURIComponent(baseStorage.id) : null,
              status: baseStorage ? getStatus() : null,
              useResponsible: baseStorage == null,
              sort: "status:desc,plannedDateTime:asc",
            }),
          [filters, freeTextSearch, taskTypes, baseStorage, getStatus],
        )}
      >
        <div className={isBaseStorageWorkList ? styles.includeFacilitySelector : null}>
          {isBaseStorageWorkList && <FacilitySelector preSelected={baseStorage} onSelect={setBaseStorage} />}
          <TopFilter
            onSubmit={filterSearch}
            filters={selectedFilters}
            setFilters={setSelectedFilters}
            fields={FILTERS}
            label={translate("SEARCH_FOR_TASK")}
          >
            <div className={styles.searchGroup}>
              <InputField
                type="date"
                label={translate("FROM_DATE")}
                labelAlwaysTop
                value={selectedFilters?.executionDateFrom}
                onChange={option => onFilterChange("executionDateFrom", option?.target?.value)}
                onClear={onInputClear}
              />
              <InputField
                type="date"
                label={translate("TO_DATE")}
                labelAlwaysTop
                value={selectedFilters?.executionDateTo}
                onChange={option => onFilterChange("executionDateTo", option?.target?.value)}
                onClear={onInputClear}
              />
            </div>
          </TopFilter>
        </div>

        {isLoading ? (
          <LoadingSpinner title="TASKS_LOADING" />
        ) : (
          <ul className={styles.wrapper}>
            {isBaseStorageWorkList && (
              <section>
                <RadioButtonGroup
                  values={filterStatuses}
                  onChange={handleStatusChange}
                  selected={status}
                  customCssClass={styles.filter}
                />
              </section>
            )}
            {!!tasks.length &&
              tasks?.map(task =>
                isMobile ? (
                  <li className={styles.card} key={task.id}>
                    <div className={classNames(styles.bold, styles.descriptionAndStatus)}>
                      <Link
                        to={{
                          pathname: generatePath(
                            isBaseStorageWorkList
                              ? `${pages.BASE_STORAGE_WORK_LIST.PATH}/${task.id}`
                              : `${pages.MY_TASKS.PATH}/${task.id}`,
                          ),
                          state: {
                            path: `${window.location.pathname}${window.location.search}`,
                            text: isBaseStorageWorkList
                              ? `${translate(pages.BASE_STORAGES.NAME)} - ${translate(
                                  pages.BASE_STORAGE_WORK_LIST.NAME,
                                )}`
                              : "",
                          },
                        }}
                      >
                        {task.description}
                      </Link>
                      <div className={classNames(styles.status, styles[`status${task.status}`], styles.right)}>
                        {translate(`TASK_STATUS_${task.status}`)}
                      </div>
                    </div>
                    <div>{task.plannedDateTime}</div>
                    <Label label={translate("ORDER_NUMBER")} value={task.referenceOrderLine?.orderNumber} inline />
                    <div className={styles.item}>
                      {task.fromWarehouse && task.type != "CA" && (
                        <>
                          <div className={styles.label}>{translate("FROM")}:</div>
                          <div className={styles.grow}>
                            <div>{`${task.fromWarehouse?.value} - ${task.fromWarehouse?.label}`}</div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={styles.item}>
                      <div className={styles.label}>{translate("TO")}:</div>
                      <div className={styles.grow}>
                        {task.type == TASK_TYPES.BSM ? (
                          `${task.to?.label} - ${task.to?.value}`
                        ) : (
                          <>
                            <div>{`${task.to?.firstName} ${task.to?.surname}`}</div>
                            <div>{task.to?.identityNumber}</div>
                          </>
                        )}
                      </div>
                    </div>
                  </li>
                ) : (
                  <li className={styles.card} key={task.id}>
                    <div className={styles.header}>
                      <div className={styles.bold}>
                        <Link
                          to={{
                            pathname: generatePath(
                              isBaseStorageWorkList
                                ? `${pages.BASE_STORAGE_WORK_LIST.PATH}/${task.id}`
                                : `${pages.MY_TASKS.PATH}/${task.id}`,
                            ),
                            state: {
                              path: `${window.location.pathname}${window.location.search}`,
                              text: isBaseStorageWorkList
                                ? `${translate(pages.BASE_STORAGES.NAME)} - ${translate(
                                    pages.BASE_STORAGE_WORK_LIST.NAME,
                                  )}`
                                : "",
                            },
                          }}
                        >
                          {task.description}
                        </Link>
                      </div>
                      <div className={styles.row}>
                        <Label label={translate("ORDER_NUMBER")} value={task.referenceOrderLine?.orderNumber} inline />
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div>{task.plannedDateTime}</div>
                      <div className={classNames(styles.status, styles[`status${task.status}`])}>
                        {translate(`TASK_STATUS_${task.status}`)}
                      </div>
                    </div>

                    <div className={styles.item}>
                      {task.fromWarehouse && task.type != TASK_TYPES.CA && (
                        <>
                          <div className={styles.label}>{translate("FROM")}:</div>
                          <div className={styles.grow}>
                            <div>{`${task.fromWarehouse?.value} - ${task.fromWarehouse?.label}`}</div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={styles.item}>
                      <div className={styles.label}>{translate("TO")}:</div>
                      <div className={styles.grow}>
                        {task.type == TASK_TYPES.BSM ? (
                          `${task.to?.label} - ${task.to?.value}`
                        ) : (
                          <>
                            <div>{`${task.to?.firstName} ${task.to?.surname}`}</div>
                            <div>{task.to?.identityNumber}</div>
                          </>
                        )}
                      </div>
                    </div>
                  </li>
                ),
              )}
          </ul>
        )}
      </Paginate>
    </Page>
  );
};

Tasks.propTypes = {
  setBreadcrumbs: PropType.func,
  taskTypes: PropType.array,
  isBaseStorageWorkList: PropType.bool,
};

export default Tasks;
