import PropType from "prop-types";
import { useState, useEffect, useCallback } from "react";

import styles from "./TabMenu.module.scss";
import TabTitle from "./TabTitle";
import TabContent from "./Content/TabContent";
import classNames from "classnames";

const TabMenu = ({ categories, request, sortContent, customCssClass }) => {
  const [activeTab, setActiveTab] = useState();
  const [content, setContent] = useState();
  const [groupedContent, setGroupedContent] = useState();

  const fetchLinks = useCallback(
    async category => {
      const response = await request(category);

      if (response?.data?.page?.length) {
        if (response.data.page[0].hyperlinks?.length) {
          setContent(curr => {
            if (!curr) return response.data.page;

            const found = curr.find(pageContent => pageContent.category?.value === category);
            if (found) {
              const index = curr.indexOf(found);
              if (index !== -1) {
                curr.splice(index, 1, response.data.page[0]);
                return sortContent([...curr]);
              }
            }

            return sortContent([...curr, response.data.page[0]]);
          });
        }
      }
    },
    [request, sortContent],
  );

  useEffect(() => {
    categories?.forEach(fetchLinks);
  }, [categories, request, fetchLinks]);

  useEffect(() => {
    if (content?.length) {
      setActiveTab(content[0].category?.value);
    }
  }, [content]);

  const onChange = (e, value) => {
    e.preventDefault();
    if (activeTab !== value) {
      setActiveTab(value);
    }
  };

  useEffect(() => {
    if (content?.length) {
      setGroupedContent(
        content.reduce((accumulator, pageContent) => {
          const pageContents = accumulator[pageContent.category?.value] ?? [];
          pageContents.push(pageContent);

          return { ...accumulator, [pageContent.category?.value]: pageContents };
        }, {}),
      );
    }
  }, [content]);

  if (!content?.length) return null;

  return (
    <div className={classNames(styles.tabContainer, customCssClass)}>
      <div className={styles.tabMenu}>
        <ul className={styles.titles}>
          {!!content?.length && (
            <>
              <div className={styles.tabTitles}>
                {content.map(content => (
                  <TabTitle
                    key={content.id}
                    displayTitle={content.hyperlinkTitle}
                    category={content.category}
                    onChange={onChange}
                    active={activeTab === content.category?.value}
                  />
                ))}
              </div>
              <div>
                {activeTab &&
                  groupedContent[activeTab]?.map(content => (
                    <TabContent key={content.id} links={content.hyperlinks} type={content.category?.value} />
                  ))}
              </div>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

TabMenu.propTypes = {
  request: PropType.func,
  sortContent: PropType.func,
  categories: PropType.array,
  customCssClass: PropType.string,
};
export default TabMenu;
