import PropType from "prop-types";
import { useSelector } from "react-redux";
import { Button } from "@myloc/myloc-gui";

const CheckAuthButton = ({ theme, onClick, title, authId }) => {
  const authorizationList = useSelector(state => state.session?.authorizationList);
  const functionList = authorizationList?.filter(item => item.type === "FUNCTION" && item.id === authId);

  return (
    <>
      {!!functionList?.length && (
        <Button theme={theme} onClick={onClick}>
          {title}
        </Button>
      )}
    </>
  );
};

CheckAuthButton.propTypes = {
  theme: PropType.string,
  onClick: PropType.func,
  title: PropType.string,
  authId: PropType.string,
};
export default CheckAuthButton;
