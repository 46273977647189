import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { useEffect, useState } from "react";

import styles from "./Reference.module.scss";
import SelectField from "../../shared/SelectField/SelectField";
import { InputField, Form, Button } from "@myloc/myloc-gui";
import deviationService from "../../../services/deviation/deviationService";

function Reference({ data, setData, onNext }) {
  const translate = useTranslate();

  const init = { id: "0", value: translate("NO_REFERENCE") };
  const [references, setReferences] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const referenceRequired = data?.reference && data?.reference?.id !== init.id;

  useEffect(() => {
    deviationService.getReferences(data?.category).then(response => {
      setReferences([{ id: "0", label: translate("NO_REFERENCE") }, ...(response?.data || [])]);
    });
  }, [data.category, translate]);

  const selectHandler = reference => {
    setData({
      reference: reference || init,
      code: "",
    });
    setErrorMessage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    const valid = await validateCode();

    if (referenceRequired && !valid) {
      setLoading(false);
      return;
    }

    if ((referenceRequired && valid) || !referenceRequired) {
      await onNext(data);
      setLoading(false);
    }
  };

  const validateCode = async () => {
    if (!referenceRequired) {
      setErrorMessage("");
      return;
    }

    const response = await deviationService.postValidation(data.category, {
      id: data.reference?.id,
      value: data.code,
    });

    if (!response.isOk()) {
      setErrorMessage(translate("INVALID_REFERENCE_NUMBER"));
      return false;
    }

    setErrorMessage(null);
    return true;
  };

  return (
    <Form className={styles.content} onSubmit={handleSubmit}>
      <SelectField
        options={references}
        onSelect={selectHandler}
        selectedId={data.reference?.id}
        label={translate("REFERENCE_TO_OBJECT")}
        customSettings={{ display: "label" }}
      />
      {referenceRequired && (
        <InputField
          label={translate("SPECIFY")}
          value={data?.code}
          onChange={event => {
            setData({ code: event.target.value });
          }}
          customInvalidMessage={errorMessage}
        />
      )}
      <Button type="submit" isLoading={isLoading} customCssClass={styles.submitBtn} onClick={handleSubmit}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
}

Reference.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  category: PropTypes.object,
  values: PropTypes.array,
  onNext: PropTypes.func.isRequired,
};

export default Reference;
