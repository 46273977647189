import PropType from "prop-types";
import { useLayoutEffect } from "react";

import styles from "./SideMenu.module.scss";

const SideMenu = ({ children, id }) => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = originalStyle);
  }, []);

  return (
    <div id={id} className={styles.sideMenu}>
      {children}
    </div>
  );
};

SideMenu.propTypes = {
  children: PropType.node,
  id: PropType.string,
};

export default SideMenu;
