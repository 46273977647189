import { AnyAction } from "redux";
import {
  LOADING,
  LOADING_FREEZE,
  REGISTRATION_COMPLETED,
  REGISTRATION_RESET,
  REGISTRATION_SUCCESS,
} from "./commonVariables";

interface CommonState {
  loading: boolean;
  loadingFreeze: boolean;
  registrationSuccess: boolean;
  registrationCompleted: boolean;
}

const initialState: CommonState = {
  loading: false,
  loadingFreeze: false,
  registrationSuccess: false,
  registrationCompleted: false,
};

const commonReducer = (state: CommonState = initialState, action: AnyAction): CommonState => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case LOADING_FREEZE:
      return {
        ...state,
        loadingFreeze: action.loadingFreeze,
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationSuccess: true,
      };
    case REGISTRATION_COMPLETED:
      return {
        ...state,
        registrationCompleted: true,
      };
    case REGISTRATION_RESET:
      return {
        ...state,
        registrationCompleted: false,
        registrationSuccess: false,
      };
    default:
      return state;
  }
};

export default commonReducer;
