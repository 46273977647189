import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import LogisticsConfirmModal from "../../../shared/Modals/LogisticsConfirmModal";

const DeleteCommentModal = ({ visible, onCancel, onSubmit }) => {
  const translate = useTranslate();

  return (
    <LogisticsConfirmModal
      title={translate("DELETE_COMMENT")}
      text={translate("DELETE_COMMENT_CONFIRMATION")}
      visible={visible}
      cancel={onCancel}
      small={false}
      submit={onSubmit}
      onClose={onCancel}
    />
  );
};

DeleteCommentModal.propTypes = {
  visible: PropType.bool,
  onCancel: PropType.func,
  onSubmit: PropType.func,
};

export default DeleteCommentModal;
