import { Button, InputField, THEME } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useDebounce from "../../../hooks/useDebounce";
import { useTranslate } from "../../../language/i18n";
import inventoryService from "../../../services/inventory/inventoryService";
import pages from "../../../utils/pages";
import { useMobile } from "../../../utils/viewport";
import FacilitySelector from "../../shared/FacilitySelector/FacilitySelector";
import Page from "../../shared/Page/Page";
import Paginate from "../../shared/Pagination/Paginate";
import RadioButtonGroup from "../../shared/RadioButton/RadioButtonGroup";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import styles from "./Inventory.module.scss";
import InventoryDetails from "./InventoryDetails";
import InventoryModal from "./Modal/InventoryModal";

const Inventory = () => {
  const translate = useTranslate();
  const history = useHistory();
  const isMobile = useMobile();
  const params = new URLSearchParams(window.location.search);
  const userFacility = useSelector(state => state?.appData?.user?.facility);

  const getBaseStorage = () => {
    const paramStorage = params.get("storage");
    if (paramStorage) return { id: paramStorage };
    else if (userFacility?.category?.value > 0) return { id: userFacility?.id };
    else return null;
  };

  const [baseStorage, setBaseStorage] = useState(getBaseStorage());
  const [data, setData] = useState([]);
  const [activeInventory, setActiveInventory] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [search, setSearch] = useState(params.get("q") || "");
  const debouncedSearchValue = useDebounce(search);
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState("");
  const [storageParams, setStorageParams] = useState(true);
  const [waitWithUpdate, setWaitWithUpdate] = useState(false);

  const statusValues = [
    { id: "ALL", value: translate("ALL") },
    { id: "NOT_REPORTED", value: translate("NOT_REPORTED") },
    { id: "REPORTED", value: translate("REPORTED") },
  ];

  const [status, setStatus] = useState({ id: "ALL", value: translate("ALL") });

  const checkInventory = useCallback(async () => {
    if (baseStorage?.id) {
      const response = await inventoryService.getInventory({ baseStorageId: decodeURIComponent(baseStorage.id) });
      if (response.data?.page?.length) {
        setActiveInventory(response.data.page[0]);
      } else {
        setActiveInventory(null);
      }
    }
  }, [baseStorage]);

  useEffect(() => {
    checkInventory();
  }, [checkInventory]);

  useEffect(() => {
    if (data?.length > 0) {
      setWaitWithUpdate(false);
    }
  }, [data]);

  const getStatus = useCallback(() => {
    switch (status.id) {
      case "NOT_REPORTED":
        return "60:<";
      case "REPORTED":
        return "60:>=;80:<=";
      default:
        return "";
    }
  }, [status.id]);

  const warehouseValue = activeInventory?.warehouse?.value;
  const hasActiveInventory = !!activeInventory;

  const getProvider = useCallback(
    filter => {
      if (baseStorage?.id) {
        if (!hasActiveInventory || baseStorage?.value !== warehouseValue) {
          setShowErrorMessage(false);
        } else {
          if (!showErrorMessage) setShowErrorMessage(true);
          return inventoryService.getInventoryLines({
            status: getStatus(),
            baseStorageId: decodeURIComponent(baseStorage.id),
            searchValue: debouncedSearchValue,
            isBasicInventory: true,
            ...filter,
          });
        }
      }
    },
    [baseStorage, hasActiveInventory, warehouseValue, showErrorMessage, debouncedSearchValue, getStatus],
  );

  const createInventory = async () => {
    setIsLoading(true);
    const response = await inventoryService.createInventory(baseStorage?.id);
    if (response?.statusCode === 201) {
      setActiveInventory(response.data);
      inventoryService.getInventoryLines({
        status: getStatus(),
        baseStorageId: decodeURIComponent(baseStorage.id),
        searchValue: debouncedSearchValue,
      });
    }
    setIsLoading(false);
  };

  const onChange = status => {
    setStatus(status);
  };
  const activeInventoryStatus = activeInventory?.status;

  const updateInventoryLine = useCallback(
    async (value, inventoryLine) => {
      if (!waitWithUpdate) {
        setWaitWithUpdate(true);
        const response = await inventoryService.updateInventoryLine(
          inventoryLine.id,
          inventoryLine.fetchedTimestamp,
          value,
        );
        if (response?.statusCode === 200) {
          if (activeInventoryStatus !== response?.data?.inventory?.status) {
            setActiveInventory(response.data.inventory);
          }
          setData(cur => {
            let newList = [...cur];
            const index = newList.indexOf(inventoryLine);
            if (index === -1) return cur;
            newList.splice(index, 1, response?.data?.inventoryLine);
            return newList;
          });
        }
      }
    },
    [activeInventoryStatus, waitWithUpdate],
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (storageParams) {
      if (baseStorage?.id && params.get("storage") !== baseStorage.id) {
        params.set("storage", baseStorage.id);
      } else if (!params.get("storage")) {
        if (userFacility?.category?.value === "2") {
          params.set("storage", userFacility?.id);
        }
      }

      if (!debouncedSearchValue) {
        params.delete("q");
      } else if (debouncedSearchValue !== params.get("q")) {
        params.set("q", debouncedSearchValue);
      }
    } else {
      params.delete("storage");
    }

    history.replace(pages.INVENTORIES.PATH, params);
  }, [userFacility, baseStorage, history, debouncedSearchValue, storageParams]);

  const handleChange = useCallback(
    selected => {
      if (selected) {
        if (selected.value === baseStorage?.value) return;

        setSearch("");
        setStorageParams(true);
        setBaseStorage(selected);
        setStatus({ id: "ALL", value: translate("ALL") });
      } else {
        setSearch("");
        setStorageParams(false);
        setBaseStorage(null);
        setShowErrorMessage(false);
        setActiveInventory(null);
      }
    },
    [baseStorage, translate],
  );

  const onSubmit = async () => {
    let response;
    if (action === "ANNUL") {
      response = await inventoryService.deleteInventory(activeInventory?.id);
    } else {
      response = await inventoryService.confirmInventory(activeInventory?.id, action);
    }
    if (response.statusCode === 204) {
      setActiveInventory(null);
      setSearch("");
      setStatus({ id: "ALL", value: translate("ALL") });
    }
    setAction(null);
  };

  const onClose = () => {
    setAction(null);
  };
  const breadcrumbs = [
    {
      text: translate(pages.BASE_STORAGES.NAME),
    },
  ];

  return (
    <Page breadcrumbs={breadcrumbs} customHeaderCssClass={styles.headerSection}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setData}
        provider={getProvider}
        errorMessage={{ show: showErrorMessage }}
        skipSearch={!hasActiveInventory || baseStorage?.value !== warehouseValue}
      >
        <div className={styles.title}>
          <h2 className={styles.contentTitle}>
            {translate("MENU_BASE_STORAGE_INVENTORY")}
            {!!activeInventory && (
              <span className={styles.inventoryStatus}>
                {" "}
                - {activeInventory.inventoryId} ({translate(`INVENTORY_STATUS_${activeInventory.status}`)})
              </span>
            )}
          </h2>
          {activeInventory && (
            <div className={styles.buttons}>
              <div className={styles.button}>
                <Button theme={THEME.SECONDARY} onClick={() => setAction("ANNUL")}>
                  {isMobile ? translate("ANNUL") : translate("ANNUL_INVENTORY")}
                </Button>
              </div>
              <div>
                <Button onClick={() => setAction("CONFIRM")} disabled={activeInventory.status < 50}>
                  {isMobile ? translate("CONFIRM_INVENTORY_SHORT") : translate("CONFIRM_INVENTORY")}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={classNames(styles.search, !activeInventory ? styles.center : undefined)}>
          <FacilitySelector
            preSelected={baseStorage}
            onSelect={handleChange}
            customCssClass={styles.baseStorageSelect}
          />
          {!activeInventory ? (
            <div className={styles.button}>
              <Button isLoading={isLoading} onClick={() => createInventory()} disabled={!baseStorage?.id}>
                {translate("START_INVENTORY")}
              </Button>
            </div>
          ) : (
            <InputField
              customCssClass={styles.noErrorField}
              onChange={event => setSearch(event.target.value)}
              label={translate("SEARCH")}
              value={search}
            />
          )}
        </div>
        {activeInventory && (
          <>
            <div className={styles.bottomBorder}>
              <RadioButtonGroup
                values={statusValues}
                onChange={onChange}
                selected={status?.id}
                customCssClass={styles.radioButtons}
              />
            </div>
            {isLoading ? (
              <LoadingSpinner title="PRODUCTS_LOADING" />
            ) : (
              !!data?.length &&
              data?.map(inventoryLine => {
                return (
                  <InventoryDetails
                    key={inventoryLine.id}
                    inventoryLine={inventoryLine}
                    updateInventoryLine={updateInventoryLine}
                  />
                );
              })
            )}
          </>
        )}

        <InventoryModal visible={action ? action : false} onSubmit={onSubmit} onClose={() => onClose()} />
      </Paginate>
    </Page>
  );
};
export default Inventory;
