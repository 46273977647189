import PropTypes from "prop-types";
import { useTranslate } from "../../language/i18n";
import { AdditionalInfoSection, AddressSection, InfoSection, Row } from "../shared/OrderInfoSections/OrderInfoSections";
import { DefaultItemText } from "../shared/Text/TextComponents";

function OrderInfo({
  orderData: { accessories, address, contact, functionAccessories, form, product, deliveryMethod },
}) {
  const translate = useTranslate();

  const productInfo = product => (
    <InfoSection title={translate("PRODUCT")}>
      <Row content={product.name} />
      <Row title={translate("PRODUCT_NUMBER_SHORT")} content={product.productNumber} />
      <Row title={translate("QUANTITY")} content={`${product.quantity} ${product.unit}`} />
    </InfoSection>
  );

  const showAccessories = (functionAccessories, accessories) => (
    <InfoSection title={translate("ACCESSORIES")}>
      <>
        {functionAccessories?.map(item => (
          <Row
            key={item.id}
            title={item.label}
            content={item.values.map(val => (
              <span key={val.id}>
                {val.value} {val.default && <DefaultItemText />}
              </span>
            ))}
          />
        ))}
        {!!accessories.length && (
          <Row
            title={translate("CHOSEN_ACCESSORIES")}
            content={accessories.map(accessory => (
              <span key={accessory.id}> {`${accessory.name} - ${accessory.quantity} ${accessory.unit}`}</span>
            ))}
          />
        )}
      </>
    </InfoSection>
  );

  return (
    <section>
      {product && productInfo(product)}
      {(!!functionAccessories?.length || !!accessories?.length) && showAccessories(functionAccessories, accessories)}
      {form && <AdditionalInfoSection form={form} />}
      {(address || contact || deliveryMethod) && (
        <AddressSection address={address} contact={contact} deliveryMethod={deliveryMethod} />
      )}
    </section>
  );
}

OrderInfo.propTypes = {
  orderData: PropTypes.shape({
    accessories: PropTypes.array,
    address: PropTypes.object,
    contact: PropTypes.object,
    functionAccessories: PropTypes.array,
    form: PropTypes.object,
    product: PropTypes.object,
    deliveryMethod: PropTypes.object,
  }),
};

export default OrderInfo;
