import { HeaderOptions, Method, Request, Response, RestOptions, sendRequest, URL } from "@myloc/myloc-utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../../../config/settings";
import { setErrorAction } from "../../../reducers/dialog/dialogAction";
import type { RootState } from "../../../reducers/rootReducer";

import { useTranslate } from "../../../language/i18n";

import { useAppDispatch } from "../../../reducers/hooks/useAppDispatch";
import { resetComflowSessionId, setComflowSessionId } from "../../../reducers/session/sessionAction";
import errorCodes from "../../../services/error/errorCodes";
//import defaultRestOptions from "../../../services/utils/defaultRestOptions";

import accountService from "../../../services/account/accountService";
import { isRedirectToComflowAllowed } from "../utils/comflowUtils";

const PORTAL_URL = process.env.REACT_APP_COMFLOW_WEBAPP + "/portal/";

const useComflowLogin = () => {
  const [response, setResponse] = useState("");
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(false);
  const refLoginInitiated = useRef(false);

  const translate = useTranslate();

  const virtualSessionId = useSelector((state: RootState) => state.session.sessionId);
  const comflowSessionId = useSelector((state: RootState) => state.session.comflowSessionId);
  const isSingleSignOn = useSelector((state: RootState) => state.session.isSingleSignOn);

  const dispatch = useAppDispatch();
  const refCurrentTask = useRef("");
  const refIsFetchingComflowContent = useRef(false);

  useEffect(() => {
    setIsLoggedIn(!!response);
  }, [response]);

  const startTask = process.env.REACT_APP_VERSION_LOGIN_TASK;

  if (!startTask) {
    dispatch(setErrorAction({ error: "exception", errorMessage: "Login task not defined" }));
  }

  const closeComflowTabs = useCallback(
    async (_comflowSessionId: string) => {
      const closeTabsUrl = api.comflow.closeAllComflowActiveTasks(_comflowSessionId);

      const request = new Request(closeTabsUrl, Method.POST);
      const restOptions = new RestOptions();

      restOptions.headerOptions = new HeaderOptions();

      if (virtualSessionId) restOptions.headerOptions.setHeader("SessionId", virtualSessionId);

      sendRequest(request, {}, restOptions);
    },
    [virtualSessionId],
  );

  /*
  const showError = useCallback(
    (errorMessage: string) => {
      dispatch(setErrorAction({ error: "exception", errorMessage: errorMessage }));
      refIsFetchingComflowContent.current = false;
    },
    [dispatch],
  );
  */

  const resolveComflowSessionIdFromComflowContent = useCallback((html: string) => {
    if (html.indexOf("CA-login-error-message") > 0 && html.indexOf("License error") > 0) {
      throw new Error("License error");
    }

    if (html.indexOf("LoginForm") > 0) {
      return null;
    }

    const sessionIndex = html.indexOf("sessionId");
    const startIndex = html.substring(sessionIndex).indexOf("'") + sessionIndex;
    const endIndex = html.substring(startIndex + 1).indexOf("'") + startIndex;
    const _comflowSessionId = html.substring(startIndex + 1, endIndex + 1);

    return _comflowSessionId;
  }, []);

  const dispatchComflowSessionIdFromComflowContent = useCallback(
    (_comflowSessionId: string) => {
      if (comflowSessionId !== _comflowSessionId) {
        dispatch(setComflowSessionId(_comflowSessionId));
      }
    },
    [comflowSessionId, dispatch],
  );

  interface ErrorResponseItem {
    code: string;
    title: string;
  }

  type ErrorResponseArray = ErrorResponseItem[];

  const customErrorHandler = useCallback((exception: any) => {
    const UNAUTHORIZED = 401;
    const OK = 200;

    //console.log("e", exception?.response?.data?.errors);

    let responseArray: ErrorResponseArray = [];

    if (exception?.response?.status === UNAUTHORIZED) {
      responseArray = exception.response.data.errors;
    }

    const responseStatus = exception?.response?.status ? exception.response.status : OK;

    return new Response(responseStatus, exception.message, responseArray);
  }, []);

  const comflowLogin = useCallback(async () => {
    const url = new URL(PORTAL_URL);

    url.addParameter("myg-action", "login");

    const request = new Request(url, Method.GET);

    //Use custom restOptions, reading sessionId from session instead from local storage
    const restOptions = new RestOptions();

    restOptions.headerOptions = new HeaderOptions();
    restOptions.errorService = customErrorHandler;

    if (virtualSessionId) restOptions.headerOptions.setHeader("SessionId", virtualSessionId);

    const response = await sendRequest(request, {}, restOptions);
    //const response = await sendRequest(request, {}, await defaultRestOptions(customErrorHandler));

    if (!response.isOk()) {
      const data = response.data;

      if (Array.isArray(data) && data.every(item => typeof item.code === "string" && typeof item.title === "string")) {
        // Type assertion as ErrorResponseArray
        const errorResponse: ErrorResponseArray = data;

        const isExpiredError = errorResponse.some(
          item => item.code === errorCodes.SESSION_INVALID.SESSION_EXPIRED.CODE,
        );

        if (isExpiredError) {
          throw new Error(errorCodes.SESSION_INVALID.SESSION_EXPIRED.CODE);
        }
      } else {
        console.log("response.data is not of type ErrorResponseArray");
      }
    }

    const html = response.data;

    if (typeof html !== "string") return null;

    const _comflowSessionId = resolveComflowSessionIdFromComflowContent(html);

    return _comflowSessionId ?? null;
  }, [customErrorHandler, resolveComflowSessionIdFromComflowContent, virtualSessionId]);

  const preFetchToForceNewSession = useCallback(async () => {
    const url = new URL(PORTAL_URL);
    const request = new Request(url, Method.GET);
    const restOptions = new RestOptions();

    restOptions.headerOptions = new HeaderOptions();
    restOptions.headerOptions.setHeader("forceNewSessionId", "forceNewSessionId");

    if (virtualSessionId) restOptions.headerOptions.setHeader("SessionId", virtualSessionId);

    const response = await sendRequest(request, {}, restOptions);

    const html = response.data;

    if (typeof html !== "string") return null;

    if (html.indexOf("Comflow login page") > 0 || html.indexOf("resetPasswordLink ") > 0) {
      return null;
    }

    const _comflowSessionId = resolveComflowSessionIdFromComflowContent(html);

    return _comflowSessionId;
  }, [resolveComflowSessionIdFromComflowContent, virtualSessionId]);

  const fetchComflowContent = useCallback(
    async (_comflowSessionId: string) => {
      const url = new URL(PORTAL_URL);

      if (!startTask) return null;

      url.addParameter("_startTask", startTask);

      url.addParameter("sessionId", _comflowSessionId);

      const request = new Request(url, Method.GET);
      const restOptions = new RestOptions();

      restOptions.headerOptions = new HeaderOptions();

      if (virtualSessionId) restOptions.headerOptions.setHeader("SessionId", virtualSessionId);

      const response = await sendRequest(request, {}, restOptions);

      const html = response.data;

      if (typeof html !== "string") return null;

      if (html.indexOf("resetpassword") > 0) {
        return null;
      }

      return html;
    },
    [startTask, virtualSessionId],
  );

  const isValidHtml = (html: string) => {
    //The error is just a message inside the html code. The response is still 200
    const isError = html.includes("500") && html.includes("Oops") && html.includes("Error");

    return !isError;
  };

  const loadComflowContent = useCallback(
    async (_comflowSessionId?: string | null) => {
      if (!_comflowSessionId) {
        _comflowSessionId = await preFetchToForceNewSession();
      }

      if (_comflowSessionId === null) {
        return null;
      }

      const html = await fetchComflowContent(_comflowSessionId);

      if (html === null) {
        return null;
      }

      if (!isValidHtml(html)) {
        throw new Error(translate("SERVER_COULDNT_PROCESS"));
      }

      setResponse(html);

      _comflowSessionId = resolveComflowSessionIdFromComflowContent(html);

      if (_comflowSessionId && startTask) {
        closeComflowTabs(_comflowSessionId);
      }

      return _comflowSessionId;
    },
    [
      closeComflowTabs,
      fetchComflowContent,
      preFetchToForceNewSession,
      resolveComflowSessionIdFromComflowContent,
      startTask,
      translate,
    ],
  );

  const doRetrieveComflowContent = useCallback(async () => {
    //Use comflowSessionId from storage if exists

    let _comflowSessionId = comflowSessionId ?? null;

    //Try to load comflow content
    _comflowSessionId = await loadComflowContent(_comflowSessionId);

    if (_comflowSessionId === null) {
      //No comflow session, login first

      try {
        _comflowSessionId = await comflowLogin();
      } catch (e) {
        if (e instanceof Error && e.message === errorCodes.SESSION_INVALID.SESSION_EXPIRED.CODE) {
          //console.log("Invalid session error " + errorCodes.SESSION_INVALID.SESSION_EXPIRED.MESSAGE);

          throw e;
        }

        if (e instanceof Error && e.message === "License error") {
          //console.log("License error, throw immediately");

          throw e;
        }
      }

      //Now load comflow content, if sessionId is received - and update sessionId from received content
      if (_comflowSessionId) {
        _comflowSessionId = await loadComflowContent(_comflowSessionId);
      }
    }

    if (!_comflowSessionId) {
      throw new Error("COMFLOW LOGIN FAILED");
    }

    //Everything OK, dispatch the sessionId received from comflow
    dispatchComflowSessionIdFromComflowContent(_comflowSessionId);
  }, [comflowLogin, comflowSessionId, dispatchComflowSessionIdFromComflowContent, loadComflowContent]);

  const retrieveComflowContent = useCallback(async () => {
    try {
      await doRetrieveComflowContent();
    } catch (e) {
      //console.log("ERROR OCCURRED after first call to doRetrieveComflowContent", e);

      if (!(e instanceof Error)) return;

      //Probably session mixup, try again - new Comflow login will occur!
      try {
        await doRetrieveComflowContent();
      } catch (e) {
        //console.log("ERROR OCCURRED: ", e);

        if (!(e instanceof Error)) return;

        //Reset comflowSessionId to prevent further consequential errors - will force a new preFetch next time
        dispatch(resetComflowSessionId());

        //Don't show error message if session expired, but do kill session
        if (e.message === errorCodes.SESSION_INVALID.SESSION_EXPIRED.CODE) {
          await accountService.logout(isSingleSignOn);
        } else {
          dispatch(setErrorAction({ error: "exception", errorMessage: e.message }));
        }

        refIsFetchingComflowContent.current = false;
      }
    }
  }, [dispatch, doRetrieveComflowContent, isSingleSignOn]);

  useEffect(() => {
    if (!forceRefresh || refIsFetchingComflowContent.current) return;

    const doComflowAction = async () => {
      //setLoginInit(false);
      refCurrentTask.current = startTask ?? "";
      refIsFetchingComflowContent.current = true;

      setForceRefresh(false);
      setResponse("");

      await retrieveComflowContent();
      refIsFetchingComflowContent.current = false;
      refLoginInitiated.current = false;
    };

    doComflowAction();
  }, [forceRefresh, retrieveComflowContent, startTask]);

  const initiateLogin = useCallback(() => {
    if (refLoginInitiated.current) return;

    refLoginInitiated.current = true;

    setResponse("");
    setIsLoggedIn(false);
    setForceRefresh(true);
  }, []);

  return {
    isRedirectToComflowAllowed,
    response,
    isLoggedIn,
    refIsFetching: refIsFetchingComflowContent,
    initiateLogin,
  };
};

export default useComflowLogin;
