import PropTypes from "prop-types";

const Room = ({ color = "currentColor", size = 24, customCssClass }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M14 6v15H3v-2h2V3h9v1h5v15h2v2h-4V6h-3zm-4 5v2h2v-2h-2z" />
  </svg>
);

Room.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  customCssClass: PropTypes.string,
};

export default Room;
