import { TextArea } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useRef } from "react";
import { useTranslate } from "../../../../language/i18n";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";

const CreateCommentModal = ({ visible, type, onSubmit, onCancel }) => {
  const translate = useTranslate();
  const comment = useRef("");

  const cancel = () => {
    comment.current = "";
    onCancel();
  };

  return (
    <ConfirmModal
      title={translate(`NEW_${type}_COMMENT`)}
      visible={visible}
      cancel={cancel}
      submit={() => onSubmit(comment.current)}
      declineButtonText={translate("CANCEL")}
      confirmButtonText={translate("CREATE")}
      small={false}
    >
      <TextArea placeholder={translate("WRITE_COMMENT")} onChange={e => (comment.current = e.target.value)} />
    </ConfirmModal>
  );
};

CreateCommentModal.propTypes = {
  visible: PropType.bool.isRequired,
  setVisible: PropType.func.isRequired,
  type: PropType.string.isRequired,
  onSubmit: PropType.func.isRequired,
  onCancel: PropType.func.isRequired,
};

export default CreateCommentModal;
