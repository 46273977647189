import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslate } from "../../../language/i18n";
import { Form, TextArea, Button } from "@myloc/myloc-gui";
import FileUpload from "./FileUpload/FileUpload";
import styles from "./Description.module.scss";

function Description({ data, setData, onNext }) {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);

  const setFiles = uploadedFiles => {
    setData({ files: uploadedFiles });
  };

  const handleSubmit = async () => {
    setLoading(true);

    await onNext(data);
  };

  return (
    <Form className={classNames(styles.content)} onSubmit={handleSubmit}>
      <div className={classNames(styles.columns)}>
        <TextArea
          label={translate("DESCRIBE_DEVIATION")}
          onChange={value => setData({ description: value.target.value })}
          value={data.description}
        />
        <FileUpload setFiles={setFiles} files={data?.files} />
      </div>
      <Button type="submit" isLoading={isLoading} customCssClass={styles.submitBtn}>
        {translate("DEVIATION_CREATE")}
      </Button>
    </Form>
  );
}

Description.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  onNext: PropTypes.func.isRequired,
};

export default Description;
