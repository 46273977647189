import { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, SelectField, Button } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import { DefaultItemText } from "../../shared/Text/TextComponents";
import styles from "./Customization.module.scss";

function Customization({ onNext }) {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const [make, setMake] = useState([]);

  useEffect(() => {
    orderService.getMake().then(response => setMake(response.data.make));
  }, []);

  const selectedValues = useRef({});

  const handleSubmit = async () => {
    setLoading(true);
    const reqBody = {
      make: Object.entries(selectedValues.current).map(pair => {
        return { id: pair[0], selected: pair[1] };
      }),
    };
    const response = await orderService.updateMake(reqBody);
    setLoading(false);
    if (response.isOk()) {
      onNext(response.data);
    }
  };

  const template = useCallback(
    item => (
      <p>
        {item.value} {item.default && <DefaultItemText />}
      </p>
    ),
    [],
  );

  const handleOnSelect = useCallback((selected, id) => {
    selectedValues.current = Object.assign(selectedValues.current, { [id]: selected?.id });
  }, []);

  const renderSelections = () => {
    return (
      make &&
      make.map(item => {
        const defaultValue = item.values.find(val => val.default);
        const preSel = item.selected || defaultValue?.id || item.values[0]?.id;
        return (
          <SelectField
            key={item.id}
            name={item.id}
            label={item.label}
            selection={item.values}
            preSelected={preSel}
            listTemplate={template}
            onSelect={val => handleOnSelect(val, item.id)}
            required
          />
        );
      })
    );
  };

  return (
    <Form className={styles.content} onSubmit={handleSubmit}>
      {renderSelections()}
      <Button type="submit" isLoading={isLoading} customCssClass={styles.submitBtn}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
}

Customization.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default Customization;
