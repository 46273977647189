import classNames from "classnames";
import PropTypes from "prop-types";
import MunicipalityLogo from "../../shared/MunicipalityLogo/MunicipalityLogo";
import styles from "./CompanyLogoList.module.scss";

const CompanyLogoList = ({ customCssClass }) => (
  <section className={classNames(styles.list, customCssClass)}>
    <MunicipalityLogo id="Heby" customCssClass={styles.logo} />
    <MunicipalityLogo id="Habo" customCssClass={styles.logo} />
    <MunicipalityLogo id="Enkoping" customCssClass={styles.logo} />
    <MunicipalityLogo id="Osthammar" customCssClass={styles.logo} />
    <MunicipalityLogo id="Tierp" customCssClass={styles.logo} />
    <MunicipalityLogo id="Knivsta" customCssClass={styles.logo} />
  </section>
);

CompanyLogoList.propTypes = {
  customCssClass: PropTypes.string,
};

export default CompanyLogoList;
