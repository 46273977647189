import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import FacilitySelector from "../../FacilitySelector/FacilitySelector";
import styles from "./FacilityAddress.module.scss";
import { useTranslate } from "../../../../language/i18n";

function FacilityAddress({ preSelected, onSelect, disabled, showHUL, isActiveReferencedOrder }) {
  const [currentAddress, setCurrentAddress] = useState(null);
  const translate = useTranslate();

  const handleChange = useCallback(
    val => {
      setCurrentAddress(val);
      onSelect?.(val);
    },
    [onSelect],
  );

  const showSelected = () => {
    return (
      <address className={styles.address}>
        {currentAddress.address?.careOf && (
          <p>
            {translate("CARE_OF")} {currentAddress.address.careOf}
          </p>
        )}
        {currentAddress.address?.street && <p>{currentAddress.address.street}</p>}
        <p>
          {currentAddress.address?.postalNo} {currentAddress.address?.city}
        </p>
      </address>
    );
  };

  return (
    <section className={styles.container}>
      <div className={styles.picker}>
        <FacilitySelector
          onSelect={handleChange}
          preSelected={preSelected}
          disabled={disabled}
          showHUL={showHUL}
          referencedPrescriber={isActiveReferencedOrder?.fitting?.prescriber?.id}
        />
      </div>
      {currentAddress && showSelected()}
    </section>
  );
}

FacilityAddress.propTypes = {
  preSelected: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  showHUL: PropTypes.bool,
  isActiveReferencedOrder: PropTypes.bool,
};

export default FacilityAddress;
