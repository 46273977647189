import { useSelector } from "react-redux";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { resetMessage } from "../../../reducers/dialog/dialogAction";
import ErrorModal from "../../ErrorHandling/ErrorModal";
const UserFeedback = () => {
  const errorMessage = useSelector(state => state.dialog.message);

  const openModal =
    errorMessage?.error?.message?.includes("503") || errorMessage?.error?.message?.includes("Network Error");
  return <>{openModal ? <ErrorModal /> : <SnackbarMessage onReset={resetMessage} />}</>;
};

export default UserFeedback;
