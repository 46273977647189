import { Button } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../language/i18n";
import { setEmbeddedStatus } from "../../reducers/embedded/embeddedActions";
import orderService from "../../services/order/orderService";
import { FITTING_STATUS } from "../../utils/constants";
import Checkbox from "../shared/Checkbox/Checkbox";
import Page from "../shared/Page/Page";
import RadioButtonGroup from "../shared/RadioButton/RadioButtonGroup";
import AdminEditRow from "./AdminEditRow";
import AdminTableRow from "./AdminTableRow";
import styles from "./FittingsPage.module.scss";
import RegretReleaseFittingsModal from "./RegretReleaseFittingsModal";
import ReleaseFittingsModal from "./ReleaseFittingsModal";

const AdminFittingsPage = () => {
  const embeddedStatus = useSelector(state => state.embedded?.status);
  const translate = useTranslate();
  const [status, setStatus] = useState(
    embeddedStatus ? embeddedStatus : { id: FITTING_STATUS.UNPLANNED, value: translate("FITTING_STATUS_UNPLANNED") },
  );
  const [fittings, setFittings] = useState(null);
  const [selected, setSelected] = useState([]);
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState("");
  const [meetings, setMeetings] = useState([]);

  useEffect(() => {
    orderService
      .getFittings({
        status: status.id,
        isAdminCall: true,
        all: true,
        sort:
          status.id === FITTING_STATUS.ACTIVE
            ? "meetingStartDateTime:asc"
            : status.id === FITTING_STATUS.CLOSED
            ? "closedDateTime:desc"
            : "",
      })
      .then(response => setFittings(response.data.page));
  }, [status.id]);

  const onMeetingUpdate = data => {
    if (data) {
      const meeting = meetings.find(meeting => meeting.id === data.id);
      if (meeting) {
        setMeetings(meetings.map(meeting => (meeting.id === data.id ? { ...meeting, ...data } : meeting)));
      } else {
        setMeetings([...meetings, { ...data }]);
      }
    }
  };

  const onChange = data => {
    if (edit) setEdit(false);
    setStatus(data);
    setEmbeddedStatus(data);
  };

  const onSubmit = async () => {
    const response = await orderService.updateFittingMeetings(meetings);
    if (response.statusCode === 200) {
      location.reload();
    }
  };

  const statusValues = [
    { id: FITTING_STATUS.UNPLANNED, value: translate("FITTING_STATUS_UNPLANNED") },
    { id: FITTING_STATUS.ACTIVE, value: translate("FITTING_STATUS_ACTIVE") },
    { id: FITTING_STATUS.WAITING, value: translate("FITTING_STATUS_WAITING") },
    { id: FITTING_STATUS.CLOSED, value: translate("FITTING_STATUS_CLOSED") },
  ];

  const showColumn = column => {
    switch (column) {
      case "CHECKBOX":
        return [FITTING_STATUS.ACTIVE, FITTING_STATUS.UNPLANNED].includes(status.id);
      case "BOOKING":
        return [FITTING_STATUS.ACTIVE, FITTING_STATUS.UNPLANNED].includes(status.id);
      case "MEETING":
        return [FITTING_STATUS.ACTIVE, FITTING_STATUS.UNPLANNED].includes(status.id);
      case "INTERNAL_COMMENT":
        return [FITTING_STATUS.ACTIVE, FITTING_STATUS.UNPLANNED].includes(status.id);
      case "CLOSED":
        return [FITTING_STATUS.CLOSED].includes(status.id);
      default:
        return true;
    }
  };

  return (
    <>
      <Page title={translate("FITTING_ADMIN_PLANNING")} customCssClass={styles.page} showRefreshButton={true}>
        <RadioButtonGroup
          values={statusValues}
          onChange={onChange}
          selected={status.id}
          customCssClass={styles.filter}
        />
        {status.id !== FITTING_STATUS.CLOSED && (
          <p className={styles.statusDescription}>{translate(`FITTING_STATUS_${status.id}_DESCRIPTION_UNPLANNED`)}</p>
        )}
        {fittings?.length ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {showColumn("CHECKBOX") && (
                  <th className={styles.checkbox}>
                    <Checkbox
                      onChange={() =>
                        selected.length === fittings.length
                          ? setSelected([])
                          : setSelected(fittings.map(({ nextMeeting }) => nextMeeting.id))
                      }
                      checked={selected.length === fittings?.length}
                      customCssClass={styles.selectAll}
                      disabled={edit}
                    />
                  </th>
                )}
                <th className={styles.details}>{translate("FITTING")}</th>
                {showColumn("INTERNAL_COMMENT") && <th className={styles.details}>{translate("INTERNAL_COMMENT")}</th>}
                {showColumn("BOOKING") && <th className={styles.details}>{translate("BOOKING_COMMENT")}</th>}
                {showColumn("MEETING") && <th className={styles.meetingDetails}>{translate("MEETING_TIME_SHORT")}</th>}
              </tr>
            </thead>

            <tbody>
              {fittings?.map(fitting => (
                <Fragment key={`row-${fitting.id}`}>
                  {edit ? (
                    <AdminEditRow
                      key={`edit-row-${fitting.id}`}
                      fitting={fitting}
                      showColumn={showColumn}
                      onMeetingUpdate={onMeetingUpdate}
                    />
                  ) : (
                    <AdminTableRow
                      key={`table-row-${fitting.id}`}
                      fitting={fitting}
                      showColumn={showColumn}
                      selected={selected}
                      setSelected={setSelected}
                      status={status}
                    />
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        ) : (
          fittings && <p className={styles.noResults}>{translate("NO_RESULTS_FOUND")}</p>
        )}

        <div className={styles.actionButtons}>
          <ActionButtons
            edit={edit}
            setEdit={setEdit}
            status={status}
            setModal={setModal}
            onSubmit={onSubmit}
            selected={selected}
          />
        </div>
      </Page>

      <ReleaseFittingsModal visible={modal === "RELEASE_MODAL"} meetings={selected} onClose={() => setModal("")} />
      <RegretReleaseFittingsModal
        visible={modal === "REGRET_RELEASE_MODAL"}
        meetings={selected}
        onClose={() => setModal("")}
      />
    </>
  );
};

const ActionButtons = ({ edit, setEdit, status, setModal, onSubmit, selected }) => {
  const translate = useTranslate();

  if (status.id === FITTING_STATUS.UNPLANNED && edit) {
    return (
      <>
        <Button onClick={() => onSubmit()}>{translate("SAVE")}</Button>
        <Button onClick={() => setEdit(false)}>{translate("CANCEL")}</Button>
      </>
    );
  }
  if (status.id === FITTING_STATUS.UNPLANNED && !edit) {
    return (
      <>
        <Button onClick={() => setModal("RELEASE_MODAL")} disabled={!selected.length}>
          {translate("FITTING_RELEASE")}
        </Button>
        <Button onClick={() => setEdit(true)} disabled={!!selected.length}>
          {translate("FITTING_EDIT")}
        </Button>
      </>
    );
  }
  if (status.id === FITTING_STATUS.ACTIVE) {
    return (
      <Button onClick={() => setModal("REGRET_RELEASE_MODAL")} disabled={!selected.length}>
        {translate("FITTING_REGRET_RELEASE")}
      </Button>
    );
  }
  return <></>;
};

ActionButtons.propTypes = {
  edit: PropType.bool,
  setEdit: PropType.func,
  status: PropType.object,
  setModal: PropType.func,
  onSubmit: PropType.func,
  selected: PropType.array,
};

export default AdminFittingsPage;
