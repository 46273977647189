import { dialogVariables } from "@myloc/myloc-utils";
import store from "../rootReducer";
import { HOLDING_POPUP, ORDER_POPUP } from "./dialogVariable";

// Define types for the error and message parameters
interface Error {
  error: string;
  errorMessage: string;
}

interface OrderPopupParams {
  includeNavigation?: boolean;
  receiverType?: string;
  productRef?: string;
  addToCart?: () => void;
}

/** call without dispatch to keep working */
export function setError(error: Error) {
  store.dispatch(setErrorAction(error));
}

/** call with dispatch() to handle correct typing */
export function setErrorAction(error: Error) {
  return {
    type: dialogVariables.ACTION.MESSAGE_ERROR,
    error: error.error,
    message: error.errorMessage,
  };
}

export function setMessage(message: string, type: string = dialogVariables.ACTION.MESSAGE_INFO) {
  store.dispatch({ type: type, message: message });
}

export function resetMessage() {
  store.dispatch({ type: dialogVariables.ACTION.RESET_MESSAGE });
}

export function openOrderPopup({
  includeNavigation = true,
  receiverType = "",
  productRef = "",
  addToCart = () => {},
}: OrderPopupParams) {
  const orderPopup = {
    visible: true,
    includeNavigation: includeNavigation,
    receiverType: receiverType,
    productRef: productRef,
    addToCart: addToCart,
  };

  store.dispatch({ type: ORDER_POPUP, orderPopup });
}

export function closeOrderPopup() {
  const orderPopup = {
    visible: false,
    includeNavigation: true,
    receiverType: "",
  };

  store.dispatch({ type: ORDER_POPUP, orderPopup });
}

export function openHoldingPopup() {
  const holdingPopup = {
    visible: true,
  };

  store.dispatch({ type: HOLDING_POPUP, holdingPopup });
}

export function closeHoldingPopup() {
  const holdingPopup = {
    visible: false,
  };

  store.dispatch({ type: HOLDING_POPUP, holdingPopup });
}
