import { Form, InputField, Modal, Button } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslate } from "../../language/i18n";
import accountService from "../../services/account/accountService";
import MessageBox, { Type } from "../shared/MessageBox/MessageBox";
import PasswordRules, { isRulesFulfilled } from "../shared/Password/PasswordRule";
import styles from "./PasswordChange.module.scss";

const PasswordChange = ({ visible, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const translate = useTranslate();

  const [policy, setPolicy] = useState({});

  const disabled = !isRulesFulfilled(policy.rules, password) || password !== repeatPassword;

  useEffect(() => {
    accountService.getPasswordPolicy().then(response => setPolicy(response.data));
  }, []);

  const onSubmit = async () => {
    setErrorMessage(null);
    setIsLoading(true);
    const response = await accountService.updatePassword(null, {
      password,
      passwordRepeat: repeatPassword,
      previous: currentPassword,
    });

    setIsLoading(false);
    if (response.isOk()) {
      onClose();
      setCurrentPassword("");
      setPassword("");
      setRepeatPassword("");
    } else {
      setErrorMessage(response.message);
    }
  };

  return (
    <Modal visible={visible} title={translate("CHANGE_PASSWORD")} onClose={onClose} customCssClass={styles.model}>
      {errorMessage && <MessageBox type={Type.Error} message={errorMessage} customCssClass={styles.messageBox} />}
      <section className={styles.content}>
        <Form onSubmit={onSubmit}>
          <InputField
            label={translate("CURRENT_PASSWORD")}
            onChange={e => setCurrentPassword(e.currentTarget.value)}
            value={currentPassword}
            required
            type={"password"}
          />
          <InputField
            label={translate("NEW_PASSWORD")}
            onChange={e => setPassword(e.currentTarget.value)}
            value={password}
            required
            pattern={policy.regexValidation}
            type={"password"}
          />
          <InputField
            label={translate("REPEAT_PASSWORD")}
            onChange={e => setRepeatPassword(e.currentTarget.value)}
            value={repeatPassword}
            required
            pattern={policy.regexValidation}
            type={"password"}
          />
          <Button type="submit" disabled={disabled} isLoading={isLoading}>
            {translate("SAVE")}
          </Button>
        </Form>
        <PasswordRules password={password} rules={policy.rules} />
      </section>
    </Modal>
  );
};

PasswordChange.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default PasswordChange;
