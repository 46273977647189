import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import ContactModal from "../ContactModal/ContactModal";
import Selector from "../Selector/Selector";
import styles from "./ContactPerson.module.scss";

function ContactPerson({ user, contacts, selectedContact, onSelect, onReload, required }) {
  const translate = useTranslate();
  const [contactModalProps, setContactModalProps] = useState({ visible: false, edit: false });
  const hasPrimaryContactSet = contacts?.find(contact => contact?.isPrimaryContact);
  const onEdit = () => {
    setContactModalProps({ visible: true, edit: true });
  };

  const onAdd = () => {
    setContactModalProps({ visible: true, edit: false });
  };

  const handleModalClose = contact => {
    if (!contactModalProps.edit && contact) onSelect(contact);
    if (contact) onReload?.();

    setContactModalProps({ visible: false });
  };

  const showSelected = useCallback(
    item => (
      <div className={styles.contact}>
        <p>
          {item.firstName} {item.surname}
        </p>
        <p>{item.phoneNo}</p>
        <p>{item.email}</p>
      </div>
    ),
    [],
  );

  const contactTemplate = useCallback(
    item => (
      <>
        <p>{item.relation}</p>
        <p>
          {item.firstName} {item.surname}
        </p>
        <p>{item.phoneNo}</p>
        <p>{item.email}</p>
      </>
    ),
    [],
  );

  return (
    <>
      <Selector
        label={translate("CONTACT_PERSON")}
        addNewButtonTitle={translate("NEW_CONTACT")}
        displayValue="relation"
        options={contacts}
        selected={selectedContact}
        listTemplate={contactTemplate}
        displaySelected={showSelected}
        onAdd={onAdd}
        onEdit={onEdit}
        onChange={onSelect}
        required={required}
      />
      <ContactModal
        userId={user?.id}
        hasPrimaryContactSet={hasPrimaryContactSet != null}
        showPrimaryCheckbox={contacts?.length > 1}
        visible={contactModalProps.visible}
        contact={contactModalProps.edit ? selectedContact : null}
        onClose={handleModalClose}
      />
    </>
  );
}

const contactPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string,
  relation: PropTypes.string,
  firstName: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  phoneNo: PropTypes.string,
});

ContactPerson.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  selectedContact: contactPropType,
  contacts: PropTypes.arrayOf(contactPropType),
  onSelect: PropTypes.func.isRequired,
  onReload: PropTypes.func,
  required: PropTypes.bool,
};

export default ContactPerson;
