import { useMemo } from "react";
import { useTranslate } from "../../../language/i18n";
import { TASK_TYPES } from "../../../utils/constants";
import pages from "../../../utils/pages";
import Tasks from "../../MyMyloc/Sections/Tasks";
import Page from "../../shared/Page/Page";

const WorkList = () => {
  const translate = useTranslate();

  const breadcrumbs = useMemo(
    () => [
      {
        text: translate(pages.BASE_STORAGES.NAME),
      },
    ],
    [translate],
  );

  return (
    <Page breadcrumbs={breadcrumbs}>
      <Tasks taskTypes={[TASK_TYPES.BSM]} isBaseStorageWorkList />
    </Page>
  );
};

export default WorkList;
