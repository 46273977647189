import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Label from "../../shared/Label/Label";

const BaseStorageInfo = ({ baseStorage }) => {
  const translate = useTranslate();

  return (
    <article>
      <section>
        <h4>
          <b> {translate("BASE_STORAGE_INFORMATION")} </b>
        </h4>

        <Label label={translate("BASE_STORAGE")} value={`${baseStorage?.value} - ${baseStorage?.label}`} />
        <Label
          label={translate("ADDRESS")}
          value={[baseStorage?.address?.street, `${baseStorage?.address?.postalNo} ${baseStorage?.address?.city}`]}
        />
      </section>
    </article>
  );
};

BaseStorageInfo.propTypes = {
  baseStorage: PropType.object,
};

export default BaseStorageInfo;
