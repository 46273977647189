import PropType from "prop-types";

import classNames from "classnames";
import { generatePath, Link } from "react-router-dom";
import Problem from "../../assets/icons/Problem";
import { useTranslate } from "../../language/i18n";
import { useTablet } from "../../utils/viewport";
import Label from "../shared/Label/Label";

import { Button, THEME } from "@myloc/myloc-gui";
import { useState } from "react";
import { useSelector } from "react-redux";
import { setMessage } from "../../reducers/dialog/dialogAction";
import baseStorageService from "../../services/baseStorage/baseStorageService";
import orderService from "../../services/order/orderService";
import { CLIENT_TYPE, MOVE_OPTIONS } from "../../utils/constants";
import pages from "../../utils/pages";
import ScrapModal from "../Scrap/ScrapModal";
import styles from "./BaseStoragesPage.module.scss";
import OrderBaseStorageMoveModal from "./Move/OrderBaseStorageMoveModal";

const BaseStorageItem = ({ item, baseStorage, hideLinks, showBaseStorageMoveOption }) => {
  const isTablet = useTablet();
  const translate = useTranslate();
  const order = useSelector(state => state.appData?.order);
  const [showScrapModal, setShowScrapModal] = useState(false);
  const [showOrderMoveModal, setShowOrderMoveModal] = useState(false);

  const scrapSubmit = async (reasonCodeId, quantity) => {
    const data = { balances: [{ balanceId: item.id, quantity }], reasonCodeId };
    return await baseStorageService.scrapBalance(data);
  };

  const orderMove = async data => {
    const response = await orderService.createContent(data);
    if (response.isOk()) {
      setShowOrderMoveModal(false);
      setMessage(translate("CART_ADDED_ITEM", { item: item.product.name }));
    }
  };

  const performMove = async (data, balanceId) => {
    const response = await baseStorageService.moveBalance(balanceId, data);
    if (response.isOk()) {
      setMessage(translate("ITEM_MOVED", { item: item.product.name }));
    }
  };

  return (
    <article className={styles.item}>
      <section className={classNames(styles.section, item.deviation && styles.titleDeviation)}>
        <div>
          <p className={styles.itemTitle}>
            {item?.product?.name || item?.product?.id}
            {item?.product?.hasDeviation && <Problem customCssClass={styles.deviation} />}
          </p>
        </div>
        {isTablet && (
          <div className={styles.itemBalance}>
            <Label
              label={translate("STOCK_BALANCE")}
              value={item?.product?.quantity + " " + item?.product?.unit}
              inline
            />
          </div>
        )}
      </section>
      <section className={styles.section}>
        <div>
          {hideLinks ? (
            <Label label={translate("PRODUCT_NUMBER_SHORT")} value={item?.product?.productNumber} inline />
          ) : (
            <Link
              to={{
                pathname: generatePath(pages.PRODUCT_DETAIL.PATH, {
                  product: item?.product?.productId,
                }),
                state: { referrer: `${window.location.pathname}${window.location.search}` },
              }}
            >
              <Label label={translate("PRODUCT_NUMBER_SHORT")} value={item?.product?.productNumber} inline />
            </Link>
          )}
          <Label label={translate("ARTICLE_CLASS")} value={item?.product?.itemClass?.label} inline />
        </div>
        <div>
          {hideLinks || !item?.product?.inventoryNumber ? (
            <Label label={translate("INVENTORY_NUMBER_SHORT")} value={item?.product?.inventoryNumber} inline />
          ) : (
            <Link
              to={{
                pathname: generatePath(pages.FIXED_ASSET.PATH, {
                  baseStorage: baseStorage.id,
                  product: item?.product?.id,
                }),
                state: {
                  baseStorage: baseStorage,
                  referrer: `${window.location.pathname}${window.location.search}`,
                },
              }}
            >
              <Label label={translate("INVENTORY_NUMBER_SHORT")} value={item?.product?.inventoryNumber} inline />
            </Link>
          )}
          <Label label={translate("BATCH_NO")} value={item?.product?.batch} inline />
        </div>
        <div>
          <Label label={translate("STORAGE_PLACE")} value={item?.location?.value} inline />
          <Label label={translate("SUPPLIER")} value={item?.product?.supplier?.label} inline />
        </div>
        <div>
          {!isTablet && (
            <Label
              label={translate("STOCK_BALANCE")}
              value={item?.product?.quantity + " " + item?.product?.unit}
              inline
            />
          )}
          <Label label={translate("RESERVED")} value={item?.product?.allocatedQuantity} inline />
          <Label
            label={translate("AVAILABLE_BALANCE")}
            value={item?.product?.quantity - item?.product?.allocatedQuantity}
            inline
          />
        </div>
      </section>
      {item?.product?.quantity - item?.product?.allocatedQuantity > 0 && (
        <div className={styles.buttons}>
          {(!order || order.receiver.type == CLIENT_TYPE.USER) && (
            <div className={styles.move}>
              <Button theme={THEME.PLAIN_PRIMARY} onClick={() => setShowOrderMoveModal(MOVE_OPTIONS.DIRECT_MOVE)}>
                {translate("MOVE")}
              </Button>
            </div>
          )}
          {showBaseStorageMoveOption && (
            <div className={styles.move}>
              <Button theme={THEME.PLAIN_PRIMARY} onClick={() => setShowOrderMoveModal(MOVE_OPTIONS.ORDER_MOVE)}>
                {translate("ORDER_MOVE")}
              </Button>
            </div>
          )}
          <Button theme={THEME.PLAIN_PRIMARY} onClick={() => setShowScrapModal(true)}>
            {translate("SCRAP")}
          </Button>
        </div>
      )}
      {showScrapModal && (
        <ScrapModal
          visible={showScrapModal}
          onClose={() => setShowScrapModal(false)}
          currentAvailableBalance={item?.product?.quantity - item?.product?.allocatedQuantity}
          currentAvailableBalanceUnit={item?.product?.unit}
          isInventory={item?.product?.inventoryNumber != null}
          showCounter
          onSubmit={scrapSubmit}
        />
      )}

      <OrderBaseStorageMoveModal
        visible={showOrderMoveModal}
        onClose={() => setShowOrderMoveModal(false)}
        item={item}
        onSubmit={showOrderMoveModal === MOVE_OPTIONS.DIRECT_MOVE ? performMove : orderMove}
        fromBaseStorage={baseStorage}
        toBaseStorage={order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE ? order?.receiver : baseStorage}
        isDirectMove={showOrderMoveModal === MOVE_OPTIONS.DIRECT_MOVE}
      />
    </article>
  );
};

BaseStorageItem.propTypes = {
  item: PropType.object,
  baseStorage: PropType.object,
  hideLinks: PropType.bool,
  showBaseStorageMoveOption: PropType.bool,
};

export default BaseStorageItem;
