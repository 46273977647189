import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";
import {
  AdditionalInfoSection,
  AddressSection,
  InfoSection,
  Row,
} from "../../shared/OrderInfoSections/OrderInfoSections";
import styles from "./OrderInfo.module.scss";

function OrderInfo({ orderData: { pickupList, text, form, address, contact } }) {
  const translate = useTranslate();
  const showPickupList = data => (
    <InfoSection title={translate("AID")}>
      {data.map(item => (
        <li key={item.productNumber} className={styles.aidRow}>
          <span>{item.name}</span>
          <span>
            <span className={styles.subTitle}>{translate("INVENTORY_NUMBER_SHORT")}:</span> {item.inventoryNumber}
          </span>
          <span>
            <span className={styles.subTitle}>{translate("PRODUCT_NUMBER_SHORT")}:</span> {item.productNumber}
          </span>
          <span>
            <span className={styles.subTitle}>{translate("QUANTITY")}:</span>
            {item.quantity} {item.unit}
          </span>
        </li>
      ))}
      {text && <Row title={translate("DESCRIBE_AID")} content={text} />}
    </InfoSection>
  );

  return (
    <section>
      {pickupList && showPickupList(pickupList)}
      {form && <AdditionalInfoSection form={form} />}
      {(address || contact) && (
        <AddressSection address={address} contact={contact} title="PICKUP_ADDRESS_AND_CONTACT" />
      )}
    </section>
  );
}

OrderInfo.propTypes = {
  orderData: PropTypes.shape({
    pickupList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        quantity: PropTypes.number,
        productNumber: PropTypes.string,
        inventoryNumber: PropTypes.string,
      }),
    ),
    text: PropTypes.string,
    form: PropTypes.object,
    address: PropTypes.object,
    contact: PropTypes.object,
  }),
};

export default OrderInfo;
