import { NavLink } from "react-router-dom";
import { Message } from "../../assets/icons";
import pages from "../../utils/pages";
import styles from "./MessageButton.module.scss";

const MessageButton = () => {
  return (
    <NavLink to={pages.MESSAGES.PATH} className={styles.messageLink} activeClassName={styles.selected}>
      <Message size={35} customCssClass={styles.icon} />
    </NavLink>
  );
};

export default MessageButton;
