import QRCode from "qrcode.react";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";
import logo from "../../../assets/logos/HÖS/HÖS.jpg";
import { setErrorAction } from "../../../reducers/dialog/dialogAction";
import { useAppDispatch } from "../../../reducers/hooks/useAppDispatch";
import { RootState } from "../../../reducers/rootReducer";
import pages from "../../../utils/pages";
import { useTablet } from "../../../utils/viewport";
import Image from "../../shared/Image/Image";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import SpinningButton from "../SpinningButton/SpinningButton";
import { ReactComponent as BankIDIcon } from "./assets/images/BankID_logo_black.svg";
import { ReactComponent as BankIDIconWhite } from "./assets/images/BankID_logo_white.svg";
import { useBankId } from "./hooks/useBankId";
import type { CollectedResponse } from "./hooks/useBankIdAuthentication";
import styles from "./style/bankId.module.scss";

interface SpinnerProps {
  hide: boolean;
}

const Spinner = ({ hide }: SpinnerProps) => {
  return <div className="spinnerPlaceholder">{hide ? <>Hiding</> : <LoadingSpinner />}</div>;
};

interface FailedContentProps {
  collectedResponse?: CollectedResponse;
  action: () => void;
  cancelled?: boolean;
}

const navigateToBankId = () => {
  window.location.href = pages.BANKID_LOGIN.PATH;
};

const Logo = () => {
  return (
    <div className={styles.logo}>
      <Image
        src={logo}
        alt="Hös - Logotyp"
        customCssClass={styles.imageContainer}
        isOrderable={undefined}
        infoMessage={undefined}
      />
    </div>
  );
};

export const BankIDLoginAlternatives = () => {
  const notDesktop = useTablet();

  return (
    <>
      <div className={classNames(styles.sectionTitle, styles.textAlignLeft)}>Hur vill du logga in?</div>
      {notDesktop ? (
        <>
          <BankIdButtonThisDevice isPrimary={true} text={"Öppna BankID"} showIcon={true} />
          eller
          <SpinningButton buttonAction={navigateToBankId} variant="outlined">
            Öppna BankID på annan enhet
          </SpinningButton>
        </>
      ) : (
        <>
          <SpinningButton
            icon={<BankIDIconWhite />}
            iconPosition="start"
            buttonAction={navigateToBankId}
            variant="contained"
          >
            Mobilt BankID
          </SpinningButton>
          eller
          <BankIdButtonThisDevice isPrimary={false} text={"Öppna BankID på denna enhet"} showIcon={false} />
        </>
      )}
    </>
  );
};

const MILLISECONDS_IN_SECOND = 1000;
const SEVEN = 7;

const BankIdButtonThisDevice = ({
  isPrimary,
  text,
  showIcon,
}: {
  isPrimary: boolean;
  text: string;
  showIcon: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { bankIdUrl, initAuth } = useBankId();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined; // Declare timeout at a higher scope

    if (bankIdUrl) {
      timeout = setTimeout(() => {
        setLoading(false);
        dispatch(setErrorAction({ error: "exception", errorMessage: "BankID appen startades inte" }));
      }, SEVEN * MILLISECONDS_IN_SECOND); // Wait for 10 seconds

      window.location.href = bankIdUrl;

      const handleBlur = () => {
        if (timeout) {
          clearTimeout(timeout); // Clear timeout if it exists
        }

        setLoading(false); // Stop loading when the app successfully launches (focus is lost)
        window.removeEventListener("blur", handleBlur); // Clean up the event listener
      };

      //If we blur, we know that we lost focus, probably because BankID application exists and was launched
      //At blur, remove timer since we started - should only need 3 sec max
      window.addEventListener("blur", handleBlur);
    }

    // Clean up the event listener in case effect runs multiple times
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [bankIdUrl, dispatch]);

  const onClickUseThisDevice = () => {
    setLoading(true);

    //if (!bankIdUrl) {
    initAuth();
    //}
  };

  return (
    <>
      {showIcon ? (
        <SpinningButton
          variant={isPrimary ? "contained" : "outlined"}
          loading={loading}
          buttonAction={onClickUseThisDevice}
          icon={isPrimary ? <BankIDIconWhite /> : <BankIDIcon />}
          iconPosition={"start"}
        >
          {text}
        </SpinningButton>
      ) : (
        <SpinningButton
          variant={isPrimary ? "contained" : "outlined"}
          loading={loading}
          buttonAction={onClickUseThisDevice}
        >
          {text}
        </SpinningButton>
      )}
    </>
  );
};

const FailedContent = ({ collectedResponse, action, cancelled }: FailedContentProps) => {
  const getMessage = () => {
    if (cancelled) {
      return "Inloggningen avbröts";
    }

    if (collectedResponse?.status === "failed" && collectedResponse.hintCode === "startFailed") {
      return "Inloggningen startades inte";
    }

    if (collectedResponse?.status === "failed") {
      return "Autenticeringen misslyckades";
    }

    return "Ett fel uppstod";
  };

  return (
    <div className={styles.bankIdWrapper}>
      <div className={classNames(styles.bankdIdContentText, styles.textCentered, styles.withGap)}>
        <p>{getMessage()}</p>
        <SpinningButton buttonAction={action} variant="outlined">
          Försök igen
        </SpinningButton>
      </div>
    </div>
  );
};

interface PendingContentProps {
  collectedResponse?: CollectedResponse;
}

const PendingContent = ({ collectedResponse }: PendingContentProps) => {
  let msg: string;

  if (!collectedResponse) {
    msg = "Väntar på BankID...";
  } else if (collectedResponse.status === "complete") {
    msg = "Identidiering med BankID lyckades, omdirigerar...";
  } else if (collectedResponse.status === "failed") {
    msg = "Identidiering med BankID misslyckades...";
  } else if (collectedResponse.hintCode === "userSign") {
    msg = "Väntar på inloggning...";
  } else {
    msg = "Oväntat fel!";
  }

  return (
    <>
      <Spinner hide={false} />
      <>{msg}</>
    </>
  );
};

const QRCodeContainer = ({ qrCodeContent }: { qrCodeContent: string }) => {
  return <div className={styles.bankdIdContentQR}>{qrCodeContent && <QRCode size={128} value={qrCodeContent} />}</div>;
};

const Instructions = () => {
  return (
    <ol className={styles.bankdIdContentText}>
      <li>1. Öppna BankID-appen på din telefon</li>
      <li>2. Tryck på skanna QR-kod</li>
      <li>3. Rikta kameran mot QR-koden på skärmen</li>
    </ol>
  );
};

const InitContent = ({ qrCodeContent }: { qrCodeContent: string }) => {
  return (
    <>
      <div className={styles.bankIdWrapper}>
        <QRCodeContainer qrCodeContent={qrCodeContent} />
        <Instructions />
      </div>
      <BankIdButtonThisDevice isPrimary={false} text={"Öppna BankID på denna enhet"} showIcon={false} />
    </>
  );
};

const navigateHome = () => {
  window.location.href = pages.HOME;
};

const CancelContent = () => {
  return (
    <div className={styles.cancelContent} onClick={navigateHome}>
      Tillbaka
    </div>
  );
};

interface BankIdContainerProps {
  children: ReactNode;
}

const BankIdContainer = ({ children }: BankIdContainerProps) => {
  const notDesktop = useTablet();

  return (
    <div className={styles.authenticationContainer}>
      <div className={styles.bankIDContainerTitle}>
        {notDesktop ? (
          <>
            <div className="bankIDHeading">Logga in med BankID</div>
            <BankIDIcon className={styles.titleIcon} />
          </>
        ) : (
          <>
            <BankIDIcon className={styles.titleIcon} />
            <div className="bankIDHeading">Logga in med BankID</div>
          </>
        )}
      </div>
      {children}
      <CancelContent />
    </div>
  );
};

// Main component
const BankIdAuthentication = () => {
  const initialized = useSelector((state: RootState) => state.session.initialized);
  const {
    autoStartToken,
    bankIdContent,
    cancelled,
    collectedResponse,
    initAuth,
    isBankIdError,
    qrCodeContent,
    restart,
  } = useBankId();

  useEffect(() => {
    if (!autoStartToken && initialized && !cancelled) {
      initAuth();
    }
  }, [autoStartToken, cancelled, initAuth, initialized]);

  return (
    <>
      <nav className={styles.navBar}></nav>
      <div className={styles.container}>
        <BankIdContainer>
          {cancelled || isBankIdError() ? (
            <FailedContent action={restart} />
          ) : !autoStartToken || !bankIdContent ? (
            <PendingContent />
          ) : qrCodeContent && collectedResponse?.hintCode === "outstandingTransaction" ? (
            <InitContent qrCodeContent={qrCodeContent} />
          ) : collectedResponse?.hintCode === "expiredTransaction" || collectedResponse?.status === "failed" ? (
            <FailedContent collectedResponse={collectedResponse} action={restart} />
          ) : (
            <PendingContent collectedResponse={collectedResponse} />
          )}
        </BankIdContainer>
        <Logo />
      </div>
    </>
  );
};

export default BankIdAuthentication;
