import { useState } from "react";
import PropType from "prop-types";
import clientService from "../../../../services/client/clientService";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";
import ClientHoldingList from "../../../shared/ClientHoldingList/ClientHoldingList";
import { useTranslate } from "../../../../language/i18n";
import styles from "../Holding.module.scss";

const AddModal = ({ clientId, visible, onClose, selectedProducts }) => {
  const translate = useTranslate();
  const [selected, setSelected] = useState();

  const onSubmit = async () => {
    const response = await clientService.addAccessoriesToAid({
      clientId,
      holdingId: selected.id,
      data: selectedProducts,
    });

    if (response.isOk()) {
      return response;
    }
  };

  return (
    <ConfirmModal
      title={translate("ADD_AS_ACCESSORY")}
      text={translate("ADD_AS_ACCESSORY_DESCRIPTION")}
      visible={visible}
      cancel={onClose}
      small={false}
      submit={() => onSubmit().then(() => location.reload())}
      customCssClass={styles.addModal}
      confirmButtonDisabled={!selected}
    >
      <ClientHoldingList
        clientId={clientId}
        onSelect={accessory => {
          setSelected(accessory);
        }}
        selected={[selected]}
        singleSelect
        onlyInventories
      />
    </ConfirmModal>
  );
};

AddModal.propTypes = {
  clientId: PropType.string,
  visible: PropType.bool,
  onClose: PropType.func,
  selectedProducts: PropType.array,
};

export default AddModal;
