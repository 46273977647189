import { openOrderPopup } from "../reducers/dialog/dialogAction";
import pages from "./pages";

const CONFIGS = {
  MENU: {
    items: [
      {
        label: "MENU_ORDERS",
        subMenus: [
          {
            items: [{ label: "NEW_ORDER", action: openOrderPopup }],
          },
          {
            title: "ADD_TO_ORDER",
            items: [
              {
                label: pages.FUNCTION_ORDER.NAME,
                link: pages.FUNCTION_ORDER.PATH,
              },
              {
                label: pages.PRODUCTS.NAME,
                link: pages.PRODUCTS.PATH,
              },
              {
                label: pages.FITTING.NAME,
                link: pages.FITTING.PATH,
              },
              {
                label: pages.WORK_ORDER.NAME,
                link: pages.WORK_ORDER.PATH,
              },
              {
                label: pages.PICKUP_ORDER.NAME,
                link: pages.PICKUP_ORDER.PATH,
              },
              {
                label: pages.PRODUCTS_EXTERNAL_ASSORTMENT.NAME,
                link: pages.PRODUCTS_EXTERNAL_ASSORTMENT.PATH,
              },
            ],
          },
        ],
      },
      {
        label: "SEARCH",
        subMenus: [
          {
            items: [
              {
                label: "MENU_CLIENTS",
                link: pages.CLIENTS.PATH,
              },
              {
                label: "MENU_INVENTORIES",
                link: pages.INVENTORIES.PATH,
              },
            ],
          },
        ],
      },

      {
        label: "MENU_BASE_STORAGE",
        subMenus: [
          {
            items: [
              {
                label: "STOCK_BALANCE",
                link: pages.BASE_STORAGES.PATH,
              },
              {
                label: "MENU_DELIVERY",
                link: pages.RECEIVE_DELIVERIES.PATH,
              },
              {
                label: "MENU_BASE_STORAGE_INVENTORY",
                link: pages.BASE_STORAGE_INVENTORY.PATH,
              },
              {
                label: "MENU_BASE_STORAGE_WORK_LIST",
                link: pages.BASE_STORAGE_WORK_LIST.PATH,
              },
            ],
          },
        ],
      },
      {
        label: "MENU_PRODUCTS",
        to: pages.PRODUCTS.PATH,
      },
      {
        label: "MENU_DOCUMENTS",
        to: pages.DOCUMENTS.PATH,
      },
      {
        label: "MENU_DEVIATION",
        to: pages.DEVIATION.PATH,
      },
    ],
  },
  MENU_DRAWER: {
    items: [
      {
        label: "MENU_BASE_STORAGE",
        subMenus: [
          {
            items: [
              {
                label: "STOCK_BALANCE",
                link: pages.BASE_STORAGES.PATH,
              },
              {
                label: "MENU_DELIVERY",
                link: pages.RECEIVE_DELIVERIES.PATH,
              },
              {
                label: "MENU_BASE_STORAGE_INVENTORY",
                link: pages.BASE_STORAGE_INVENTORY.PATH,
              },
            ],
          },
        ],
      },
      {
        label: "MENU_DOCUMENTS",
        to: pages.DOCUMENTS.PATH,
      },
      {
        label: pages.DEVIATION.NAME,
        to: pages.DEVIATION.PATH,
      },
      {
        label: pages.USER_SETTINGS.NAME,
        to: pages.USER_SETTINGS.PATH,
      },
    ],
  },
  SEARCH_DRAWER: {
    items: [
      { label: "MENU_CLIENTS", to: pages.CLIENTS.PATH },
      {
        label: "MENU_INVENTORIES",
        to: pages.INVENTORIES.PATH,
      },
    ],
  },
};

export const createMenu = (conf, hiddenFields = {}) => {
  // hiddenFields is an object with item label as key, and boolean value to designate if hidden
  const menuConfig = CONFIGS[conf];
  if (!menuConfig) throw new Error(`[menu.js] No menu config for "${conf}" found`);

  let id = 0;
  const menu = menuConfig.items.map(item => {
    if (!item.label) throw new Error(`[menu.js] Label is required for menu item`);

    let itemOut = {
      id: id++,
      label: item.label,
      to: item.to || null,
    };

    if (item.subMenus) {
      itemOut = {
        ...itemOut,
        subMenus: item.subMenus.map(subMenu => ({
          title: subMenu.title || null,
          items:
            subMenu.items &&
            subMenu.items.map(subMenuItem => ({
              label: subMenuItem.label,
              action: subMenuItem.action || null,
              link: subMenuItem.link || null,
              hidden: hiddenFields[subMenuItem.label] || false,
            })),
        })),
      };
    }

    return itemOut;
  });

  return menu;
};
