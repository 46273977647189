import PropType from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Button, THEME } from "@myloc/myloc-gui";
import Refresh from "../../../assets/icons/Refresh";
import styles from "./Breadcrumbs.module.scss";
import { useTranslate } from "../../../language/i18n";

function Breadcrumbs({ breadcrumbs, showRefreshButton, customCssClass }) {
  const translate = useTranslate();
  return (
    <nav className={classNames(styles.breadcrumbs, customCssClass, showRefreshButton && styles.displayRefreshButton)}>
      <ol>
        {breadcrumbs.map(crumb => (
          <li key={crumb.text}>{crumb.link ? <Link to={crumb.link}>{crumb.text}</Link> : <p>{crumb.text}</p>}</li>
        ))}
      </ol>
      {showRefreshButton && (
        <div className={styles.button}>
          <Button
            theme={THEME.SECONDARY}
            onClick={() => window.location.reload()}
            customCssClass={styles.refreshButton}
          >
            <Refresh size={"25"} customCssClass={styles.refreshIcon} />
            <div className={styles.buttonText}>{translate("RELOAD")}</div>
          </Button>
        </div>
      )}
    </nav>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropType.array,
  customCssClass: PropType.string,
  showRefreshButton: PropType.bool,
};

export default Breadcrumbs;
