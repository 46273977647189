import { Button, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslate } from "../../../../language/i18n";
import taskService from "../../../../services/taskService";
import { isSameId } from "../../../../utils/calculateAllocation";
import { ACTION_TYPES } from "../../../../utils/constants";
import pages from "../../../../utils/pages";
import Checkbox from "../../../shared/Checkbox/Checkbox";
import Counter from "../../../shared/Counter/Counter";
import Label from "../../../shared/Label/Label";
import Page from "../../../shared/Page/Page";
import styles from "./TaskTypeBSM.module.scss";

const STATUS_COMPLETED = 90;

const TaskTypeBSM = () => {
  const translate = useTranslate();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useParams();
  const [selection, setSelection] = useState([]);
  const [task, setTask] = useState(null);
  const [executingAction, setExecutingAction] = useState();
  const disabled = !selection.length;

  const breadcrumbs = useMemo(
    () => [
      {
        text:
          location.state?.text ??
          `${translate(pages.BASE_STORAGES.NAME)} - ${translate(pages.BASE_STORAGE_WORK_LIST.NAME)}`,
        link: location.state?.path ?? pages.BASE_STORAGE_WORK_LIST.PATH,
      },
      {
        text: translate("REPORT_TASK"),
      },
    ],
    [location.state?.path, location.state?.text, translate],
  );

  useEffect(() => {
    (async () => {
      const response = await taskService.getTask(queryParams.taskId);
      setTask(response.data);
    })();
  }, [queryParams.taskId]);

  const getAllocationQuantity = (lineAllocations, selection) => {
    let count = 0;

    const allocationQuantity = lineAllocations
      .map(allocation => {
        if (count === selection.quantity) return;
        if (count + allocation.quantity <= selection.quantity) {
          count = count + allocation.quantity;
          return { allocation, quantity: allocation.quantity };
        } else if (count + allocation.quantity > selection.quantity) {
          const diff = selection.quantity - count;
          count += diff;
          return { allocation, quantity: diff };
        }
      })
      .filter(allocation => allocation);

    return allocationQuantity[0];
  };

  const getSelectedAllocations = () => {
    let allocationData = [];
    if (!selection.length) return allocationData;

    selection.forEach(selection => {
      const lineId = decodeURI(selection.line.id);
      const lineAllocations = task.allocations?.filter(allocation => {
        return isSameId(allocation, lineId);
      });

      const allocationQuantity = getAllocationQuantity(lineAllocations, selection);

      if (allocationQuantity == null) return;

      if (!allocationData.length) allocationData.push(allocationQuantity);
      else {
        const existingAllocationQuantity = allocationData.find(
          allocation => allocation.allocation.id === allocationQuantity.allocation.id,
        );
        if (existingAllocationQuantity == null) allocationData.push(allocationQuantity);
        else existingAllocationQuantity.quantity = allocationQuantity.quantity;
      }
    });

    return allocationData;
  };

  const removeLine = line => {
    setSelection(selection.filter(item => item.line.id !== line.id));
  };

  const addLine = line => {
    setSelection([...selection, { quantity: line.maxQuantity, line }]);
  };

  const handleCheck = line => {
    isSelected(line) ? removeLine(line) : addLine(line);
  };

  const allSelected = () => {
    const linesWithQuantity = task.lines.filter(line => line.maxQuantity > 0);
    return selection.length === linesWithQuantity.length;
  };

  const disableSelectAll = lines => {
    const totalMaxQuantity = lines.reduce((sum, line) => sum + line.maxQuantity, 0);
    return totalMaxQuantity < 1;
  };

  const isSelected = row => selection.find(item => item.line?.id === row.id);

  const toggleAll = () => {
    allSelected()
      ? setSelection([])
      : setSelection(
          task.lines
            .filter(line => line.maxQuantity > 0)
            .map(line => ({
              quantity: line.maxQuantity,
              line,
            })),
        );
  };

  const handleQuantityChange = (line, num) => {
    if (isSelected(line)) {
      const _selection = selection.filter(item => item.line.id !== line.id);
      _selection.push({ line, quantity: num });
      setSelection(_selection);
    }
  };

  const getQuantity = line => {
    if (isSelected(line)) {
      return line.maxQuantity;
    }
  };

  const performTask = async action => {
    setExecutingAction(action);
    const response = await taskService.doAllocationTask({
      id: task.id,
      allocations: getSelectedAllocations(),
      action,
      task,
    });

    setExecutingAction(null);

    if (response.isOk()) {
      if (response.data.status === STATUS_COMPLETED) {
        history.replace(pages.BASE_STORAGE_WORK_LIST.PATH);
      } else {
        window.location.reload();
      }
    }
  };

  if (!task) {
    return <Page>{translate("LOADING_DOT")}</Page>;
  }

  return (
    <Page breadcrumbs={breadcrumbs}>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.bold}>
                <span>{`${task.description}`}</span>
              </div>
              <div className={styles.row}>
                <p className={styles.label}>{translate("ORDER_NUMBER")}:</p>
                <p>{task.referenceOrderLine.orderNumber}</p>
              </div>
            </div>

            <div className={styles.right}>
              <div>{task.plannedDateTime}</div>
              <div className={classNames(styles.status, styles[`status${task.status}`])}>
                {translate(`TASK_STATUS_${task.status}`)}
              </div>
            </div>
          </div>

          <section className={styles.allocations}>
            <div className={styles.listHead}>
              <Checkbox
                label={translate("SELECT_ALL")}
                checked={!disableSelectAll(task.lines) && allSelected()}
                onChange={toggleAll}
                disabled={disableSelectAll(task.lines)}
              />
            </div>
            <ul>
              {task.lines?.map(line => {
                const allocations = task?.allocations?.filter(allocation =>
                  isSameId(allocation, decodeURIComponent(line.id)),
                );
                const locations = allocations?.map(allocation => allocation.location.value);
                const disabled = line.maxQuantity === 0;
                const item = line.item ?? line.logisticsObject?.item;

                return (
                  <li key={line.id} className={disabled && styles.disabled}>
                    <Checkbox
                      onChange={() => handleCheck(line)}
                      checked={!disabled && Boolean(isSelected(line))}
                      disabled={disabled}
                    />
                    <div className={styles.allocation}>
                      <div className={styles.titleSection}>
                        <div className={styles.title}>{item?.name}</div>
                      </div>
                      <div className={styles.locationQuantity}>
                        <div className={styles.title}>
                          {isSelected(line) ? (
                            <Counter
                              onChange={num => handleQuantityChange(line, num)}
                              min={1}
                              max={line.maxQuantity}
                              startValue={getQuantity(line)}
                              customCssClass={classNames(styles.counter, styles.selected)}
                            />
                          ) : (
                            `${line.maxQuantity} ${translate("UNITS")}`
                          )}
                        </div>
                      </div>
                      <div className={styles.itemInfo}>
                        <Label label={translate("PRODUCT_NUMBER_SHORT")} value={item?.itemNumber} inline />
                        <Label label={translate("ARTICLE_CLASS")} value={item?.itemClass?.label} inline />
                        <Label
                          label={translate("INVENTORY_NUMBER_SHORT")}
                          value={line.fixedAssetNumber ?? line.logisticsObject?.fixedAssetNumber}
                          inline
                        />
                        <Label label={translate("BATCH_NO")} value={item?.batch} inline />
                      </div>
                      <div className={styles.assetInfo}>
                        <Label
                          label={translate("FROM")}
                          value={`${task.fromWarehouse.label} - ${task.fromWarehouse.value}`}
                          inline
                        />
                        <Label label={translate("LOCATION")} value={locations.join(", ")} inline />
                      </div>
                      <div className={styles.quantityInfo}>
                        <Label
                          label={translate("TO")}
                          value={`${task.toLocation.facility.label} - ${task.toLocation.facility.value} `}
                          inline
                        />
                        <Label label={translate("LOCATION")} value={task.toLocation.value} inline />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>
          <section className={styles.actions}>
            <div className={styles.button}>
              <Button
                onClick={() => performTask(ACTION_TYPES.RETURN)}
                theme={THEME.SECONDARY}
                disabled={disabled}
                isLoading={executingAction == ACTION_TYPES.RETURN}
              >
                {translate("RETURN_TO_BASE_STORAGE")}
              </Button>
            </div>
            <div className={styles.button}>
              <Button
                onClick={() => performTask(ACTION_TYPES.MOVE)}
                disabled={disabled}
                isLoading={executingAction == ACTION_TYPES.MOVE}
              >
                {translate("MOVE")}
              </Button>
            </div>
          </section>
        </div>
      </div>
    </Page>
  );
};

TaskTypeBSM.propTypes = {
  task: PropType.shape({
    id: PropType.string,
    description: PropType.string,
    plannedDateTime: PropType.string,
    status: PropType.number,
    orderNumber: PropType.number,
    to: PropType.shape({
      type: PropType.string,
      firstName: PropType.string,
      surname: PropType.string,
    }),
    referenceOrderLine: PropType.shape({
      orderNumber: PropType.number,
    }),
    lines: PropType.arrayOf(
      PropType.shape({
        fixedAssetNumber: PropType.string,
        quantity: PropType.number,
        maxQuantity: PropType.number,
        item: PropType.shape({
          name: PropType.string,
          itemNumber: PropType.string,
          batch: PropType.string,
          itemClass: PropType.shape({
            label: PropType.string,
          }),
        }),
      }),
    ),
    logisticsObject: PropType.arrayOf(
      PropType.shape({
        item: PropType.shape({
          name: PropType.string,
          itemNumber: PropType.string,
          batch: PropType.string,
          itemClass: PropType.shape({
            label: PropType.string,
          }),
        }),
      }),
    ),
    allocations: PropType.arrayOf(
      PropType.shape({
        quantity: PropType.number,
        taskOrderLine: PropType.shape({
          id: PropType.string,
        }),
        location: PropType.shape({
          value: PropType.string,
        }),
      }),
    ),
  }),
};

export default TaskTypeBSM;
