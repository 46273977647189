import PropType from "prop-types";
import { useState, useEffect } from "react";
import { useParams, useHistory, generatePath } from "react-router-dom";
import CheckAuthButton from "../../shared/Buttons/CheckAuthButton";
import orderService from "../../../services/order/orderService";
import { useTranslate } from "../../../language/i18n";
import { THEME } from "@myloc/myloc-gui";
import { clearOrder, setOrder } from "../../../reducers/appData/appDataActions";
import { clearReferencedOrder, setReferencedOrder } from "../../../reducers/embedded/embeddedActions";
import { CONTENT_TYPE, CLIENT_TYPE } from "../../../utils/constants";
import { ProductDelivery, Work } from "../../shared/OrderListComponents/Types/Types";
import { setMessage } from "../../../reducers/dialog/dialogAction";
import DeleteOrderModal from "../../Cart/CartModals/DeleteOrderItemModal";
import pages from "../../../utils/pages";
import styles from "./FittingOrders.module.scss";

const FittingOrders = ({ fitting, setBreadcrumbs, embeddedFitting }) => {
  const translate = useTranslate();
  const history = useHistory();
  const [orders, setOrders] = useState();
  const [fetchOrders, setFetchOrders] = useState(false);
  const [groupedContentList, setGroupedContentList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));
  const isCompleted = fitting?.status?.value === 90 || fitting?.status?.value === 99;

  const params = useParams();

  useEffect(() => {
    orderService.getFittingOrders(params.fitting, { all: true }).then(response => setOrders(response.data.page));
    if (fetchOrders) setFetchOrders(false);
  }, [params, fetchOrders]);

  useEffect(() => {
    setBreadcrumbs([
      {
        text: embeddedFitting?.text
          ? translate(embeddedFitting?.text)
          : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}`,
        link: embeddedFitting?.path ? embeddedFitting?.path : pages.MY_FITTINGS.PATH,
      },
      {
        text: `${translate(pages.FITTING_DETAIL.NAME)} - ${translate(pages.MY_FITTINGS_ORDERS.NAME)}`,
      },
    ]);
  }, [embeddedFitting, setBreadcrumbs, translate]);

  const startOrder = async type => {
    setReferencedOrder({ fitting: fitting, type: type });
    if (type === CONTENT_TYPE.WORK) {
      const createOrder = await orderService.createOrder({
        id: fitting?.client?.id,
        type: CLIENT_TYPE.USER,
      });
      if (createOrder.isOk) {
        setOrder(createOrder.data);
        history.push(
          generatePath(isEmbedded ? pages.EMBEDDED_WORK_ORDER.PATH : pages.WORK_ORDER.PATH, {
            order: createOrder.data.id,
          }),
        );
      }
    } else {
      history.push(isEmbedded ? pages.EMBEDDED_PRODUCTS.PATH : pages.PRODUCTS.PATH);
    }
  };

  const handleDeleteOrderItem = async orderData => {
    setModalData(orderData);
    setModalVisible(true);
  };

  const deleteOrder = async () => {
    const response = await orderService.deleteOrder(modalData.orderId);

    if (response.statusCode === 204) {
      setModalVisible(false);
      setModalData({});
      setMessage(translate("ORDER_DELETED"));
      clearOrder();
      clearReferencedOrder();
      setFetchOrders(true);
    }
  };

  const handleQuantityChange = async (contentId, data) => {
    const response = await orderService.updateLine(contentId, data);
    if (response.isOk()) {
      setFetchOrders(true);
      setMessage(translate("QUANTITY_UPDATED"));
    }
  };

  useEffect(() => {
    if (orders) {
      const groupedOrders = orders
        ?.sort((a, b) => a.status - b.status)
        .reduce(
          (accumulator, item) => (
            (accumulator[item.contentType] || (accumulator[item.contentType] = [])).push(item), accumulator
          ),
          {},
        );
      setGroupedContentList(groupedOrders);
    }
  }, [orders]);

  return (
    <div>
      <section>
        {groupedContentList[CONTENT_TYPE.PRODUCT_DELIVERY] && (
          <ProductDelivery
            list={groupedContentList[CONTENT_TYPE.PRODUCT_DELIVERY]}
            onDelete={handleDeleteOrderItem}
            onQuantityChange={handleQuantityChange}
            isReferencedOrder={fitting}
            checkboxDisabled
          />
        )}
        {groupedContentList[CONTENT_TYPE.WORK] && (
          <Work
            list={groupedContentList[CONTENT_TYPE.WORK]}
            onDelete={handleDeleteOrderItem}
            isReferencedOrder={fitting}
          />
        )}
      </section>
      {isEmbedded && !isCompleted && (
        <footer className={styles.buttons}>
          <div className={styles.button}>
            <CheckAuthButton
              title={translate("ORDER_PRODUCT")}
              theme={THEME.SECONDARY}
              onClick={() => startOrder(CONTENT_TYPE.PRODUCT_DELIVERY)}
              authId={"FSP_CreateWebOrderContentWithParent"}
            />
          </div>
          <div className={styles.button}>
            <CheckAuthButton
              title={translate("ORDER_WORKORDER")}
              theme={THEME.SECONDARY}
              onClick={() => startOrder(CONTENT_TYPE.WORK)}
              authId={"FSP_CreateWebOrderContentWithParent"}
            />
          </div>
        </footer>
      )}
      {modalVisible && (
        <DeleteOrderModal
          visible={modalVisible}
          order={modalData}
          onClose={() => {
            setModalVisible(false);
          }}
          onDelete={deleteOrder}
        />
      )}
    </div>
  );
};

FittingOrders.propTypes = {
  fitting: PropType.object,
  setBreadcrumbs: PropType.func,
  embeddedFitting: PropType.object,
};
export default FittingOrders;
