import classNames from "classnames";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import { setOrder } from "../../../reducers/appData/appDataActions";
import { setMessage } from "../../../reducers/dialog/dialogAction";
import orderService from "../../../services/order/orderService";
import productService from "../../../services/product/productService";
import { ADDRESS_TYPE, CLIENT_TYPE, CONTACT_TYPE, CONTENT_TYPE, LINKED_CATEGORIES } from "../../../utils/constants";
import pages from "../../../utils/pages";
import { useTablet } from "../../../utils/viewport";
import Label from "../../shared/Label/Label";
import { DELIVERY_LOCATION } from "./Components/productConstants";

import AccordionItem from "../../shared/AccordionItem/AccordionItem";
import InventoryList from "../../shared/InventoryList/InventoryList";
import Page, { Layout } from "../../shared/Page/Page";
import ProductImage, { ImagePreset } from "../ProductImage/ProductImage";
import ProductMake from "./Components/ProductMake";
import ProductOrder from "./Components/ProductOrder";

import { Button, THEME } from "@myloc/myloc-gui";
import calculateSalesPrice from "../../../utils/calculateSalesPrice";
import TabMenu from "../../shared/TabMenu/TabMenu";
import styles from "./ProductDetailPage.module.scss";

const TAB_CATEGORIES = Object.values(LINKED_CATEGORIES);

const ProductDetailPage = () => {
  const location = useLocation();
  const referrer = location.state?.referrer;
  const history = useHistory();
  const isTablet = useTablet();
  const queryParams = useParams();
  const translate = useTranslate();
  const [product, setProduct] = useState(null);
  const appDataUser = useSelector(state => state.appData.user);
  const order = useSelector(state => state.appData.order);
  const facility = useSelector(state => state.appData.user.facility);
  const referencedOrder = useSelector(state => state.embedded.referencedOrder);
  const isBaseStorage = order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE;
  const [selectedStorage, setSelectedStorage] = useState({
    category: isBaseStorage ? order?.receiver?.category : facility.category,
    id: isBaseStorage ? order?.receiver?.id : facility.id,
    label: isBaseStorage ? order?.receiver?.label : facility.description,
    value: isBaseStorage ? order?.receiver?.value : facility.value,
  });
  const [receiverLocation, setReceiverLocation] = useState(
    isBaseStorage ? DELIVERY_LOCATION.DELIVERY : DELIVERY_LOCATION.BASE_STORAGE,
  );
  const isActiveReferencedOrder = referencedOrder?.type === CONTENT_TYPE.PRODUCT_DELIVERY;
  const showInventoryList = product?.isInventory && !!selectedStorage && !isBaseStorage;
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));

  useEffect(() => {
    productService.getProduct(encodeURIComponent(queryParams.product)).then(response => setProduct(response.data));
  }, [queryParams.product]);

  const onOrder = async (quantity, inventory, orderControl) => {
    const data = {
      contentType: receiverLocation.id,
      subContentType: {
        id: !orderControl ? "NONE" : orderControl,
      },
      product: {
        id: inventory ? inventory.id : product.id,
        quantity: quantity,
      },
      fromFacility:
        receiverLocation.id === DELIVERY_LOCATION.BASE_STORAGE.id
          ? {
              category: selectedStorage?.category,
              label: selectedStorage?.value,
              id: selectedStorage?.id,
              value: selectedStorage?.value,
            }
          : null,
      parentWebOrderContentId: isActiveReferencedOrder ? referencedOrder?.fitting?.id : null,
    };
    if (isActiveReferencedOrder) {
      const createOrder = await orderService.createOrder({
        id: referencedOrder?.fitting?.client?.id,
        type: CLIENT_TYPE.USER,
      });
      if (createOrder.isOk) {
        setOrder(createOrder.data);

        const response = await orderService.createContentWithParent(data, createOrder?.data?.id);
        if (!!response.data.steps?.length > 0) {
          history.push({
            pathname: generatePath(isEmbedded ? `${pages.EMBEDDED_PRODUCT_ORDER.PATH}` : pages.PRODUCT_ORDER.PATH, {
              order: createOrder.data.id,
              content: response.data.id,
            }),
          });
        }
      }
    } else {
      const response = await orderService.createContent(data);
      if (response.isOk()) {
        if (isBaseStorage) {
          const addressAndContactDataForBaseStorage = {
            contact: { id: appDataUser.information.id, type: CONTACT_TYPE.PERSON },
            address: {
              id: order?.receiver?.id,
              type: ADDRESS_TYPE.COMPANY,
            },
          };

          await orderService.updateDelivery(addressAndContactDataForBaseStorage, order, response.data.id);
        }

        if (!!response.data.steps?.length > 0) {
          history.push({
            pathname: generatePath(pages.PRODUCT_ORDER.PATH, { order: order.id, content: response.data.id }),
          });
        } else {
          if (isBaseStorage) {
            setMessage(translate("CART_ADDED_ITEM", { item: response.data?.product?.name }));
          } else {
            history.push({
              pathname: pages.CART.PATH,
              state: { orderId: order.id },
            });
          }
        }
      }

      return response;
    }
  };

  const getTitleForSimilarProducts = () => {
    if (!selectedStorage) {
      return translate("SIMILAR_PRODUCTS");
    } else {
      return `${translate("SIMILAR_PRODUCTS")} ${translate("WITH_AVAILABILITY_IN")} ${selectedStorage.value} - ${
        selectedStorage.label
      }`;
    }
  };

  const pushToProductsPage = () => {
    const isoCode = encodeURIComponent(product?.isoCode?.id);
    const baseStorage = encodeURIComponent(selectedStorage?.id);
    const itemClass = encodeURIComponent(product?.itemClass?.id);

    if (!selectedStorage) {
      history.push(
        `${pages.PRODUCTS.PATH}?isoCodes=${encodeURIComponent(isoCode)}&itemClasses=${encodeURIComponent(itemClass)}`,
      );
    } else {
      history.push(
        `${pages.PRODUCTS.PATH}?isoCodes=${encodeURIComponent(isoCode)}&baseStorages=${encodeURIComponent(
          baseStorage,
        )}&itemClasses=${encodeURIComponent(itemClass)}`,
      );
    }
  };

  const breadcrumbs = () => {
    return [
      {
        text: !referrer || referrer?.match(/products/) ? translate(pages.PRODUCTS.NAME) : translate("STOCK_BALANCE"),
        link: referrer || pages.PRODUCTS.PATH,
      },
      {
        text: `${translate("PRODUCT")} ${product?.productNumber}`,
      },
    ];
  };

  const fetchLinkCategory = useCallback(
    category => {
      return productService.getPageContentsForProduct(product?.id, { category, sort: "publishDate:desc", amount: 1 });
    },
    [product?.id],
  );

  const sortContent = content => {
    return content.sort((curr, prev) =>
      curr.hyperlinkTitle
        ? curr.hyperlinkTitle.localeCompare(prev.hyperlinkTitle ?? prev.category?.label)
        : curr.category?.value?.localeCompare(prev.hyperlinkTitle ?? prev.category?.label),
    );
  };

  if (!product) {
    return <></>;
  }

  return (
    <Page breadcrumbs={breadcrumbs()} customCssClass={styles.productDetail} layout={Layout.ONE_TO_ONE} spaceBetween>
      <section className={styles.imageSection}>
        {isTablet && (
          <div className={styles.titleRow}>
            <h2 className={styles.title}>{product.name}</h2>
            {product.salesPriceCurrency?.value && product.salesPrice > 0 && (
              <h2 className={styles.price}>
                {`${calculateSalesPrice(product.salesPrice, product.salesPriceQuantity, product.numberOfPriceDecimals)} 
              ${product.salesPriceCurrency.value.toUpperCase()}`}
              </h2>
            )}
          </div>
        )}
        <ProductImage
          images={product.images}
          preset={ImagePreset.MEDIUM}
          isOrderable={product.isOrderable}
          customCssClass={styles.notOrderable}
        />
        <TabMenu categories={TAB_CATEGORIES} request={fetchLinkCategory} sortContent={sortContent} />
      </section>
      {isTablet ? (
        <section className={styles.information}>
          <ProductOrder
            selectedStorage={selectedStorage}
            onSelectStorage={setSelectedStorage}
            receiverLocation={receiverLocation}
            onSetReceiverLocation={setReceiverLocation}
            onClick={onOrder}
            product={product}
            isActiveReferencedOrder={isActiveReferencedOrder}
          />
          <Button customCssClass={styles.similarProducts} onClick={pushToProductsPage} theme={THEME.PLAIN_PRIMARY}>
            {getTitleForSimilarProducts()}
          </Button>
          {showInventoryList && !isEmbedded && (
            <InventoryList
              productId={product.id}
              facility={selectedStorage}
              onOrder={onOrder}
              disabled={!order?.receiver || receiverLocation.id !== DELIVERY_LOCATION.BASE_STORAGE.id}
            />
          )}
          <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={product.productNumber} />
          <Label inline label={translate("DESCRIPTION")} value={product.description} hideEmpty />
          <Label inline label={translate("ARTICLE_CLASS")} value={product.itemClass?.label} />
          <Label inline label={translate("ISO_CODE")} value={`${product.isoCode?.value} - ${product.isoCode?.label}`} />
          <Label inline label={translate("SUPPLIER")} value={product.supplier?.label} hideEmpty />
          <Label inline label={translate("SUPPLIER_ITEM_NUMBER")} value={product.mainSupplierItemNumber} hideEmpty />

          <p className={styles.text}>{product.text}</p>
          {product?.makes && (
            <AccordionItem title={translate("CUSTOMIZATION")}>
              <ProductMake makes={product.makes} />
            </AccordionItem>
          )}
        </section>
      ) : (
        <section className={styles.information}>
          <div className={styles.titleRow}>
            <h2 className={styles.title}>{product.name}</h2>
            {product.salesPriceCurrency?.value && product.salesPrice > 0 && (
              <h2 className={styles.price}>
                {`${calculateSalesPrice(product.salesPrice, product.salesPriceQuantity, product.numberOfPriceDecimals)} 
              ${product.salesPriceCurrency.value.toUpperCase()}`}
              </h2>
            )}
          </div>
          <div className={styles.row}>
            <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={product.productNumber} />
          </div>
          <div className={styles.row}>
            <Label inline label={translate("DESCRIPTION")} value={product.description} hideEmpty />
          </div>
          <div className={styles.row}>
            <Label inline label={translate("ARTICLE_CLASS")} value={product.itemClass?.label} />
          </div>
          <div className={styles.row}>
            <Label
              inline
              label={translate("ISO_CODE")}
              value={`${product.isoCode?.value} - ${product.isoCode?.label}`}
            />
          </div>
          <div className={styles.row}>
            <Label inline label={translate("SUPPLIER")} value={product.supplier?.label} hideEmpty />
          </div>
          <div className={styles.row}>
            <Label inline label={translate("SUPPLIER_ITEM_NUMBER")} value={product.mainSupplierItemNumber} hideEmpty />
          </div>
          <div className={styles.row}>
            <Label
              inline
              label={`${translate("AVAILABLE_BALANCE")} (${product.defaultWarehouseName})`}
              value={`${product.defaultWarehouseBalance} ${product.unit}`}
              hideEmpty
            />
          </div>

          <p className={classNames(styles.text, styles.informationMargin)}>{product.text}</p>
          <ProductMake makes={product.makes} />
          <Button customCssClass={styles.similarProducts} onClick={pushToProductsPage} theme={THEME.PLAIN_PRIMARY}>
            {getTitleForSimilarProducts()}
          </Button>
          <ProductOrder
            selectedStorage={selectedStorage}
            onSelectStorage={setSelectedStorage}
            receiverLocation={receiverLocation}
            onSetReceiverLocation={setReceiverLocation}
            onClick={onOrder}
            product={product}
            isActiveReferencedOrder={isActiveReferencedOrder}
          />
          {showInventoryList && !isEmbedded && (
            <div className={styles.inventoryList}>
              <InventoryList
                productId={product.id}
                facility={selectedStorage}
                onOrder={onOrder}
                disabled={!order?.receiver || receiverLocation.id !== DELIVERY_LOCATION.BASE_STORAGE.id}
              />
            </div>
          )}
        </section>
      )}
    </Page>
  );
};

ProductDetailPage.propTypes = {
  params: PropTypes.object,
  id: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
  productNumber: PropTypes.string,
  isInventory: PropTypes.bool,
  orderControl: PropTypes.string,
  unit: PropTypes.string,
  quantityDecimals: PropTypes.number,
  quantity: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  storageBalances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ),
  makes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  accessories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          default: PropTypes.bool,
        }),
      ),
    }),
  ),
  referrer: PropTypes.string,
};

export default ProductDetailPage;
