import { Button, Form, InputField } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import styles from "./Product.module.scss";

const Product = ({ onNext, data, setData }) => {
  const queryParams = useParams();
  const translate = useTranslate();
  const receiver = useSelector(state => state.appData.order?.receiver);

  const [isLoading, setLoading] = useState(false);

  const updateProduct = product => {
    setData({ ...data, product: { ...data.product, ...product } });
  };

  const submitHandler = async () => {
    setLoading(true);

    const { contentType, product } = data;

    const response = queryParams.content
      ? await orderService.updateExternalProduct({ contentType, product })
      : await orderService.createExternalProduct({ contentType, product });

    setLoading(false);

    if (response.isOk()) {
      onNext(response.data);
    }
  };

  return (
    <Form className={styles.content} onSubmit={submitHandler}>
      <InputField
        label={translate("PRODUCT_NUMBER_SHORT")}
        name={"productId"}
        type="text"
        required
        value={data?.product?.productNumber}
        onChange={event => updateProduct({ productNumber: event.target.value })}
      />
      <InputField
        label={translate("PRODUCT_NAME")}
        name={"name"}
        type="text"
        required
        value={data?.product?.name}
        onChange={event => updateProduct({ name: event.target.value })}
      />
      <InputField
        label={translate("SUPPLIER")}
        name={"supplier"}
        type="text"
        required
        value={data?.product?.supplier?.label}
        onChange={event => updateProduct({ supplier: { label: event.target.value } })}
      />
      <InputField
        label={translate("QUANTITY")}
        name={"quantity"}
        type="number"
        required
        value={String(data.product?.quantity)}
        onChange={event => updateProduct({ quantity: event.target.value })}
      />
      <Button type="submit" isLoading={isLoading} disabled={!receiver}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
};

Product.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  order: PropTypes.object,
  contentId: PropTypes.string,
  product: PropTypes.object,
  onNext: PropTypes.func,
};

export default Product;
