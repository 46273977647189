export const ORDER_POPUP = "DIALOG_ORDER_POPUP";
export const HOLDING_POPUP = "DIALOG_HOLDING_POPUP";
export const MESSAGE_ERROR = "DIALOG_ERROR";
export const MESSAGE_INFO = "DIALOG_MESSAGE";
export const RESET_MESSAGE = "DIALOG_RESET_MESSAGE";

export enum MESSAGE_TYPE {
  ERROR = 1,
  MESSAGE = 2,
}
