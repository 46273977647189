import PropType from "prop-types";
import { useEffect, useState } from "react";
import Label from "../../../shared/Label/Label";
import { useTranslate } from "../../../../language/i18n";
import { THEME } from "@myloc/myloc-gui";

import resourceGroupService from "../../../../services/resourceGroupService";
import AnnulFittingMeetingModal from "../../Modals/AnnulFittingMeetingModal";
import { getPlace } from "../../../../utils/getPlace";

import styles from "../../FittingDetailPage.module.scss";
import pages from "../../../../utils/pages";
import CheckAuthButton from "../../../shared/Buttons/CheckAuthButton";
import CreateOrEditNextMeeting from "./CreateOrEditMeeting";
import { useSelector } from "react-redux";

const NextMeeting = ({ fitting, setBreadcrumbs, embeddedFitting, setFetchFittings }) => {
  const translate = useTranslate();
  const authorizationList = useSelector(state => state.session.authorizationList);
  const address = fitting?.nextMeeting?.address
    ? [
        fitting?.nextMeeting?.address?.street,
        `${fitting?.nextMeeting?.address?.postalNo} ${fitting?.nextMeeting?.address?.city}`,
      ]
    : [];

  const [modal, setModal] = useState("");
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [options, setOptions] = useState({});
  const [meeting, setMeeting] = useState();
  const isCompleted = fitting.status.value === 90 || fitting.status.value === 99;

  useEffect(() => {
    setBreadcrumbs([
      {
        text: embeddedFitting?.text
          ? translate(embeddedFitting?.text)
          : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}`,
        link: embeddedFitting?.path ? embeddedFitting?.path : pages.MY_FITTINGS.PATH,
      },
      {
        text: `${translate(pages.FITTING_DETAIL.NAME)} - ${translate(pages.MY_FITTINGS_NEXT_MEETING.NAME)}`,
      },
    ]);
  }, [embeddedFitting, setBreadcrumbs, translate]);

  useEffect(() => {
    if (edit || create) {
      Promise.all([
        resourceGroupService.getResourceGroup(fitting?.fittingType?.roomResourceGroup?.id, { subResource: true }),
        resourceGroupService.getResourceGroup(fitting?.fittingType?.carResourceGroup?.id, { subResource: true }),
        resourceGroupService.getResourceGroup(fitting?.fittingType?.consultantResourceGroup?.id, {
          mainResource: true,
        }),
        resourceGroupService.getResourceGroup(fitting?.fittingType?.technicianResourceGroup?.id, {
          mainResource: true,
        }),
      ]).then(([room, car, consultant, technician]) => {
        setOptions({
          room: room?.data?.page,
          car: car?.data?.page,
          consultant: consultant?.data?.page,
          technician: technician?.data?.page,
        });
      });

      setMeeting({
        consultantId: fitting.nextMeeting?.consultant?.id,
        technicianId: fitting.nextMeeting?.technician?.id,
        roomId: fitting.nextMeeting?.room?.id,
        carId: fitting.nextMeeting?.car?.id,
        date: fitting.nextMeeting?.date || "",
        fromTime: fitting.nextMeeting?.fromTime || "",
        toTime: fitting.nextMeeting?.toTime || "",
        id: fitting.nextMeeting?.id,
        fittingId: fitting.id,
        address: fitting.nextMeeting?.address || fitting.client?.defaultAddress,
        addressType: fitting.nextMeeting?.addressType?.id || "CLIENT",
      });
    }
  }, [fitting, edit, create]);

  const isAuthorized = authId => {
    return authorizationList?.find(item => item.type === "FUNCTION" && item.id === authId);
  };

  const NewMeeting = () => {
    return create ? (
      <CreateOrEditNextMeeting
        fitting={fitting}
        setMeeting={setMeeting}
        meeting={meeting}
        create={create}
        setCreate={setCreate}
        options={options}
        setFetchFittings={setFetchFittings}
      />
    ) : (
      <>
        <p>{translate("NO_MEETINGS_BOOKED")}</p>
        {!isCompleted && (
          <div className={styles.buttons}>
            <CheckAuthButton
              theme={THEME.SECONDARY}
              onClick={() => setCreate(true)}
              title={translate("NEW_MEETING")}
              authId={"FSP_CreateWebOrderFittingMeeting"}
            />
          </div>
        )}
      </>
    );
  };

  const NextMeetingDetails = () => {
    if (
      !fitting?.nextMeeting ||
      (fitting?.nextMeeting?.status == "BOOKED" && !isAuthorized("FSP_UpdateUnplannedWebOrderFittingMeetings"))
    ) {
      return <NewMeeting />;
    }

    return (
      <>
        <Label
          label={translate("TIME")}
          value={`${fitting?.nextMeeting?.date || ""} ${fitting?.nextMeeting?.fromTime || ""} - ${
            fitting?.nextMeeting?.toTime || ""
          }`}
          inline
        />
        <section className={styles.split}>
          <div className={styles.address}>
            <Label label={translate("ADDRESS")} value={address} />
          </div>

          <div>
            <Label inline label={translate("PLACE")} value={getPlace(fitting?.nextMeeting, translate("CLIENT"))} />
            <Label inline label={translate("ROOM")} value={fitting?.nextMeeting?.room?.label} hideEmpty />
            <Label inline label={translate("CAR")} value={fitting?.nextMeeting?.car?.label} hideEmpty />
          </div>

          <div className={styles.column}>
            {fitting?.nextMeeting?.consultant?.firstName && (
              <Label
                label={translate("CONSULTANT")}
                value={[
                  `${fitting?.nextMeeting?.consultant?.firstName} ${fitting?.nextMeeting?.consultant?.surname}`,
                  `${fitting?.nextMeeting?.consultant?.phoneNo ?? ""}`,
                  `${fitting?.nextMeeting?.consultant?.email ?? ""}`,
                ]}
              />
            )}
          </div>

          <div className={styles.column}>
            {fitting?.nextMeeting?.technician?.firstName && (
              <Label
                label={translate("TECHNICIAN")}
                value={[
                  `${fitting?.nextMeeting?.technician?.firstName} ${fitting?.nextMeeting?.technician?.surname}`,
                  fitting?.nextMeeting?.technician?.phoneNo ?? "",
                  fitting?.nextMeeting?.technician?.email ?? "",
                ]}
              />
            )}
          </div>
        </section>

        {!isCompleted && (
          <div className={styles.buttons}>
            <CheckAuthButton
              theme={THEME.SECONDARY}
              onClick={() => setEdit(true)}
              title={translate("EDIT_MEETING")}
              authId={
                fitting?.nextMeeting?.status === "PLANNED"
                  ? "FSP_UpdatePlannedWebOrderFittingMeeting"
                  : "FSP_UpdateUnplannedWebOrderFittingMeeting"
              }
            />
            {fitting?.nextMeeting?.status === "PLANNED" && (
              <CheckAuthButton
                theme={THEME.SECONDARY}
                onClick={() => setModal("ANNUL_MEETING")}
                title={translate("ANNUL_FITTING_MEETING")}
                authId={"FSP_CancelWebOrderFittingMeeting"}
              />
            )}
          </div>
        )}
        <AnnulFittingMeetingModal
          visible={modal === "ANNUL_MEETING"}
          fittingId={fitting.id}
          meetingId={fitting?.nextMeeting?.id}
          onClose={() => setModal("")}
          setFetchFittings={setFetchFittings}
        />
      </>
    );
  };

  const getTitle = () => {
    if (edit) {
      return translate("EDIT_MEETING");
    } else if (create) {
      return translate("NEW_MEETING");
    } else {
      return translate("FITTING_SUB_NAV_NEXT_MEETING");
    }
  };
  return (
    <section className={styles.nextMeeting}>
      <h3 className={styles.title}>{getTitle()}</h3>
      {edit ? (
        <CreateOrEditNextMeeting
          fitting={fitting}
          setMeeting={setMeeting}
          meeting={meeting}
          edit={edit}
          setEdit={setEdit}
          options={options}
          setFetchFittings={setFetchFittings}
        />
      ) : (
        <NextMeetingDetails />
      )}
    </section>
  );
};

NextMeeting.propTypes = {
  fitting: PropType.object,
  setBreadcrumbs: PropType.func,
  embeddedFitting: PropType.object,
  setFetchFittings: PropType.func,
};

export default NextMeeting;
