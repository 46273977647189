import {
  CLIENT,
  ORDER,
  USERNAME,
  ADDRESS,
  COMPANY,
  FACILITY,
  INFORMATION,
  CLEAR_STATE,
  CLEAR_ORDER_STATE,
  CURRENT_STEP,
  RECEIVER_MENU,
  CLEAR_RECEIVER_MENU,
} from "./appDataVariables";
import store from "../rootReducer";

export function setClient(client) {
  store.dispatch({ type: CLIENT, client });
}

export function setOrder(order) {
  store.dispatch({ type: ORDER, order });
}

export function clearOrder() {
  store.dispatch({ type: CLEAR_ORDER_STATE });
}

export function setUserName(username) {
  store.dispatch({ type: USERNAME, username });
}

export function setAddress(address) {
  store.dispatch({ type: ADDRESS, address });
}

export function setCompany(company) {
  store.dispatch({ type: COMPANY, company });
}

export function setFacility(facility) {
  store.dispatch({ type: FACILITY, facility });
}

export function setInformation(information) {
  store.dispatch({ type: INFORMATION, information });
}

export function setReceiverMenu(receiverMenu) {
  store.dispatch({ type: RECEIVER_MENU, receiverMenu });
}
// Only re-initialize this reducer
export function clearAppData() {
  store.dispatch({ type: CLEAR_STATE });
}

export function clearReceiverMenu() {
  store.dispatch({ type: CLEAR_RECEIVER_MENU });
}

export function setCurrentStep(currentStep) {
  store.dispatch({ type: CURRENT_STEP, currentStep });
}
